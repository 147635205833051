/**
 * Created by d.cian on 27/08/2015.
 */
var isRecette = false;
var suffixEnv = "";

var isMobile = false;
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;

var protocole = "http";
var protocoleMedia = "https";
var php = protocoleMedia+'://www.packweb2.com/web-services-pw3';
if(isRecette) {
    suffixEnv = "-recette";
    php = 'http://www'+suffixEnv+'.packweb2.com/web-services-pw3';
}
var php_connect = 'connectServer.php?tmp='+Date.now();
var php_questions = 'questionsServer.php';
var php_stats = 'statsServer.php';
var php_formation = 'formationServer.php';
var php_messagerie = 'messagerieServer.php';

var APIKEY = 'EDI5S12544DS65D78DZZ';
var EDITEUR = "6";
var PHP_CONNECT = php+'/connect/'+php_connect;
var PHP_QUESTIONS = php+'/questions/'+php_questions;
var PHP_STATS = php+'/stats/'+php_stats;
var PHP_FORMATION = php+'/formation/'+php_formation;
var PHP_MESSAGERIE = php+'/messagerie/'+php_messagerie;

var CLIENT_ID = "connexion";
var CLIENT_SECRET = "lmdpdcpe";
if(isRecette) {
    //CLIENT_SECRET = "skljsdfghbfkdjhovsdmqzah";
}
var GRANT_TYPE = "client_credentials";

var URL_API = protocoleMedia + "://api"+suffixEnv+".ediser.com/api/";
var URL_TOKEN = URL_API+"oauth/token";

var ASSET_TYPE_IMAGE = "1";
var ASSET_TYPE_VIDEO = "3";
var ASSET_TYPE_ANIMATION = "4";
var ASSET_TYPE_HTML = "6";
var ASSET_TYPE_SANCTION = "7";
var ASSET_TYPE_RISQUE = "8";
var ASSET_TYPE_SIGNALISATION = "9";

var ID_PRODUIT_TEST_ALEATOIRE = 2838; //2838 - 6404
if(isMobile==true){ID_PRODUIT_TEST_ALEATOIRE = 6530;} //6530

var FORMATION = "1";

var RACINE_CLOUDFRONT = protocoleMedia + "://www.cdnediser.com/";
var RACINE_CLOUDFRONT_RESCU = "http://cdn.webamine.fr/";

var RACINE_MEDIA = RACINE_CLOUDFRONT+"packweb3/";
var RACINE_MEDIA_QUESTION = RACINE_CLOUDFRONT+"formation/question/PERMISB/";
var RACINE_MEDIA_QUESTION_NEW = RACINE_CLOUDFRONT+"packweb3/";
var RACINE_MEDIA_QUESTION_RESCU = RACINE_CLOUDFRONT_RESCU+"formation/question/PERMISB/";
var RACINE_MEDIA_QUESTION_NEW_RESCU = RACINE_CLOUDFRONT_RESCU+"packweb3/";
var RESOURCES_IMAGES = "images";

var eachCascadeVignette = 0;
var isStructureLoaded = false;

//pause en lecture de cours
var debutOuvertureText = 0;
var tempsOuvertureText = 0;
var debutPause = 0;
var tempsPause = 0;
var oStatSequence = {};

var PLACEHOLDER_UNIVERS = 'placeholder-univers.jpg';
var PLACEHOLDER_VIGNETTE = 'placeholder-vignette.jpg';

var numEleve = "";
var numAutoEcole = "";
var majStatSequence=0;
var elementThis;
if (typeof uniqid == 'function') {
    var token=uniqid(rand(), true);
}

var lzStringCompressionType = "";
var lzStringDecompressionType = "";

if(typeof readHistoryPrecedent == 'function') {
    var locationHref = readHistoryPrecedent();
}

var pushHistorySs = true;

//Gestion du timer
var setTimeExam;
var timerStep = 0.01;
var cantReadLocalStorage = 0;
var timerPending = false;

//médias
var videoQuestionHandler;
var audioQuestionHandler;
var nbAudioQuestionPreload = 5;
var nbVideoQuestionPreload = 5;
var nbImagesQuestionPreload = 1;
var compteurQuestionImagePreload = 0;

var tabExamLog = [];
//Pour forcer l'arret du timer
var forceEndTimer = false;
//Pour savoir si on doit déclencher le timer car l'audio ne s'est pas lancé
var audioSecurityNeeded = false, audioSecurityNotEnded = false;
var audioSecurityNeededTimeOut;
//Pour savoir si on doit déclencher le timer car la video ne s'est pas lancé
var videoSecurityNeeded = false, videoSecurityNotEnded = false;
var videoSecurityNeededTimeOut;
var appVersion = "";
var nbTests = 0;
var questionEnCours = 1;
var login_password = true;

