/**
 * Created by d.cian on 27/08/2015.
 */

var pw3 = {};

pw3.Stat = function(){};
pw3.Droit = function(){};
pw3.Examens = function(){};
pw3.Page = function(){};

pw3.Page.splitUrl = [];

pw3.Page.dataFormation = {};
pw3.Page.dataCouleur = {};
pw3.Page.dataForme = {};
pw3.Page.dataAsset = {};
pw3.Stat.dataStat = {};
pw3.Examens.obExamen = {};
pw3.Examens.questionEnErreur = 0;
pw3.Page.obMobile = {};
pw3.Page.obEtg = {};
pw3.Page.obQuiz = {};
pw3.Droit.obDroit = {};
pw3.Page.obEleve = {};
pw3.Page.agence = {};
pw3.PermisDemande = {};
pw3.login = "";

pw3.ListeClasseVirtuelle = [];

pw3.Examens.prototype = {
    buildTmpNewTestInCache : function (idTheme){
        try{
            var tmp = {};
            tmp.Date = timeToStr();
            tmp.Flag = null;
            tmp.NbFautes = this.TestCorrection.NbFautes;
            tmp.NumEleve = numEleve;
            tmp.NumStatTest = this.TestCorrection.NumStatTest;
            tmp.NumTest = this.TestCorrection.NumTest;
            tmp.Reussi;
            tmp.StatQuestion = [];
            tmp.TempsConnexion = 0;
            if(this.TestCorrection.NumTest && this.TestCorrection.NumTest != 0 && this.TestCorrection.NumTest != ID_PRODUIT_TEST_ALEATOIRE){
                tmp.NumTheme = idTheme;
                pw3.Page.obQuiz.liste[pw3.Page.obQuiz.liste.length] = tmp;
            }
            else{
                this.TestCorrection.NumTest = 0;
                //Dans le cas d'un test examen
                pw3.Examens.obExamen.liste[pw3.Examens.obExamen.liste.length] = tmp;
            }
        }
        catch(e){catchError(e);}
    },
    getNumStatTest : function (){
        try{
            if((numEleve!="")||(this.oTest.NumTest!="")||(this.oTest.Type!="")){
                var dataObj = {login: EDITEUR, password: APIKEY, id_eleve: numEleve, num_test: this.oTest.NumTest, type_test: this.oTest.Type};
                var ObjectForm = new CallObj("initTest", PHP_STATS, dataObj, "sessionStorage", "inittest");
                return ObjectForm.getObj();
            }
        }
        catch(e){catchError(e);}
    },
    buildTestCorrection : function(){
        try{
            var TestCorrection  = {};
            TestCorrection.NumStatTest = this.NumStatTest;
            TestCorrection.idSousTheme = this.idTheme;
            TestCorrection.Reussite = this.oTest.Reussite;
            TestCorrection.Type = this.oTest.Type;
            TestCorrection.Date=time();
            TestCorrection.NumTest = this.oTest.NumTest;
            TestCorrection.NbFautes = 0;
            TestCorrection.envoieResultat = 0;
            TestCorrection.Question = [];

            for(var idQuestion in this.tab_intranet){
                var oQuestion = this.tab_intranet[idQuestion];
                var oQuestiontblRep  = {};
                oQuestiontblRep.NumQuestion = oQuestion.reference;
                oQuestiontblRep.Etat = 0;
                oQuestiontblRep.Reponse = "";
                TestCorrection.Question[idQuestion] = oQuestiontblRep;
            }
            var objTestCorrection= new CallObj("", "", TestCorrection, "sessionStorage", "TestCorrection");
            return objTestCorrection.getObj();
        }
        catch(e){catchError(e);}
    },
    buildOTestEnCours : function(){
        try{
            var oTestEnCours  = {};
            oTestEnCours.Question = [];
            oTestEnCours.Compteur=1;
            for(var idQuestion in this.tab_intranet){
                var oQuestion = this.tab_intranet[idQuestion];
                oTestEnCours.Question[idQuestion] = oQuestion;
            }
            var objTestEnCours= new CallObj("", "", oTestEnCours, "sessionStorage", "TestEnCours");
            return objTestEnCours.getObj();
        }
        catch(e){
            catchError(e);
        }
    },
    initTest : function(oTypeTest, module_id){
        try{
            var numTest = oTypeTest.numTest;
            var idTheme = 0;
            var deferInitTest = Q.defer();

            if(oTypeTest.idTheme){idTheme = oTypeTest.idTheme;}

            sessionStorage.removeItem("SS_TestCorrection1");
            sessionStorage.removeItem("SS_TestEnCours1");
            sessionStorage.removeItem("SS_otypetest1");
            sessionStorage.removeItem("SS_numStatTest1");
            sessionStorage.removeItem("SS_test1");
            sessionStorage.removeItem("SS_inittest1");
            this.numTest = numTest*1;
            this.idTheme = idTheme;

            if(numEleve!=""){
                var _this = this;
                var dataApi = {"eleve_id":numEleve};
                apiCall("GET", "managements/test/serie/" + numTest, dataApi)
                    .then(function(retour){
                            _this.oTest = retour.message;
                            _this.oTest.NumTest = (numTest == ID_PRODUIT_TEST_ALEATOIRE ? 0 : numTest);
                            _this.oTest.idModule = module_id;
                            _this.tab_intranet = _this.oTest.TabQuestion;
                            _this.NumStatTest = pw3.Examens.getNumStatTest();
                            _this.oTestEnCours = pw3.Examens.buildOTestEnCours();
                            _this.TestCorrection = pw3.Examens.buildTestCorrection();
                            pw3.Examens.buildTmpNewTestInCache(idTheme); //Pour mettre à jour le cache avec le nouveau test

                            countQuestionType("mp3");
                            countQuestionType("mp4");

                            deferInitTest.resolve(_this.oTestEnCours);
                        }
                    );
            }
            return deferInitTest.promise;
        }
        catch(e){catchError(e);}
    },
    lanceTest : function(test_id, module_id){
        try{
            var deferLanceTest = Q.defer();
            var oResult = {};
            var oTypeTest = {};
            if(test_id && module_id){
                oTypeTest.Type = "thematique";
                oTypeTest.numTest = test_id;
                oTypeTest.idTheme = module_id;
            }
            else{
                oTypeTest.Type = "examen";
                oTypeTest.numTest = ID_PRODUIT_TEST_ALEATOIRE;
            }

            this.initTest(oTypeTest, module_id).then(
                function(retour){
                    var objTypeTest = new CallObj("oTypeTest", "", oTypeTest, "sessionStorage", "otypetest");

                    oResult.typeTest = objTypeTest.getObj();
                    oResult.oTestEnCours = retour;

                    //lanceur image
                    stockImage().then(function(oResultImage){
                        stockAudio().then(function(oResultAudio){
                            stockVideo().then(function(oResultVideo){
                                deferLanceTest.resolve(oResult);
                            });
                        });
                    });
                }
            );
            return deferLanceTest.promise;
        }
        catch(e){catchError(e);}
    },
    inspectImageComplete : function(idLoadMedia, mp3ToLaunch, nbTentatives, mode){
        try {
            if ($('#' + idLoadMedia).length >= 1) {var mediaQuestion = $('#' + idLoadMedia).get(0);}

            nbTentatives = (nbTentatives ? nbTentatives : 1);
            mode = (mode ? mode : "question");

            if (mediaQuestion && (idLoadMedia != "videoQuestion") && (!mediaQuestion.complete && nbTentatives < 300)) {
                nbTentatives++;
                setTimeout('pw3.Examens.inspectImageComplete("' + idLoadMedia + '", "' + mp3ToLaunch + '", "' + nbTentatives + '", "' + mode + '")', 20);
                return;
            }
            else {
                //corrige le bug d'affichage des gif
                setTimeout(function (){$("#imageQuestion").css("height", "68.8%")}, 50);

                console.log();

                addContextToLog("Finalisation du chargement de l'image au bout de " + nbTentatives + " tentative(s)");
                if (mode === "question") {addTimerSecuriteAudio(true, audioSecurityNotEnded, 10000);}

                try {
                    launchMp3(mode, mp3ToLaunch);
                } 
                catch (e) {
                    catchError(e);
                    addContextToLog("Echec du chargement du son (" + mp3ToLaunch + ")");
                }

            }
        }
        catch(e){catchError(e);}
    },
    initBarreQuestionExamen : function(){
        $("#timer .barreTimer .contenerProgress .progressBarre").css("width", "100%").css("backgroundColor", "#00D114");
    },
    progressBarreQuestionExamen : function(){
        try{
            //affiche le bouton validé
            addContextToLog("Demande de chargement de la barre de progression");
            if(!timerPending) {

                addContextToLog("Chargement de la barre de progression - OK");
                timerPending = true;
                forceEndTimer = false;

                $("#btnSuivant").removeClass("nolink").addClass("link");

                if (document.querySelector("canvas")) {
                    var ctx = document.querySelector("canvas").getContext("2d"), pst = 0, dlt = 0.0005;
                    var _this = this;
                    var currentAngle = 0;
                    timerStep = 0.01;
                    var startAngle = currentAngle * Math.PI * 2;
                    var endAngle;

                    (function loop() {
                        if(forceEndTimer){
                            addContextToLog("On force l'arret du timer");
                            return;
                        }
                        endAngle = (currentAngle += timerStep) * Math.PI * 2;
                        drawPie(ctx, 25, 31, 17, startAngle, endAngle);
                        timerStep += dlt;
                        if (timerStep <= 0.035) {
                            ctx.fillStyle = selectColor(timerStep);
                            setTimeExam = setTimeout(function () {
                                requestAnimationFrame(loop)
                            }, 333);
                        }
                        else{
                            addContextToLog("Fin du timer");
                            pw3.Examens.goToNextQuestion();
                            return;
                        }
                    })();
                }
            }
        }
        catch(e){catchError(e);}
    },
    goToNextQuestion : function(){
        try{
            //Met le son en pause au click sur changement de question
            $("#audioQuestion").get(0).pause();

            addContextToLog("Suppression des listener");

            if(document.getElementById("audioQuestion")){Handler.removeListener(audioQuestionHandler);}

            clearTimerStep().then(pw3.Examens.saveReponse().then(pw3.Examens.questionSuivante()));
        }
        catch(e){catchError(e);}
    },
    saveReponse : function(){
        try{
            var deferSaveReponse = Q.defer();

            var reponse = "";
            var oQuestion = this.oTestEnCours.Question[parseInt(this.oTestEnCours.Compteur)-1];
            var correction = oQuestion.bonne_reponse;
            var etat;
            var tblCorrection;

            $(".examens #menuExamen li.rep.active").each(function (){reponse += $(this).text();});

            if(correction.toUpperCase() != reponse.toUpperCase()){etat='0';}
            else{etat='1';}

            var oQuestionResult  = {};
            oQuestionResult.NumQuestion = oQuestion.reference;
            oQuestionResult.Etat = etat;
            oQuestionResult.Reponse = reponse;

            addContextToLog("Sauvegarde de la réponse client : " + reponse);

            var objTestCorrection = new CallObj("TestCorrection", "", "", "sessionStorage", "TestCorrection");
            this.TestCorrection = objTestCorrection.getObj();

            if(this.TestCorrection.Question[(this.oTestEnCours.Compteur*1)-1]){
                this.TestCorrection.Question[(this.oTestEnCours.Compteur*1)-1] = oQuestionResult;
                var objTblCorrection = new CallObj("TestCorrection", "", this.TestCorrection, "updateReponseTest", "TestCorrection");
                tblCorrection = objTblCorrection.getObj();
            }

            deferSaveReponse.resolve(tblCorrection);

            return deferSaveReponse.promise;
        }
        catch(e){catchError(e);}
    },
    prepareLaBaliseVideo : function(oExamenEnCours){
        try{
            if(oExamenEnCours.loop==true){$("#videoQuestion").attr('loop','loop')}
            else{$("#videoQuestion").removeAttr("loop")}

            $('#videoQuestion').clone().appendTo('#contenuAjax .partieQuestion');
            $('#videoQuestion').remove();
            $("#contenuAjax .partieQuestion #videoQuestion").removeClass("none");
            $("#contenuAjax .partieQuestion #videoQuestion").get(0).load();
            $("#contenuAjax .partieQuestion #videoQuestion").get(0).play();
        }
        catch(e){catchError(e);}
    },
    reinitialiseBaliseVideo : function(){
        try{
            $("#contenuAjax .partieQuestion #videoQuestion").clone().prependTo("body");
            $("#contenuAjax .partieQuestion #videoQuestion").remove();
            $("body #videoQuestion").addClass("none");
        }
        catch(e){catchError(e);}
    },
    questionSuivante : function(){
        try{
            addContextToLog("Chargement de la question suivante");
            clearTimerStep();

            $("#btnSuivant").removeClass("link").addClass("nolink");

            this.reinitialiseBaliseVideo();

            $("#menuExamen li.rep").removeClass("active");

            this.oTestEnCours.Compteur ++;

            var oResultMaj = {};
            oResultMaj.oTestEnCours = {};
            oResultMaj.oTestEnCours.Compteur = this.oTestEnCours.Compteur;
            oResultMaj.oTestEnCours.Question = this.oTestEnCours.Question;
            oResultMaj.TestCorrection = this.TestCorrection;
            oResultMaj.obExamen = this.obExamen;

            var oTestEnCours = new CallObj("", "", oResultMaj.oTestEnCours, "update", "TestEnCours");
            pw3.Examens.oTestEnCours = oTestEnCours.getObj();

            if(pw3.Examens.oTestEnCours.Question[pw3.Examens.oTestEnCours.Compteur-1] && pw3.Examens.oTestEnCours.Question[pw3.Examens.oTestEnCours.Compteur-1].reference){
                addContextToLog("Question [" + pw3.Examens.oTestEnCours.Compteur + "] " + pw3.Examens.oTestEnCours.Question[pw3.Examens.oTestEnCours.Compteur-1].reference + " initialisée");
            }else{
                addContextToLog("XXX FIN DU TEST");
            }

            if(this.oTestEnCours.Compteur > ObjectLength(oResultMaj.oTestEnCours.Question)){
                pw3.Examens.TestCorrection = this.TestCorrection;
                pw3.Page.AffichePage("examens", "resultat");
            }
            else{pw3.Page.AfficheExamensPage(oResultMaj);}
        }
        catch(e){catchError(e);}
    }
};

pw3.Droit.prototype = {
    typeConnect : function(retourConnect){
        try{
            //Les états de connect sont les suivant,
            // null = non connecté,
            // 0 = mot de passe invalide,
            // 1 = compte verrouillé,
            // 2 = compte archivé,
            // 3 = demande d'info complémentaire,
            // 4 = je suis conecté.
            pw3.Page.connect = null;

            if((typeof(pw3.Page.obEleve)==="object")&&(numEleve)&&(numEleve!="")){
                if(!pw3.Page.obEleve.hasOwnProperty("PermisDemande") || pw3.Page.obEleve.PermisDemande == null || pw3.Page.obEleve.PermisDemande == "" ) {
                    pw3.Page.obEleve.PermisDemande = "B";
                    pw3.PermisDemande ="B";
                } else {
                    //2 => A1 ; 3=> A2
                    var decoupermis = pw3.Page.obEleve.PermisDemande.split(";");
                    var permis = "B;";
                    for (var i=0; i < decoupermis.length; i++){
                        if(decoupermis[i] == "2"){
                            permis += "A1;";
                        } else if(decoupermis[i] == "3") {
                            permis += "A2;";
                        }
                    }
                    var permis = permis.substring(0, permis.length - 1);
                    pw3.Page.obEleve.PermisDemande = permis;
                    pw3.PermisDemande = permis;
                }

                pw3.Page.connect = 4;
                pw3.Droit.oDroit();
                if(pw3.Droit.obDroit.IsVerrouille==="1"){pw3.Page.connect = 1;}
                else if(pw3.Droit.obDroit.Flag==="A"){pw3.Page.connect = 2;}
                else if((pw3.Page.obEleve.DateNaissance <= 0)||(pw3.Page.obEleve.DateNaissance==="")||(pw3.Page.obEleve.Flag==="HT")||(pw3.Page.obEleve.Flag==="G")){pw3.Page.connect = 3;}
            }
            else if(retourConnect===false){
                pw3.Page.connect = 0;
            }
        }
        catch(e){catchError(e);}
    },
    Login : function(mdp, redirection){
        //console.log('Login', mdp, redirection);
        localStorage.clear();
        sessionStorage.clear();

        try{
            var Environnement = {};
            Environnement.IP = "";
            Environnement.sessionID = "";
            Environnement.browser = navigator.userAgent;
            Environnement.frontal = location.host;

            var retourLog = callData.service.login(mdp, sous_domaine(), Environnement);

            //console.log('retourLog', retourLog);

             var retourConnect = false;
            if(retourLog){
                pw3.Page.obEleve = retourLog;
                retourConnect = true;
            }

            this.typeConnect(retourConnect);

            //console.log(pw3.Page.connect);
            if (!login_password) {
                if (pw3.Page.connect != 3) {
                    pw3.Page.connect = 0;
                }
            }

            if(pw3.Page.connect==4){pw3.Page.popUpReCalcule();}

            if((!redirection)||(redirection==true)){
                setTimeout('pw3.Page.AffichePage("reinitRediection")',100);
                if(pw3.Page.connect==4){setTimeout('pw3.Page.popUpReCalcule()',100);}
            }
        }
        catch(e){catchError(e);}
    },
    oDroit : function(){
        try{
            if(pw3.Page.obEleve){
                var pourcentAvancementCours = 0;
                var avencement_exam_blanc = 0;
                var totalMedia = 0;
                var totalMediaVu = 0;

                if ((pw3.Stat.dataStat) && (pw3.Stat.dataStat.stat[FORMATION])) {
                    totalMedia = pw3.Stat.dataStat.stat[FORMATION].quantite;
                    totalMediaVu = pw3.Stat.dataStat.stat[FORMATION].vu;
                }

                if (totalMedia > 0) {
                    pourcentAvancementCours = Math.round(totalMediaVu * 100 / totalMedia);
                }

                if ((pw3.Examens.obExamen) && (pw3.Examens.obExamen.total > 0)) {
                    avencement_exam_blanc = Math.round(pw3.Examens.obExamen.question * 100 / pw3.Examens.obExamen.total);
                }

                var avencement_code_route = pourcentAvancementCours;
                var nbTestRealise = 0;
                var IsTestLimitAtteint = 0;
                var EleveFree;
                var EleveOnLine;
                var EleveIsCours;
                var EleveIsTestExamenCode;
                var ElevenbMaxTest;
                var EleveIsAAC;
                var EleveIsMoto;
                var agenceIsCours;
                var agenceIsTest;
                var agenceIsStat;
                var agenceIsREMC;
                var agenceIsMessage;
                var agenceAfficheDessinNomapad;
                var agenceAfficheObservationNomapad;
                var agenceIsUMPC;
                var agenceIsBoitier;
                var agenceNiveauFormationProgressive;
                var agenceIsFormationProgressive;
                var Flag;
                var IsVerrouille;
                var DateFinFormation;

                EleveFree = pw3.Page.obEleve.IsPackwebFree;
                EleveOnLine = pw3.Page.obEleve.IsPackwebOnline;
                EleveIsCours = pw3.Page.obEleve.IsCours; //1 si l'éléve peut lire les cours
                EleveIsTestExamenCode = pw3.Page.obEleve.IsTestExamenCode; //1 si l'éléve peut faire les examens blanc
                ElevenbMaxTest = pw3.Page.obEleve.nbMaxTest; //0 nb tests illimité, sinon nombre de tests auquel il a droit
                EleveIsAAC = pw3.Page.obEleve.IsAAC; //1 si l'élève a l'option AAC
                EleveIsMoto = pw3.Page.obEleve.IsMoto; //1 si l'élève a l'option Moto
                agenceIsCours = pw3.Page.obEleve.Agence.IsCours; // 1 Si l'auto école a les cours
                agenceIsTest = pw3.Page.obEleve.Agence.IsTest; // 1 Si l'auto école a les test
                agenceIsStat = pw3.Page.obEleve.Agence.IsStat; // 1 Si l'auto école a les stats
                agenceIsREMC = pw3.Page.obEleve.Agence.IsREMC; //1 si la formation est REMC
                agenceIsMessage = pw3.Page.obEleve.Agence.IsMessage; //1 si la messagerie est activée
                agenceAfficheDessinNomapad = pw3.Page.obEleve.Agence.AfficheDessinNomapad; // 1 si on affiche les dessins du nomapad
                agenceAfficheObservationNomapad = pw3.Page.obEleve.Agence.AfficheObservationNomapad; // 1 si on affiche les observation nomapad
                agenceIsUMPC = pw3.Page.obEleve.Agence.IsUMPC; // 1 si les nomapads sont activés
                agenceIsBoitier = pw3.Page.obEleve.Agence.IsBoitier; // 1 si les boitier sont activés
                agenceNiveauFormationProgressive = pw3.Page.obEleve.Agence.NiveauFormationProgressive; // chiffre (pourcentage) de la formation progressive
                agenceIsFormationProgressive = (pw3.Page.obEleve.IsFormationProgressive == "" || agenceIsCours == 0 ? 0 : pw3.Page.obEleve.IsFormationProgressive); // 1 si la formation progressive est activée
                Flag = pw3.Page.obEleve.Flag;
                IsVerrouille = pw3.Page.obEleve.IsVerrouille;
                DateFinFormation = pw3.Page.obEleve.DateFinFormation;

                if (pw3.Examens.obExamen) {
                    nbTestRealise = 0;
                    if(pw3.Examens.obExamen.liste != undefined) {
                        nbTestRealise = pw3.Examens.obExamen.liste.length;
                    }
                }

                if (ElevenbMaxTest != 0) {
                    if (nbTestRealise > ElevenbMaxTest) {
                        IsTestLimitAtteint = 1;
                    }
                }

                var Cours = 0;
                var Tests = 0;
                var Stats = 1;

                if(EleveFree==1 || EleveOnLine==1){
                    // Si l'élève est Free ou Online, je ne regarde que l'élève
                    Cours = EleveIsCours;
                    Tests = EleveIsTestExamenCode;
                } else {
                    // sinon, je regarde l'agence ET l'élève
                    Cours = agenceIsCours * EleveIsCours;
                    Tests = agenceIsTest * EleveIsTestExamenCode;
                }

                if (ElevenbMaxTest != 0) {
                    // Si les tests ne sont pas illimités, je regarde si le max est atteint
                    Tests = ElevenbMaxTest - nbTestRealise;
                }
                if(agenceIsFormationProgressive!=0){
                    // Si l'élève est en formation progressive, je regarde son % de progression
                    Tests = (agenceNiveauFormationProgressive <= avencement_code_route);
                }

                var oDroit = {};
                oDroit.Cours = Cours;
                oDroit.Tests = Tests;
                oDroit.Stats = Stats;
                oDroit.REMC = agenceIsREMC;
                oDroit.Messages = 0;
                oDroit.nbMaxTest = ElevenbMaxTest;
                oDroit.Boitier = agenceIsBoitier;
                oDroit.Tablette = agenceIsUMPC;
                oDroit.DessinTablette = agenceAfficheDessinNomapad;
                oDroit.ObservationTablette = agenceAfficheObservationNomapad;
                oDroit.NiveauFormationProgressive = agenceNiveauFormationProgressive;
                oDroit.IsFormationProgressive = agenceIsFormationProgressive;
                oDroit.IsTestLimitAtteint = IsTestLimitAtteint;
                oDroit.IsVerrouille = IsVerrouille;
                oDroit.dateFinFormation = DateFinFormation;
                oDroit.EleveFree = EleveFree;
                oDroit.EleveOnLine = EleveOnLine;
                oDroit.Flag = Flag;

                if (((oDroit.dateFinFormation <= time()) && (oDroit.dateFinFormation != "")) || (oDroit.IsVerrouille == 1)) {
                    oDroit.Cours = 0;
                    oDroit.Tests = 0;
                }

                var objDroit = new CallObj("oDroit", "", oDroit, "updateDroit", "odroit");
                pw3.Droit.obDroit = objDroit.getObj();
            }
        }
        catch(e){catchError(e);}
    }
};

pw3.Stat.prototype = {
    inspectPictoAvancement : function(is_fini, type, imageVignette, test_id){
        try{
            if(!test_id){test_id = 0;}else{test_id = test_id.replace("testId_", "");}
            var objAvancement = {};
            if(type===false){
                if((is_fini===0)||(!is_fini)||(is_fini==="")||(is_fini===null)||(typeof is_fini === 'undefined')){objAvancement.pictoAvancement = "m";}
                else if(is_fini==1){objAvancement.pictoAvancement = "n";}
                else if(is_fini==2){objAvancement.pictoAvancement = "o";}
                else if(is_fini==3){objAvancement.pictoAvancement = "l";}
            }
            else{
                if(is_fini==0){
                    objAvancement.pictoAvancement = "m";
                    objAvancement.classActiveModule = " desactive";
                    objAvancement.activeTestThematique = " nonFait";
                    if(imageVignette===null){objAvancement.imageVignette = imageVignette.replace(".jpg", "b.jpg");}
                }
                else if(is_fini==1){
                    objAvancement.pictoAvancement = "S";
                    objAvancement.classActiveModule = " active";
                    objAvancement.activeTestThematique = " rate";
                }
                else if(is_fini==2){
                    objAvancement.pictoAvancement = "V";  //croix
                    objAvancement.classActiveModule = " active";
                    objAvancement.activeTestThematique = "";
                }
                else if(is_fini==3){
                    objAvancement.pictoAvancement = "R";  //carré plein
                    objAvancement.classActiveModule = " active";
                    objAvancement.activeTestThematique = " reussi";
                }

                if(test_id>0){objAvancement.addClassPicto=" link";}
                else{
                    objAvancement.classActiveModule = " testThematiqueExistePas";
                    objAvancement.activeTestThematique = " nonFait";
                    objAvancement.addClassPicto=" none";
                }
            }
            return objAvancement;
        }
        catch(e){catchError(e);}
    },
    inspectPictoAvancement2 : function(etat_module, etat_test, test_id){
        try{
            if(!test_id){test_id = 0;}else{test_id = test_id.replace("testId_", "");}
            var objAvancement = {};

            objAvancement.addClassPicto=" link";
            switch(etat_module){
                case 0 :
                    objAvancement.pictoAvancement = "m";
                    objAvancement.classActiveModule = " desactive";
                    break;
                case 1 :
                    objAvancement.pictoAvancement = "S";
                    objAvancement.classActiveModule = " active";
                    break;
                case 2 :

                    objAvancement.pictoAvancement = "V";  //croix
                    objAvancement.classActiveModule = " active";
                    break;
                case 3 :
                    objAvancement.pictoAvancement = "R";  //carré plein
                    objAvancement.classActiveModule = " active";
                    break;
            }

            //Pour gérer le cas particulier de l'introduction
            if(test_id > 0){
                switch(etat_test){
                    case 0 :
                        objAvancement.activeTestThematique = " nonFait";
                        break;
                    case 1 :
                        objAvancement.activeTestThematique = " rate";
                        break;
                    case 2 :
                        objAvancement.activeTestThematique = "";
                        break;
                    case 3 :
                        objAvancement.activeTestThematique = " reussi";
                        break;
                }
            }else{
                objAvancement.activeTestThematique = " nonFait";
                objAvancement.addClassPicto=" none";
            }

            return objAvancement;
        }
        catch(e){catchError(e);}
    },
    nbSousThemeNbSousThemeVu : function(tblStat){
        try{
            var ObjStatSousTheme = {};
            ObjStatSousTheme.nbSoustheme = tblStat.quantite;
            ObjStatSousTheme.nbSousthemeVu = tblStat.vu;
            ObjStatSousTheme.is_fni = tblStat.is_fni;

            return ObjStatSousTheme;
        }
        catch(e){catchError(e);}
    },
    initTabStat : function(type, numSequence, tempsVideo, vu){
        try{
            oStatSequence.id_eleve = numEleve;

            if(pw3.Page.agence){
                oStatSequence.id_autoecole = pw3.Page.agence.NumAutoEcole;
            }
            else {
                oStatSequence.id_autoecole = pw3.Page.obEleve.Agence.NumAutoEcole;
            }

            oStatSequence.id_formation = numSequence;
            oStatSequence.date_debut = time();
            oStatSequence.duree_texte = 0;
            oStatSequence.ouvert_texte = 0;
            oStatSequence.duree_reel = 0;
            oStatSequence.duree_stat = 0;
            oStatSequence.date_fin = "";
            oStatSequence.is_fini = 0;
            oStatSequence.duree_media = tempsVideo;
            oStatSequence.type = type;
            oStatSequence.vu = vu;
        }
        catch(e){catchError(e);}
    },
    sendServer : function(oAsset) {
        try{
            masqueCours();
            if(oAsset){
                oStatSequence = oAsset;
                callData.statistiques.setStatCours();
            }
            else{
                oStatSequence.date_fin = time() - tempsPause;
                oStatSequence.duree_texte = tempsOuvertureText;
                tempsOuvertureText = 0;
                oStatSequence.temps_pause = tempsPause;
                var dureeMiniLecture = oStatSequence.duree_media / 2;

                if (oStatSequence.duree_texte == 0) {oStatSequence.ouvert_texte = 0;}
                else if (oStatSequence.duree_texte < dureeMiniLecture) {oStatSequence.ouvert_texte = 1;}
                else if (oStatSequence.duree_texte >= dureeMiniLecture) {
                    oStatSequence.ouvert_texte = 2;
                    oStatSequence.is_fini = 1;
                }

                if ((oStatSequence.date_fin - oStatSequence.date_debut - oStatSequence.duree_texte) >= oStatSequence.duree_media){oStatSequence.is_fini = 1;}

                oStatSequence.duree_reel = oStatSequence.date_fin - oStatSequence.date_debut;

                //On ajoute 5 secondes à chaque lecture pour que les personnes trop préssées ne soient pas pénalisées
                oStatSequence.duree_reel += (oStatSequence.duree_reel > 0 ? 5 : 0);

                if (oStatSequence.is_fini == 1) {
                    if (oStatSequence.ouvert_texte == 2) {oStatSequence.duree_stat = oStatSequence.duree_texte;}
                    else {oStatSequence.duree_stat = oStatSequence.duree_media;}
                }
                else {oStatSequence.duree_stat = oStatSequence.duree_reel;}

                if (oStatSequence.duree_reel > oStatSequence.duree_media) {oStatSequence.duree_stat = oStatSequence.duree_media;}
                if (oStatSequence.is_fini > 0) {oStatSequence.vu++;}

                if (oStatSequence.duree_media != undefined && oStatSequence.duree_media != 0){
                    callData.statistiques.setStatCours();
                }
            }

            this.setStatPW3(oStatSequence);
            oStatSequence = {};
            majStatSequence = 0;
            tempsPause = 0;
        }
        catch(e){catchError(e);}
    },
    setStatPW3 : function(oData){
        try{
            pw3.Page.splitUrl = attrUrl();
            this.oData = oData;
            this.idFomation = this.oData.id_formation;

            if(!pw3.Page.themeId){
                if(pw3.Page.dataFormation.formation["tabChild"][cleanID(pw3.Page.splitUrl[1])]["tabChild"][cleanID(pw3.Page.splitUrl[2])]["Meta"].theme_id){
                    pw3.Page.themeId = pw3.Page.dataFormation.formation["tabChild"][cleanID(pw3.Page.splitUrl[1])]["tabChild"][cleanID(pw3.Page.splitUrl[2])]["Meta"].theme_id;
                }
                else{pw3.Page.themeId = 0;}
            }

            this.test_asset = testAsset(pw3.Page.themeId, pw3.Page.dataAsset);

            if(this.oData.type == undefined){
                if(pw3.Page.splitUrl[3]){
                    if(pw3.Page.splitUrl[3] == "signalisation") {
                        this.oData.type = 'signalisation';
                        this.oData.id_formation = cleanID(pw3.Page.splitUrl[2]);
                        this.oData.id_eleve = numEleve;
                    }
                    else if(pw3.Page.splitUrl[3] == "risque"){
                        this.oData.type = 'risque';
                        this.oData.id_formation = cleanID(pw3.Page.splitUrl[2]);
                        this.oData.id_eleve = numEleve;
                        this.oData.id_autoecole  = numAutoEcole;
                        this.oData.is_fini= 1;

                        //Pour mettre à jour les stats côté serveur
                        oStatSequence = this.oData;
                        callData.statistiques.setStatCours();
                    }
                }
            }

            if(this.oData.type == 'sequence'){
                this.setStatPW3Sequence();
            }
            else if(this.oData.type == 'signalisation'){
                this.setStatPW3Signalisation();
            }
            else if(this.oData.type == 'risque'){
                this.setStatPW3Risque();
            }

            this.updateFormationInCache();
        }
        catch(e){catchError(e);}
    },
    setStatPW3Sequence : function(){
        try{
            this.setStatPW3SequenceSequence();
            this.setStatPW3SequencePartie();
            this.setStatPW3SequenceModule();
            this.setStatPW3Thematique();
        }
        catch(e){catchError(e);}
    },
    updateFormationInCache : function(){
        try{
            var duree_reel = 0;
            var vu = 0;
            for(var element in pw3.Stat.dataStat.stat){
                if(element == "1"
                    || (pw3.Stat.dataStat.stat[element].type != "Sequence")
                    || ( pw3.Stat.dataStat.stat[element].type == "Sequence" && pw3.Stat.dataStat.stat[element].is_fini != 3) ) {
                    continue;
                }
                duree_reel += pw3.Stat.dataStat.stat[element].duree_reel;
                vu += pw3.Stat.dataStat.stat[element].vu;
            }

            pw3.Stat.dataStat.stat[FORMATION].vu = vu;
            pw3.Stat.dataStat.stat[FORMATION].duree_reel = duree_reel;

            //On met à jour le cache des statistiques dans le session storage
            var update = new CallObj("", "", pw3.Stat.dataStat.stat, "update", "formation");
            update.getObj();
        }
        catch(e){catchError(e);}
    },
    setStatPW3Signalisation : function(){
        try{
            this.idFomation = cleanID(attrUrl()[2]);

            if(pw3.Stat.dataStat.stat[this.idFomation]) {
                //=====================================================
                // Module sous theme
                //=====================================================
                var sous_theme = this.idFomation;
                pw3.Stat.dataStat.stat[sous_theme].signalisation += 1;

                if (pw3.Stat.dataStat.stat[sous_theme].quantite == pw3.Stat.dataStat.stat[sous_theme].vu && (pw3.Stat.dataStat.stat[sous_theme].risque > 0 || this.test_asset.is_risque == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].signalisation > 0 || this.test_asset.is_signalisation == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].test_thematique == 3 || pw3.Stat.dataStat.stat[sous_theme].test_id == '' || pw3.Stat.dataStat.stat[sous_theme].test_id == null)) {
                    pw3.Stat.dataStat.stat[sous_theme].is_fini = 3;
                }
                else if (pw3.Stat.dataStat.stat[sous_theme].quantite == pw3.Stat.dataStat.stat[sous_theme].vu && (pw3.Stat.dataStat.stat[sous_theme].risque > 0 || this.test_asset.is_risque == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].signalisation > 0 || pw3.Stat.dataStat.stat[sous_theme].is_signalisation == false)) {
                    pw3.Stat.dataStat.stat[sous_theme].is_fini = 2;
                }
                else {
                    pw3.Stat.dataStat.stat[sous_theme].is_fini = 1;
                }

                //=====================================================
                // Module theme
                //=====================================================
                var theme = pw3.Stat.dataStat.stat[this.idFomation].theme;

                var nb_signalisation = 0;
                var nb_fini = 0;
                var nb_sous_theme_3 = 0;
                var nb_sous_theme_2 = 0;

                for(var idStat in pw3.Stat.dataStat.stat) {
                    if(pw3.Stat.dataStat.stat[idStat].theme == theme && pw3.Stat.dataStat.stat[idStat].type == 'Module' && pw3.Stat.dataStat.stat[idStat].signalisation > 0){
                        nb_signalisation += pw3.Stat.dataStat.stat[idStat].signalisation;
                    }

                    if (pw3.Stat.dataStat.stat[idStat].theme == theme && pw3.Stat.dataStat.stat[idStat].type == 'Sequence' && pw3.Stat.dataStat.stat[idStat].ouvert > 0){
                        if(pw3.Stat.dataStat.stat[idStat].is_fini == 3){nb_fini++;}
                    }

                    if(pw3.Stat.dataStat.stat[idStat].theme == theme && pw3.Stat.dataStat.stat[idStat].type == 'Module'){
                        if(pw3.Stat.dataStat.stat[idStat].is_fini == 3){nb_sous_theme_3++;}
                        if (pw3.Stat.dataStat.stat[idStat].is_fini > 1){nb_sous_theme_2++;}
                    }
                }
                pw3.Stat.dataStat.stat[theme].signalisation = nb_signalisation;

                if(pw3.Stat.dataStat.stat[theme].nombre_sous_theme == nb_sous_theme_3){pw3.Stat.dataStat.stat[theme].is_fini = 3;}
                else if(pw3.Stat.dataStat.stat[theme].nombre_sous_theme == nb_sous_theme_2){pw3.Stat.dataStat.stat[theme].is_fini = 2;}
                else{pw3.Stat.dataStat.stat[theme].is_fini = 1;}

                pw3.Stat.setStatPW3Thematique();
            }
        }
        catch(e){catchError(e);}
    },
    setStatPW3Risque : function() {
        try{
            this.idFomation = cleanID(attrUrl()[2]);
            if (pw3.Stat.dataStat.stat[this.idFomation]) {
                //=====================================================
                // Module sous theme
                //=====================================================
                var sous_theme = this.idFomation;
                pw3.Stat.dataStat.stat[sous_theme].risque += 1;

                if (pw3.Stat.dataStat.stat[sous_theme].quantite == pw3.Stat.dataStat.stat[sous_theme].vu && (pw3.Stat.dataStat.stat[sous_theme].risque > 0 || this.test_asset.is_risque == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].signalisation > 0 || this.test_asset.is_signalisation == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].test_thematique == 3 || pw3.Stat.dataStat.stat[sous_theme].test_id == '' || pw3.Stat.dataStat.stat[sous_theme].test_id == null)) {
                    pw3.Stat.dataStat.stat[sous_theme].is_fini = 3;
                }
                else if (pw3.Stat.dataStat.stat[sous_theme].quantite == pw3.Stat.dataStat.stat[sous_theme].vu && (pw3.Stat.dataStat.stat[sous_theme].risque > 0 || this.test_asset.is_risque == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].signalisation > 0 || pw3.Stat.dataStat.stat[sous_theme].is_signalisation == false)) {
                    pw3.Stat.dataStat.stat[sous_theme].is_fini = 2;
                }
                else {
                    pw3.Stat.dataStat.stat[sous_theme].is_fini = 1;
                }
                //=====================================================
                // Module theme
                //=====================================================
                var theme = pw3.Stat.dataStat.stat[this.idFomation].theme;

                var nb_risque = 0;
                var nb_fini = 0;
                var nb_sous_theme_3 = 0;
                var nb_sous_theme_2 = 0;

                for(var idStat in pw3.Stat.dataStat.stat) {
                    if(pw3.Stat.dataStat.stat[idStat].theme == theme && pw3.Stat.dataStat.stat[idStat].type == 'Module' && pw3.Stat.dataStat.stat[idStat].risque > 0){
                        nb_risque += pw3.Stat.dataStat.stat[idStat].risque;
                    }

                    if (pw3.Stat.dataStat.stat[idStat].theme == theme && pw3.Stat.dataStat.stat[idStat].type == 'Sequence' && pw3.Stat.dataStat.stat[idStat].ouvert > 0){
                        if(pw3.Stat.dataStat.stat[idStat].is_fini == 3){nb_fini++;}
                    }

                    if(pw3.Stat.dataStat.stat[idStat].theme == theme && pw3.Stat.dataStat.stat[idStat].type == 'Module'){
                        if(pw3.Stat.dataStat.stat[idStat].is_fini == 3){nb_sous_theme_3++;}
                        if (pw3.Stat.dataStat.stat[idStat].is_fini > 1){nb_sous_theme_2++;}
                    }
                }
                pw3.Stat.dataStat.stat[theme].risque = nb_risque;

                if(pw3.Stat.dataStat.stat[theme].nombre_sous_theme == nb_sous_theme_3){pw3.Stat.dataStat.stat[theme].is_fini = 3;}
                else if(pw3.Stat.dataStat.stat[theme].nombre_sous_theme == nb_sous_theme_2){pw3.Stat.dataStat.stat[theme].is_fini = 2;}
                else{pw3.Stat.dataStat.stat[theme].is_fini = 1;}

                pw3.Stat.setStatPW3Thematique();
            }
        }
        catch(e){catchError(e);}
    },
    setStatPW3SequenceSequence : function(){
        try{
            pw3.Stat.dataStat.stat[this.idFomation].ouvert++;
            pw3.Stat.dataStat.stat[this.idFomation].duree_reel += this.oData.duree_reel;
            pw3.Stat.dataStat.stat[this.idFomation].duree_stat += this.oData.duree_stat;
            pw3.Stat.dataStat.stat[this.idFomation].duree_texte += this.oData.duree_texte;

            if (this.oData.ouvert_texte > pw3.Stat.dataStat.stat[this.idFomation].ouvert_texte) {
                pw3.Stat.dataStat.stat[this.idFomation].ouvert_texte = this.oData.ouvert_texte;
            }

            if(pw3.Stat.dataStat.stat[this.idFomation].is_fini < 3){
                if (this.oData.is_fini == 1 || this.oData.ouvert_texte == 2) {
                    pw3.Stat.dataStat.stat[this.idFomation].is_fini = 3;
                    pw3.Stat.dataStat.stat[this.idFomation].vu = 1;
                }
                else{
                    pw3.Stat.dataStat.stat[this.idFomation].is_fini = 0;
                }
            }

            if (pw3.Stat.dataStat.stat[this.idFomation].date == '') {
                pw3.Stat.dataStat.stat[this.idFomation].date = this.oData.date_debut;
            }
        }
        catch(e){catchError(e);}
    },
    setStatPW3SequencePartie : function(){
        try{
            var nb_fini = 0;
            var nb_ouvert = 0;
            var partie = pw3.Stat.dataStat.stat[this.idFomation].partie;
            var countSequence = 0;

            var quantite = pw3.Stat.dataStat.stat[partie].quantite;
            pw3.Stat.dataStat.stat[partie].duree_reel += this.oData.duree_reel;
            pw3.Stat.dataStat.stat[partie].duree_stat += this.oData.duree_stat;
            pw3.Stat.dataStat.stat[partie].duree_texte += this.oData.duree_texte;
            if (this.oData.ouvert_texte == 2) {
                pw3.Stat.dataStat.stat[partie].validation_texte++;
            }

            for(var stat in pw3.Stat.dataStat.stat) {
                if (pw3.Stat.dataStat.stat[stat].partie == partie && pw3.Stat.dataStat.stat[stat].type == 'Sequence' && pw3.Stat.dataStat.stat[stat].ouvert > 0) {
                    countSequence++;

                    if (pw3.Stat.dataStat.stat[stat].is_fini == 3) {nb_fini++;}

                    nb_ouvert += pw3.Stat.dataStat.stat[stat].ouvert;

                    if(pw3.Stat.dataStat.stat[partie].quantite == countSequence){break;}
                }
            }

            pw3.Stat.dataStat.stat[partie].vu = nb_fini;
            if (quantite == nb_fini) {
                pw3.Stat.dataStat.stat[partie].is_fini = 3;
            }
            else if(nb_fini > 0){
                pw3.Stat.dataStat.stat[partie].is_fini = 1;
            }
            pw3.Stat.dataStat.stat[partie].ouvert = nb_ouvert;
        }
        catch(e){catchError(e);}
    },
    setStatPW3SequenceModule : function(){
        try{
            var nb_fini = 0;
            var nb_ouvert = 0;
            var sous_theme = pw3.Stat.dataStat.stat[this.idFomation].sous_theme;
            var quantite = pw3.Stat.dataStat.stat[sous_theme].quantite;
            var countSousTheme = 0;

            pw3.Stat.dataStat.stat[sous_theme].duree_reel += this.oData.duree_reel;
            pw3.Stat.dataStat.stat[sous_theme].duree_stat += this.oData.duree_stat;
            pw3.Stat.dataStat.stat[sous_theme].duree_texte += this.oData.duree_texte;

            if (this.oData.ouvert_texte == 2) {
                pw3.Stat.dataStat.stat[sous_theme].validation_texte++;
            }


            for(var stat in pw3.Stat.dataStat.stat) {
                if (pw3.Stat.dataStat.stat[stat].sous_theme == sous_theme && pw3.Stat.dataStat.stat[stat].type == 'Sequence' && pw3.Stat.dataStat.stat[stat].ouvert > 0) {
                    countSousTheme++;

                    if(pw3.Stat.dataStat.stat[stat].is_fini == 3){nb_fini++;}

                    nb_ouvert += pw3.Stat.dataStat.stat[stat].ouvert;

                    if(pw3.Stat.dataStat.stat[sous_theme].quantite == countSousTheme){break;}
                }
            }
            pw3.Stat.dataStat.stat[sous_theme].vu = nb_fini;

            if(quantite == nb_fini && (pw3.Stat.dataStat.stat[sous_theme].risque > 0 || this.test_asset.is_risque == false) &&
                (pw3.Stat.dataStat.stat[sous_theme].signalisation > 0 || this.test_asset.is_signalisation == false) &&
                (pw3.Stat.dataStat.stat[sous_theme].test_thematique == 3 || pw3.Stat.dataStat.stat[sous_theme].test_id == ''
                || pw3.Stat.dataStat.stat[sous_theme].test_id == null)) {
                pw3.Stat.dataStat.stat[sous_theme].is_fini = 3;
            }
            else if (quantite == nb_fini && (pw3.Stat.dataStat.stat[sous_theme].risque > 0 || this.test_asset.is_risque == false)
                && (pw3.Stat.dataStat.stat[sous_theme].signalisation > 0 || this.test_asset.is_signalisation == false)
            ){
                pw3.Stat.dataStat.stat[sous_theme].is_fini = 2;
            }else if(nb_fini > 0){
                pw3.Stat.dataStat.stat[sous_theme].is_fini = 1;
            }

            pw3.Stat.dataStat.stat[sous_theme].ouvert = nb_ouvert;
        }
        catch(e){catchError(e);}
    },
    setStatPW3Thematique : function(){
        try{this.setStatPW3ThematiqueCache(this.test_asset, this.oData.id_formation, this.oData.is_fini, this.oData.duree_reel, this.oData.duree_stat, "theme" );}
        catch(e){catchError(e);}
    },
    setStatPW3ThematiqueCache : function(test_asset, id_formation, is_fini, duree_reel, duree_stat, type_theme){
        try{
            if(pw3.Stat.dataStat.stat[id_formation]){
                if(is_fini==1){is_fini=3;}else{is_fini=1;}

                var sous_theme = (type_theme == "theme" ? pw3.Stat.dataStat.stat[id_formation].sous_theme : id_formation);

                //Cas Risque & sanction
                if(!sous_theme){sous_theme = cleanID(pw3.Page.splitUrl[2]);}
                //Cas du test thématique
                if(!sous_theme){sous_theme = cleanID(elementThis.attr("id"));}
                //Sous theme pour les test thematique
                if(!sous_theme){sous_theme = cleanID(pw3.Examens.origineUrlExamen[1]);}

                if(pw3.Page.splitUrl[0] == "examens" && is_fini > pw3.Stat.dataStat.stat[sous_theme].test_thematique){
                    pw3.Stat.dataStat.stat[sous_theme].test_thematique = is_fini;
                    pw3.Stat.dataStat.stat[sous_theme].duree_reel += duree_reel;
                    pw3.Stat.dataStat.stat[sous_theme].duree_stat += duree_stat;
                }

                if (pw3.Stat.dataStat.stat[sous_theme].quantite == pw3.Stat.dataStat.stat[sous_theme].vu &&
                    (pw3.Stat.dataStat.stat[sous_theme].risque > 0 || test_asset.is_risque == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].signalisation > 0 || test_asset.is_signalisation == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].test_thematique == 3 || pw3.Stat.dataStat.stat[sous_theme].test_id == '' || pw3.Stat.dataStat.stat[sous_theme].test_id == null)) {
                    pw3.Stat.dataStat.stat[sous_theme].is_fini = 3;
                }
                else if(pw3.Stat.dataStat.stat[sous_theme].quantite == pw3.Stat.dataStat.stat[sous_theme].vu &&
                    (pw3.Stat.dataStat.stat[sous_theme].risque > 0 || test_asset.is_risque == false) &&
                    (pw3.Stat.dataStat.stat[sous_theme].signalisation > 0 || test_asset.is_signalisation == false)) {
                    pw3.Stat.dataStat.stat[sous_theme].is_fini = 2;
                }

                //=====================================================
                // Module theme
                //=====================================================
                var nb_fini = 0;
                var nb_sous_theme_1 = 0;
                var nb_sous_theme_2 = 0;
                var nb_sous_theme_3 = 0;
                var nb_thematique_0 = 0;
                var nb_thematique_1 = 0;
                var nb_thematique_2 = 0;
                var nb_thematique_t = 0;

                var theme = (type_theme == "theme" ? pw3.Stat.dataStat.stat[id_formation].theme : pw3.Stat.dataStat.stat[sous_theme].theme);

                for(var idStat in pw3.Stat.dataStat.stat){
                    if (pw3.Stat.dataStat.stat[idStat].theme == theme && pw3.Stat.dataStat.stat[idStat].type == 'Module') {
                        if(pw3.Stat.dataStat.stat[idStat].is_fini == 3){nb_sous_theme_3++;}
                        if(pw3.Stat.dataStat.stat[idStat].is_fini == 2){nb_sous_theme_2++;}
                        if(pw3.Stat.dataStat.stat[idStat].is_fini == 1){nb_sous_theme_1++;}
                        if(pw3.Stat.dataStat.stat[idStat].test_thematique == 0){nb_thematique_0++;}
                        if(pw3.Stat.dataStat.stat[idStat].test_thematique == 1){nb_thematique_1++;nb_thematique_t++;}
                        if(pw3.Stat.dataStat.stat[idStat].test_thematique == 3){nb_thematique_2++;nb_thematique_t++;}
                    }
                }

                //si tous les test thematique sont fini et réussit
                if(pw3.Stat.dataStat.stat[theme].nombre_sous_theme == nb_sous_theme_3){pw3.Stat.dataStat.stat[theme].is_fini = 3;}
                else if(pw3.Stat.dataStat.stat[theme].nombre_sous_theme == nb_sous_theme_2){pw3.Stat.dataStat.stat[theme].is_fini = 2;}
                else{pw3.Stat.dataStat.stat[theme].is_fini = (nb_sous_theme_1 > 0 ? 1 : nb_sous_theme_1);}

                if(pw3.Stat.dataStat.stat[theme].nombre_sous_theme == nb_thematique_2){pw3.Stat.dataStat.stat[theme].test_thematique = 3;}
                else if(pw3.Stat.dataStat.stat[theme].nombre_sous_theme == nb_thematique_t){pw3.Stat.dataStat.stat[theme].test_thematique = 2;}
                else if(nb_thematique_t > 0){pw3.Stat.dataStat.stat[theme].test_thematique = 1;}
                else{pw3.Stat.dataStat.stat[theme].test_thematique = 0;}

                pw3.Stat.dataStat.stat[theme].duree_reel += duree_reel;
                pw3.Stat.dataStat.stat[theme].duree_stat += duree_stat;

                //=====================================================
                // Formation
                //=====================================================
                pw3.Stat.dataStat.stat[theme].duree_reel += duree_reel;
                pw3.Stat.dataStat.stat[theme].duree_stat += duree_stat;

                //On met à jour le cache des statistiques dans le session storage
                var update = new CallObj("", "", pw3.Stat.dataStat.stat, "update", "formation");
                update.getObj();
            }
        }
        catch(e){catchError(e);}
    }
};

pw3.Page.prototype = {
    AffichePage: function(newMenu, newParam){

        AfficheAccueil = function() {
            try {
                if(cantReadLocalStorage > 1){
                    pw3.Page.AfficheNavigationPrivee();
                    return ;
                }
                
                var objResult = {};
                objResult.classToAddBoddy = "index";
                this.ParamUrl = "";
                this.lettreNewParam = "";
                //efface les images en basse64 du sessionstorage
                cleanImageQuestionInSS();
                //Nettoie le DOM des balises Audio qui servent a précharger les MP3
                cleanAudioQuestionInDom();
                //Nettoie le DOM des balises Video qui servent a précharger les MP4
                cleanVideoQuestionInDom();

                if(
                    ((pw3.Droit.obDroit)&&(pw3.Page.obEleve)&&(pw3.Page.connect)&&(pw3.Page.connect===4))
                    || ((login_password === true)&&(pw3.Page.obEleve)&&(pw3.Page.connect)&&(pw3.Page.connect===4))
                ){
                    pw3.Page.AffichePageAccueil();
                    this.urlPage = this.ParamUrl;
                    locationHref = this.urlPage;
                    pushHistory(locationHref);
                    personnalise();

                    return objResult;
                }

                var modeMaintenace = maintenance();
                if(modeMaintenace.maintenance==true){
                    objResult.classToAddBoddy += " modeMaintenace";
                    pw3.Page.AffichePageMaintenace(modeMaintenace.description);
                    return objResult;
                }

                var oldNavigateur = testNavigateur();
                if(oldNavigateur==true){
                    objResult.classToAddBoddy += " oldnavigateur";
                    pw3.Page.AffichePageObsolette();
                    return objResult;
                }

                var isMobile = detectMobile();
                if(isMobile=="true"){
                    objResult.classToAddBoddy += " mobile";
                    pw3.Page.AffichePageMobile();
                    return objResult;
                }

                if(!pw3.Page.obEleve){pw3.Page.obEleve = {};}

                pw3.Page.AffichePageLogin();

                //Mot de passe invalide
                if(pw3.Page.connect===0){
                    var id0 = "erreurPasseBox";
                    var body0 = '<div class="contentAscenseur scrollerBox"><div class="label center">Il y a une erreur dans la saisie de votre mot de passe. Merci de recommencer.<br/><br/><br/></div><div class="submit center"><input type="submit" id="recommencerMdp" value="Recommencer" class="submit link closeBox" /></div></div>';
                    var picto0 = "J";
                    var titre0 = " Mot de passe erron&eacute;";
                    var idBox0 = "erreurPasse";
                    var boxConstruct0 = new pw3.box(id0, titre0, picto0, body0, idBox0);
                    var html0 = boxConstruct0.getHtml();
                    $("#listeBox").html(html0);
                    AfficheBoiteMessage("#"+idBox0, true);
                    sessionStorage.clear();
                }

                //Compte verrouillé
                if(pw3.Page.connect===1){
                    var id1 = "isVerrouille";
                    var body1 = 'Votre compte est verrouill&eacute;, merci de contacter votre auto-&eacute;cole.';
                    var picto1 = "J";
                    var titre1 = " Etat de votre compte";
                    var idBox1 = "boxIsVerrouille";
                    var boxConstruct1 = new pw3.box(id1, titre1, picto1, body1, idBox1);
                    var html1 = boxConstruct1.getHtml();
                    $("#listeBox").html(html1);
                    AfficheBoiteMessage("#"+idBox1, true);
                }

                //Compte archivé
                if(pw3.Page.connect===2){
                    var id1 = "compteArchive";
                    var body1 = 'Votre compte est archiv&eacute;, merci de contacter votre auto-&eacute;cole.';
                    var picto1 = "J";
                    var titre1 = " Compte archiv&eacute;";
                    var idBox1 = "boxCompteArchive";
                    var boxConstruct1 = new pw3.box(id1, titre1, picto1, body1, idBox1);
                    var html1 = boxConstruct1.getHtml();
                    $("#listeBox").html(html1);
                    AfficheBoiteMessage("#"+idBox1, true);
                }

                //Première connexion d'un élève Code Free ou généré
                if(pw3.Page.connect===3){
                    var id1 = "premiereConnexion";
                    var boxPremiereConnection = new pw3.premiereConnection();
                    var body1 = boxPremiereConnection.getHtml();
                    var picto1 = "D";
                    var titre1 = " Vos informations";
                    var idBox1 = "boxPremiereConnexion";
                    var boxConstruct1 = new pw3.box(id1, titre1, picto1, body1, idBox1);
                    var html1 = boxConstruct1.getHtml();
                    $("#listeBox").html(html1);
                    AfficheBoiteMessage("#"+idBox1, true);
                }

                return objResult;
            }
            catch(e){
                catchError(e);
                pw3.Page.connect = 0; //je déconnect l'éléve.
                return "";
            }
        };

        AfficheCours = function(formationFunction, dataAsset, newMenu, newParam){
            try {
                console.log('AfficheCours');
                addGoogleAnalyticsLine('/cours', 'Cours');
                $(".titreModulePrincipal").text("FORMATION THEORIQUE AU PERMIS DE CONDUIRE");
                $("#couleurBarreHorizontal").attr("style", "background-color:#64b8ac");

                //Pour ne pas charger la structure du site si elle est déjà chargée
                if(isStructureLoaded===false){
                    var objEleve = new CallObj("oEleve", "", "", "sessionStorage", "eleve");
                    pw3.Page.obEleve = objEleve.getObj();
                    pw3.Page.AfficheStructurePage();
                }
                var objResult = {};
                objResult.classToAddBoddy = "";
                var findParamElementCoursByUrl = function(){
                    var newParamLenght = 0;
                    var newParamLastElement;

                    if((newParam) && (newParam!="")){
                        newParamLenght = newParam.split("-").length;
                        var newParamSplit = newParam.split("-");
                        pw3.Page.splitUrl = attrUrl(newParam);
                    }

                    if(newParamLenght>=2){newParamLastElement = newParamSplit[newParamSplit.length - 1];}
                    else if(newParam){newParamLastElement = newParam;}

                    if ((newParamLastElement == "signalisation") || (newParamLastElement == "risque")) {
                        this.newParam = newParamLastElement;
                        this.lettreNewParam = this.newParam;
                    }
                    else if (newParamLastElement) {
                        this.newParam = newParamLastElement;
                        this.lettreNewParam = this.newParam.substr(0, 1);
                    }
                    else if(newParam) {
                        var splitUrlPop = attrUrl();
                        this.newParam = splitUrlPop[splitUrlPop.length - 1];
                        this.lettreNewParam = this.newParam.substr(0, 1);
                    }
                    this.ParamUrl = newMenu;
                    if ((pw3.Page.splitUrl.length > 1) || (this.newParam != "cours")) {
                        if ((this.newParam === "signalisation") || (this.newParam === "risque")) {
                            this.ParamUrl = pw3.Page.splitUrl[0] + "-" + pw3.Page.splitUrl[1] + "-" + pw3.Page.splitUrl[2] + "-" + this.newParam;
                        }
                        else{
                            if (this.lettreNewParam === "s") {
                                this.ParamUrl = pw3.Page.splitUrl[0] + "-" + pw3.Page.splitUrl[1] + "-" + pw3.Page.splitUrl[2] + "-" + pw3.Page.splitUrl[3] + "-" + this.newParam;
                            }
                            else if (this.lettreNewParam === "p") {
                                this.ParamUrl = pw3.Page.splitUrl[0] + "-" + pw3.Page.splitUrl[1] + "-" + pw3.Page.splitUrl[2] + "-" + this.newParam;
                            }
                            else if (this.lettreNewParam === "m") {
                                this.ParamUrl = pw3.Page.splitUrl[0] + "-" + pw3.Page.splitUrl[1] + "-" + this.newParam;
                            }
                            else if (this.lettreNewParam === "c") {
                                this.ParamUrl = pw3.Page.splitUrl[0] + "-" + this.newParam;
                                $("#contenuAjax").css("backgroundImage", "url("+RACINE_CLOUDFRONT+"packweb3/image/fond_screen2.jpg)");
                            }else{
                                $("#contenuAjax").css("backgroundImage", "url("+RACINE_CLOUDFRONT+"/packweb3/image/fond_screen2.jpg)");
                            }
                            this.idLastElementUrl = this.newParam;
                        }
                    }
                    else{
                        this.lettreNewParam = "";
                        $("#contenuAjax").css("backgroundImage", "url("+RACINE_CLOUDFRONT+"packweb3/image/fond_screen2.jpg)");
                    }

                    this.urlPage = this.ParamUrl;

                    locationHref = this.urlPage;

                    pushHistory(locationHref);
                };
                var initCours = function(formationFunction){
                    pw3.Page.splitUrl = attrUrl(newParam);
                    if(pw3.Page.splitUrl[1]){
                        this.couleur = formationFunction.getCouleurCours(cleanID(pw3.Page.splitUrl[1]));

                        this.picto = pw3.Stat.inspectPictoAvancement(pw3.Stat.dataStat.stat[cleanID(pw3.Page.splitUrl[1])].is_fini, false, "").pictoAvancement;
                    }

                    if(pw3.Page.splitUrl[2]){
                        this.titreModule = formationFunction.getTitreModule(cleanID(pw3.Page.splitUrl[1]), cleanID(pw3.Page.splitUrl[2]));
                        this.puce = formationFunction.getPuce(cleanID(pw3.Page.splitUrl[1]), cleanID(pw3.Page.splitUrl[2]));
                        this.themeId = formationFunction.getThemeId(cleanID(pw3.Page.splitUrl[1]), cleanID(pw3.Page.splitUrl[2]));
                        pw3.Page.themeId = this.themeId;
                        this.pictoModule = pw3.Stat.inspectPictoAvancement(pw3.Stat.dataStat.stat[cleanID(pw3.Page.splitUrl[2])].is_fini, false, "").pictoAvancement;
                    }

                    if(pw3.Page.splitUrl[3]){
                        if((pw3.Page.splitUrl[3]!="signalisation")&&(pw3.Page.splitUrl[3]!="risque")){
                            this.titrePartie = formationFunction.getTitrePartie(cleanID(pw3.Page.splitUrl[1]), cleanID(pw3.Page.splitUrl[2]), cleanID(pw3.Page.splitUrl[3]));
                        }
                    }
                };
                var classBody = function(){
                    var objResult = {};
                    var lastElement = (pw3.Page.splitUrl ? pw3.Page.splitUrl[pw3.Page.splitUrl.length - 1] : "");
                    var firstLetter = lastElement.charAt(0);
                    if(pw3.Page.splitUrl[3]==="risque"){objResult.classToAddBoddy = "risques pedagogie";}
                    else if(pw3.Page.splitUrl[3]==="signalisation"){objResult.classToAddBoddy = "panneaux pedagogie";}
                    else if(firstLetter==="s"){
                        objResult.classToAddBoddy = "cours sequence";
                        //si pourquoi de la regle on ajoute une class spésifique
                        if((pw3.Page.splitUrl[4])&&(cleanID(pw3.Page.splitUrl[4])===findPourquoiRegle().idSequence)){
                            objResult.classToAddBoddy += " pourquoiRegle";
                        }
                    }
                    else if(firstLetter==="p"){objResult.classToAddBoddy = "cours partie";}
                    else if(firstLetter==="m"){objResult.classToAddBoddy = "cours module";}
                    else if(firstLetter==="c"){objResult.classToAddBoddy = "accueil cours";}
                    else if(firstLetter==""){objResult.classToAddBoddy = "index";}
                    return objResult.classToAddBoddy;
                };
                var initFooterForModule = function(moduleStat){
                    var moduleToEnable = testAsset(this.themeId);

                    if(moduleToEnable.is_risque){
                        $("#menuFourtous li.risque").removeClass("masque").addClass("grayscale");
                        $("#menuFourtous li.risque div").removeClass("nolink").addClass("link");
                        if(moduleStat.risque > 0){$("#menuFourtous li.risque").removeClass("grayscale");}
                    }
                    else{
                        $("#menuFourtous li.risque").addClass("masque grayscale");
                        $("#menuFourtous li.risque div").removeClass("link").addClass("nolink");
                    }

                    if(moduleToEnable.is_signalisation){
                        $("#menuFourtous li.panneaux").removeClass("masque").addClass("grayscale");
                        $("#menuFourtous li.panneaux div").removeClass("nolink").addClass("link");
                        if (moduleStat.signalisation > 0) {$("#menuFourtous li.panneaux").removeClass("grayscale");}
                    }
                    else{
                        $("#menuFourtous li.panneaux").addClass("masque grayscale");
                        $("#menuFourtous li.panneaux div").removeClass("link").addClass("nolink");
                    }

                    var pourQuoiRegleVu = inspectPourquoiRegleVu();
                    $("#menuFourtous li.pourquoi").addClass("grayscale");
                    if(pourQuoiRegleVu == 1){$("#menuFourtous li.pourquoi").removeClass("grayscale");}

                };
                var CoursNews = function(formationFunction){
                    this.lettreNewParam = "";
                    this.idLastElementUrl = "";

                    findParamElementCoursByUrl();
                    initCours(formationFunction);

                    if(pw3.Page.splitUrl[3]==="risque") {
                        pw3.Page.AfficheRisque(dataAsset.getTree(this.ParamUrl), pw3.Stat.dataStat, this.titreModule, this.couleur, this.themeId, this.puce, this.pictoModule);
                    }
                    else if(pw3.Page.splitUrl[3]==="signalisation"){
                        pw3.Page.AfficheSignalisation(formationFunction.getTree(this.ParamUrl), pw3.Stat.dataStat, this.titreModule, this.couleur, this.themeId, this.puce, this.pictoModule);
                    }
                    else if(this.lettreNewParam==="s"){
                        pw3.Page.AfficheMenuSequence(formationFunction.getTree(this.ParamUrl), pw3.Stat.dataStat, this.titrePartie, this.couleur, this.pictoModule, this.puce);
                    }
                    else if(this.lettreNewParam==="p"){
                        pw3.Page.AfficheMenuPartie(formationFunction.getTree(this.ParamUrl), pw3.Stat.dataStat, this.titrePartie, this.couleur, this.puce, this.titreModule, this.pictoModule);
                    }
                    else if(this.lettreNewParam==="m"){
                        var pourQuoiRegleOuvert = inspectPourquoiRegleOuvert();
                        if((pourQuoiRegleOuvert === 0)||(pourQuoiRegleOuvert === "")){AffichePourquoiRegle();}
                        else{pw3.Page.AfficheMenuModule(formationFunction.getTree(this.ParamUrl), pw3.Stat.dataStat, this.pictoModule);}
                        initFooterForModule(pw3.Stat.dataStat.stat[formationFunction.getTree(this.ParamUrl).id]);
                    }
                    else if(this.lettreNewParam==="c"){
                        $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                        pw3.Page.AfficheMenuFormation(formationFunction.getMenuInfo());
                        pw3.Page.AfficheVignetteFormation(formationFunction.getVinetteInfo(), pw3.Stat.dataStat, "cours");
                        pw3.Page.AfficheVignetteCours(this.idLastElementUrl, pw3.Stat.dataStat);
                    }
                    else if(this.lettreNewParam==""){
                        $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                        pw3.Page.AfficheMenuFormation(formationFunction.getMenuInfo());
                    }
                };
                CoursNews(formationFunction);
                objResult.classToAddBoddy = classBody();
                return objResult;
            }
            catch(e){
                //On force le rechargement de la formation
                catchError(e);
                //sendLog("exception","AfficheCours", e);
                reloadFormation(true);
                return "";
            }
        };

        AfficheExamens = function(formationFunction, dataAsset, newMenu, newParam){
            try {
                console.log('AfficheExamens');
                addGoogleAnalyticsLine('/examens', 'Tests ETG');
                function LanceTestExamen(){
                    initContenuAjax();

                    //affiche la page d'examen
                    var examensFunction = new ExamensFunction();
                    var dataTest = {};

                    if (sessionStorage.getItem("SS_TestEnCours1") !== null) {
                        dataTest.nbQuestion = pw3.Examens.oTestEnCours.nbQuestion;
                        dataTest.oTestEnCours = pw3.Examens.oTestEnCours;
                        dataTest.oTestEnCours.Compteur = pw3.Examens.oTestEnCours.Compteur;
                        dataTest.typeTest = {};
                        dataTest.typeTest.type = pw3.Examens.TestCorrection.Type;
                        dataTest.typeTest.numTest = pw3.Examens.TestCorrection.NumTest;
                        pw3.Page.AfficheExamensPage(dataTest);
                        objResult.classToAddBoddy = "examens";
                    }
                    else {
                        examensFunction.getTest().then(function (retour) {
                            pw3.Page.AfficheExamensPage(retour);
                            objResult.classToAddBoddy = "examens";
                        });
                    }
                }

                if (isStructureLoaded === false) {
                    var objEleve = new CallObj("oEleve", "", "", "sessionStorage", "eleve");
                    pw3.Page.obEleve = objEleve.getObj();
                    pw3.Page.AfficheStructurePage();
                }

                var objResult = {};
                objResult.classToAddBoddy = "";
                var findParamElementExamByUrl = function () {
                    var newParamLenght = 0;
                    var newParamLastElement;

                    if ((newParam) && (newParam != "")) {
                        newParamLenght = newParam.split("-").length;
                        var newParamSplit = newParam.split("-");
                        pw3.Page.splitUrl = attrUrl(newMenu + '-' + newParam);
                    }

                    if (newParamLenght >= 2) {
                        newParamLastElement = newParamSplit[newParamSplit.length - 1];
                    }
                    else if (newParam) {
                        newParamLastElement = newParam;
                    }

                    if (newParamLastElement) {
                        this.newParam = newParamLastElement;
                        this.lettreNewParam = this.newParam.substr(0, 1);
                    }
                    else if (newParam) {
                        var splitUrlPop = attrUrl();
                        this.newParam = splitUrlPop[splitUrlPop.length - 1];
                        this.lettreNewParam = this.newParam.substr(0, 1);
                    }

                    this.ParamUrl = newMenu + '-' + this.newParam;

                    if ((pw3.Page.splitUrl.length > 1) || (this.newParam != "examens")) {
                        if (this.lettreNewParam === "c") {
                            this.ParamUrl = pw3.Page.splitUrl[0] + "-" + this.newParam;
                            $("#contenuAjax").css("backgroundImage", "url(" + RACINE_CLOUDFRONT + "packweb3/image/fond_screen2.jpg)");
                        }
                        else {
                            $("#contenuAjax").css("backgroundImage", "url(" + RACINE_CLOUDFRONT + "packweb3/image/fond_screen2.jpg)");
                        }
                        this.idLastElementUrl = this.newParam;
                    }
                    else {
                        this.lettreNewParam = "";
                        $("#contenuAjax").css("backgroundImage", "url(" + RACINE_CLOUDFRONT + "packweb3/image/fond_screen2.jpg)");
                    }

                    this.urlPage = this.ParamUrl;

                    locationHref = this.urlPage;

                    pushHistory(locationHref);
                };
                var initExamen = function (formationFunction) {
                    pw3.Page.splitUrl = attrUrl(newParam);

                    reloadFormation();
                    //Sécurité pour palier le bug
                    pw3.Page.splitUrl = (!pw3.Page.splitUrl ? attrUrl('examen-examen') : pw3.Page.splitUrl);

                    if (pw3.Page.dataFormation.formation["tabChild"][cleanID(pw3.Page.splitUrl[1])]) {
                        this.couleur = formationFunction.getCouleurCours(cleanID(pw3.Page.splitUrl[1]));
                        this.picto = pw3.Stat.inspectPictoAvancement(pw3.Stat.dataStat.stat[cleanID(pw3.Page.splitUrl[1])].is_fini, false, "").pictoAvancement;
                    }
                };
                this.lettreNewParam = "";
                this.idLastElementUrl = "";

                findParamElementExamByUrl();
                initExamen(formationFunction);

                //met en forme newParam et en enlevant newMenu se produit quand on appuie sur F5 ou si on rafraichie la page
                if(newParam){newParam = newParam.replace(newMenu+"-", "");}

                //Initialise newparam sur raffraichissement si nécésaire
                if(pw3.Examens.oTestEnCours && newParam && (newParam.charAt(0) == "m") && (ObjectLength(pw3.Examens.oTestEnCours.Question) < pw3.Examens.oTestEnCours.Compteur)){
                    newParam = "resultat";
                }

                if((newMenu === "examens")&&((!newParam)||(newParam == ""))&&(newParam != "examens")){
                    //Affiche la page d'accueil des tests thématique
                    $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                    $("#contenuAjax").css("backgroundImage", "url(" + RACINE_CLOUDFRONT + "packweb3/image/fond_screen2.jpg)");
                    pw3.Page.AfficheMenuFormation(formationFunction.getMenuInfo());
                    objResult.classToAddBoddy = "accueil examens";
                }
                else if((newMenu === "examens") && ((newParam === "examens") || (this.newParam === "examens"))) {
                    //Lancement d'un nouveau test
                    elementThis = "";
                    LanceTestExamen();
                }
                else if(this.lettreNewParam=="c") {
                    //Affiche la page d'accueil avec le menu des tests thématique.
                    pw3.Page.AfficheMenuFormation(formationFunction.getMenuInfo(), pw3.Stat.dataStat);
                    pw3.Page.AfficheVignetteFormation(formationFunction.getVinetteInfo(), pw3.Stat.dataStat, "examens");
                    pw3.Page.AfficheVignetteCours(this.idLastElementUrl, pw3.Stat.dataStat);
                    objResult.classToAddBoddy = "accueil examens";
                }
                else if(newParam.substr(0,6)==="revoir"){
                    //@David : Quand cette partie est appelée
                    var idQuestion = newParam.replace("revoir", "");
                    var idTheme = pw3.Examens.TestCorrection.NumTest;
                    var picto;

                    if( (parseInt(idTheme) !== ID_PRODUIT_TEST_ALEATOIRE) && (parseInt(idTheme) !== 0) ){
                        var idSousTheme;
                        for(var idDataStat in pw3.Stat.dataStat.stat){
                            if(pw3.Stat.dataStat.stat[idDataStat].test_id && pw3.Stat.dataStat.stat[idDataStat].test_id == parseInt(idTheme)){
                                idSousTheme = idDataStat;
                                break;
                            }
                        }
                        picto = pw3.Stat.inspectPictoAvancement(pw3.Stat.dataStat.stat[idSousTheme].is_fini, false, "").pictoAvancement;
                        pw3.Page.AfficheExamensPage(idQuestion, picto);
                    }
                    else{pw3.Page.AfficheExamensPage(idQuestion);}

                    objResult.classToAddBoddy = "revoirQuestion examens";

                }
                else if((newMenu === "examens") && (newParam) && (newParam != "resultat")) {
                    //@David : Quand cette partie est appelée
                    LanceTestExamen();
                }
                else if((newMenu === "examens") && (newParam) && (newParam == "resultat")) {
                    //page affichée à la fin des 40 questions
                    pw3.Page.AfficheExamensResultat(pw3.Examens.TestResultat, pw3.Examens.TestCorrection);
                    objResult.classToAddBoddy = "resultats examens";

                    //si c'est un test examen
                    if(pw3.Examens.TestCorrection.NumTest == ID_PRODUIT_TEST_ALEATOIRE || pw3.Examens.TestCorrection.NumTest == 0){
                        $("#nouveauTest").removeClass("none");
                        $("#retourThema, #retourFormation").addClass("none");
                    }
                    else{
                        //si on lance un test thematique depuis les cours
                        if(pw3.Examens.origineTypeExamen=="cours"){
                            $("#retourFormation").removeClass("none");
                            $("#nouveauTest, #retourThema").addClass("none");
                        }
                        else{
                            $("#retourThema").removeClass("none");
                            $("#nouveauTest, #retourFormation").addClass("none");
                        }
                    }
                }

                if(newParam){locationHref = newMenu + "-" + newParam;}
                else{locationHref = newMenu}

                return objResult;
            }
            catch(e){
                console.log('class AfficheExamens');
                catchError(e);
                //On force le rechargement de la formation
                //sendLog("exception","AfficheExamens", e);
                reloadFormation(true);
                return "";
            }
        };

        AfficheStatistiques = function(formationFunction, dataAsset, newMenu, newParam){
            try{
                console.log('AfficheStatistiques');
                addGoogleAnalyticsLine('/stats', 'Statistiques');
                if(isStructureLoaded===false){pw3.Page.AfficheStructurePage();}
                var objResult = {};
                objResult.classToAddBoddy = "";
                var findParamElementStatByUrl = function(){
                    var newParamLenght = 0;
                    var newParamLastElement;

                    if((newParam) && (newParam!="")){
                        newParamLenght = newParam.split("-").length;
                        var newParamSplit = newParam.split("-");
                        pw3.Page.splitUrl = attrUrl(newParam);
                    }

                    if(newParamLenght>=2){newParamLastElement = newParamSplit[newParamSplit.length - 1];}
                    else if(newParam){newParamLastElement = newParam;}

                    if (newParamLastElement) {
                        this.newParam = newParamLastElement;
                        this.lettreNewParam = this.newParam.substr(0, 1);
                    }
                    else if(newParam) {
                        var splitUrlPop = attrUrl();
                        this.newParam = splitUrlPop[splitUrlPop.length - 1];
                        this.lettreNewParam = this.newParam.substr(0, 1);
                    }
                    else{
                        //onglet par défaut si "newParam" n'est pas définit
                        this.newParam = "moncode";
                        this.lettreNewParam = this.newParam.substr(0, 1);
                    }

                    this.ParamUrl = newMenu + "-" + this.newParam;
                    $("#contenuAjax").css("backgroundImage", "url("+RACINE_CLOUDFRONT+"packweb3/image/fond_screen2.jpg)");
                    this.idLastElementUrl = this.newParam;


                    this.urlPage = this.ParamUrl;
                    locationHref = this.urlPage;

                    pushHistory(locationHref);
                };

                this.lettreNewParam = "";
                this.idLastElementUrl = "";

                findParamElementStatByUrl();

                objResult.classToAddBoddy = "accueil statistiques";
                if(newMenu===newParam) {
                    $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                    pw3.Page.AfficheStatistiquesMonCode("moncode");
                }
                else if(this.newParam==="moncode"){
                    $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                    pw3.Page.AfficheStatistiquesMonCode(this.newParam);
                }
                else if(this.newParam==="maconduite"){
                    $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                    pw3.Page.AfficheStatistiquesMaConduite(this.newParam);

                }
                else if(this.newParam==="monaac"){
                    $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                    pw3.Page.AfficheStatistiquesMonAAC(this.newParam);
                }
                else if((this.newParam==="mesoptions")||(this.newParam==="tvAdherence")||(this.newParam==="alcoolemie")||(this.newParam==="moto")||(this.newParam==="testsMobiles")||(this.newParam==="constat")||(this.newParam==="classeVirtuelle")){
                    $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                    pw3.Page.AfficheStatistiquesMesOptions(this.newParam);
                }
                else if(this.newParam==="mesinfos"){
                    $("#contenuAjax").html('<div id="degradeLivre" class="degradeLivre full"></div>');
                    pw3.Page.AfficheStatistiquesMesInfos(this.newParam);
                }

                return objResult;
            }
            catch(e){
                catchError(e);
                //On force le rechargement de la formation
                //sendLog("exception", "AfficheStatistiques", e);
                reloadFormation(true);
                return "";
            }
        };

        try{
            //Réinitialise la redirection après l'autologin
            if(newMenu=="reinitRediection"){
                console.log('réinit');
                newMenu = undefined;
                var resetPw3PageNewmenu = true;
            }

            var retour;

            //Pour arréter le timer quand il est lancé
            clearTimerStep();

            //**********************************************************
            //**********************************************************
            //*** IMPORTANT : Assure la cohérence de navigation (au rechargement de chaque page) pour l'élève connecté
            //**********************************************************
            //**********************************************************
            if ((numEleve == "") || (numAutoEcole == "")) {
                var objGetQueryString = getQueryString();
                var autoRedirect = false;

                if (!pw3.Page.obEleve) {pw3.Page.obEleve = {};}

                //Appel oAgence qui permet de choisir le méthode de compréssion et de chager l'agence,
                // obligatoire sur le page de login et chaque rafraichissement.
                if ((numAutoEcole == "") || (!numAutoEcole)) {lzStringTypeCompress();}

                //Auto-login
                //exemple : https://www.packweb3.com/?login=RXUBX8E&page=statistiques
                if (typeof objGetQueryString[0] !== 'undefined') {
                    var localprotocol =  location.protocol;
                    history.pushState("", "", localprotocol + "//" + document.location.host);
                    var redirectAfterLogin = true;
                    for (var idObjGetQueryString in objGetQueryString) {
                        if(objGetQueryString[idObjGetQueryString].name === "page") {
                            var newMenuRedirectSplit = objGetQueryString[idObjGetQueryString].value.split("-");
                            pw3.Page.newMenu = newMenuRedirectSplit[0];
                            pw3.Page.newParam = newMenuRedirectSplit[1];
                            redirectAfterLogin = false;
                            //Efface le session Storage à chaque auto connection
                            if(pw3.Page.newMenu=="statistiques"){
                                sessionStorage.clear();
                            }
                        }
                    }

                    //autologin
                    for (idObjGetQueryString in objGetQueryString) {
                        if (objGetQueryString[idObjGetQueryString].name === "login") {
                            pw3.Droit.Login(objGetQueryString[idObjGetQueryString].value, redirectAfterLogin);
                            break;
                        }
                    }
                }

                //Charge oEleve et oAgence qui est inclu dans oEleve.
                // Définition des variable global numEleve et numAutoEcole
                if ((numEleve == "") || (numEleve)) {
                    var objEleve = new CallObj("eleve", "", "", "sessionStorage", "eleve");
                    if (typeof(objEleve.getObj()) != "undefined") {
                        //en cas de corruption des données du session et local storage on suprimme tous et on les recharge.
                        if (!objEleve.getObj()) {
                            sessionStorage.clear();
                            localStorage.clear();
                            pw3.Page.AffichePageLogin();
                            return;
                        }
                        pw3.Page.obEleve = objEleve.getObj();
                    }
                    if (pw3.Page.obEleve) {numEleve = pw3.Page.obEleve.NumEleve;}
                }

                if(autoRedirect==false){
                    var objNewHistory = formatNewMenuParam(locationHref);
                    newMenu = objNewHistory.newMenu;
                    newParam = objNewHistory.newParam;
                }

                pw3.Droit.typeConnect();
            }


            if (cantReadLocalStorage > 1) {
                AfficheAccueil();
                return;
            }
            //Paramètre le mode debug
            debugPw();

            pw3.Page.splitUrl = attrUrl();
            var formationFunction;
            var assetFonction;
            formationFunction = new FormationFunction();
            assetFonction = new AssetFunction();

            if((pw3.Page.obEleve) && (pw3.Page.obEleve.Agence) && (pw3.Page.obEleve.Agence.NumAutoEcole)){
                numAutoEcole = pw3.Page.obEleve.Agence.NumAutoEcole;
            }

            console.log('numAutoEcole', numAutoEcole);
            console.log('numEleve', numEleve);
            console.log('pw3.Page.connect', pw3.Page.connect);

            //si je suis conecté
            if (
                (numAutoEcole !== "")
                && ((typeof(numEleve) !== "undefined") && (numEleve !== ""))
                && ((pw3.Page.connect !== null) && (pw3.Page.connect !== 0))
            ) {
                console.log('je suis connecté');
                new DataStat();
                new pw3.Droit.oDroit();
                //Charge le test en cours si il y en a un
                loadTestEnCours();
            }
            //reset et initialise les balises Audio et Video
            activeBaliseAudioVideo();

            //redirection sur page en fonction de la variable $_GET page
            if ((pw3.Page.newMenu) && (pw3.Page.newMenu != "") && (pw3.Page.obEleve.Flag !== "G")) {
                newMenu = pw3.Page.newMenu;
                newParam = pw3.Page.newParam;
                //Réinitialise la redirection après l'autologin
                if(resetPw3PageNewmenu){pw3.Page.newMenu = undefined;}
            }

            if (newMenu) {
                if ((pw3.Page.splitUrl[0] == "cours") || (newMenu == "cours")) {
                    retour = AfficheCours(formationFunction, assetFonction, newMenu, newParam);
                }
                if ((pw3.Page.splitUrl[0] == "examens") || (newMenu == "examens")) {
                    retour = AfficheExamens(formationFunction, assetFonction, newMenu, newParam);
                }
                if ((pw3.Page.splitUrl[0] == "statistiques") || (newMenu == "statistiques")) {
                    retour = AfficheStatistiques(formationFunction, assetFonction, newMenu, newParam);
                }
                if ((pw3.Page.splitUrl[0] == "") && (newMenu == "")) {
                    retour = AfficheAccueil();
                }
            }
            else {
                retour = AfficheAccueil();
                this.urlPage = "";
                locationHref = this.urlPage;
            }

            //gestion du fullscreen et des affichages specifique ...
            if((pw3.Page.obEleve.NumENPC) && (pw3.Page.obEleve.NumENPC !== null)){retour.classToAddBoddy += " enpcScreen";}
            if (window.innerHeight == screen.height){retour.classToAddBoddy += " fullScreen";}

            $("body").removeClass().addClass(retour.classToAddBoddy);

            if(retour==""){AfficheAccueil()}

        }
        catch(e){catchError(e);}
    },
    reloadObForLoginMdp : function(){
        var ObjectEleve= new CallObj("", "", "", "sessionStorage", "eleve");
        var retourEleve = ObjectEleve.getObj();
        var ObjectAutoecole= new CallObj("", "", "", "sessionStorage", "autoecole");
        var retourAutoecole = ObjectAutoecole.getObj();
        var ObjectDroit= new CallObj("", "", "", "sessionStorage", "odroit");
        var retourDroit = ObjectDroit.getObj();

        console.log('pw3.Droit.obDroit 4',pw3.Droit.obDroit);
        console.log('pw3.Page.obEleve 4',pw3.Page.obEleve);
        console.log('pw3.Page.connect 4',pw3.Page.connect);

        if(
            ( typeof retourEleve === 'object' && Object.keys(retourEleve).length === 0 && retourEleve.constructor === Object) &&
            ( typeof retourAutoecole === 'object' && Object.keys(retourAutoecole).length === 0 && retourAutoecole.constructor === Object) &&
            ( typeof retourDroit === 'object' && Object.keys(retourDroit).length === 0 && retourDroit.constructor === Object)
        ){
            console.log('je rentre');
            /*pw3.Page.obAcces = retourDroit;
            pw3.Page.obEleve = retourEleve;
            pw3.Page.obAgence = retourAutoecole;*/

            numEleve = retourEleve.eleve_id;
            numAutoEcole = retourEleve.num_autoecole;
        }

    },
    popUpReCalcule : function(){
        try{
            if((pw3.Page.obEleve.recalcul_stat == 1)||(pw3.Page.debug=="true")) {
                var id0 = "boxRecalcule";
                var body0 = '<div class="left"><img src="images/logo_appli_76X76.png" alt="Packweb3" /></div>' +
                    '<div class="left textWidth"><h3>PACKWEB 3</h3><section class="text">Afin de diminuer le temps de calcul de vos statistiques, ' +
                    'nous vous remercions de bien vous déconnecter de votre session de travail avec le bouton prévu à cet effet.' +
                    ' ( <span class="fontEdiser">J</span> )' +
                    '<br/>Bon apprentissage !</section></div>';
                var picto0 = "J";
                var titre0 = " Titre recalcule";
                var idBox0 = "recalcule";
                var boxConstruct0 = new pw3.box(id0, titre0, picto0, body0, idBox0);
                var html0 = boxConstruct0.getHtml();
                $("#listeBox").html(html0);
                AfficheBoiteMessage("#"+idBox0, true);
            }
        }
        catch(e){catchError(e);}
    },
    popUpMessageEleve : function(){
        try{
            if(localStorage.getItem("displayMessage") != 1){
                var id0 = "boxMessageEleve";
                var body0 = '<div class="left textWidthMessageEleve"><img src="images/message_pw.jpg" /><br/></div>' +
                    '<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>' +
                    '<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>';
                var picto0 = "E";
                var titre0 = " Message d'information";
                var idBox0 = "messageEleve";
                var boxConstruct0 = new pw3.box(id0, titre0, picto0, body0, idBox0);
                var html0 = boxConstruct0.getHtml();
                $("#listeBox").html(html0);
                AfficheBoiteMessage("#"+idBox0, true);
                localStorage.setItem("displayMessage", 1);

            } 
        }
        catch(e){catchError(e)}
    },
    AfficheVignetteCours : function(selecteur){
        try{
            var total = $(selecteur).length;
            var backgroundColor = $("#"+selecteur).find(".lettre").attr("style");
            $(".ligneHaut.logo").text("");
            if(eachCascadeVignette===0){
                eachCascadeVignette = 1;
                $(".accueil nav.menuTheme li").not(this).removeClass("active").addClass("desactive");
                $(".etiquetteModule, .etiquetteStats").animate({"opacity": "0"}, "fast" ).addClass("none");
                $(".elemParent"+selecteur).each(function(i){
                    var el = $("#contentVignette .elemParent"+selecteur);
                    el.removeClass("animated fadeInUp");
                    setTimeout(function(){
                        el.removeClass("none").animate({ "opacity": "1"}, 280).addClass("animated fadeInUp");
                        if (i === total - 1){eachCascadeVignette=0;}
                    }, i * 150);
                });
                $(".accueil .couleurBarreHorizontal .ligneHaut.logo").css("display","block");
                if(total===0){eachCascadeVignette = 0}
                $(".couleurBarreHorizontal, .cours .etiquetteModule .etiquetteTitre").attr("style", backgroundColor);
                $("h2 .titreModulePrincipal").text($("ul li#"+selecteur+" .titre span").text());
                $("ul li#"+selecteur).addClass("active").removeClass("desactive");
                var pictoAvancement = pw3.Stat.inspectPictoAvancement(pw3.Stat.dataStat.stat[cleanID(selecteur)].is_fini, false, "");
                $("#pictoAvancementHeader").text(pictoAvancement.pictoAvancement);
            }
            else{return false;}
        }
        catch(e){catchError(e);}
    },
    AfficheRisque: function(dataAsset,  dataStatSignalisation, titreModule, couleurCours, themeId, puce, picto){
        try{
            var html = "";
            this.themeId = themeId;
            if(!this.dataAsset){this.dataAsset = dataAsset;}

            var paramRisques = {};
            paramRisques.asset_type_id = [ASSET_TYPE_RISQUE, ASSET_TYPE_SANCTION];
            paramRisques.themeId = this.themeId;

            this.tabAsset = findTabAssetRisqueEtSanction(this.themeId);

            html+= '<div id="closeBoxPedago" class="closeBox pedago link right fontEdiser" style="color:'+couleurCours+'">k</div>';

            var menuRisque = new pw3.menuRisque(this.tabAsset, pw3.Page.splitUrl);
            html += menuRisque.getHtml();

            html +='<section id="contenuOngletChiffre" class="contenuOnglet">';

            var pageIntro  = new pw3.PageIntro(this.tabAsset);
            html += pageIntro.getHtml();

            var pageSanction  = new pw3.PageSanction();
            html += pageSanction.getHtml();

            var pageRisque  = new pw3.PageRisqueEtDelit(this.tabAsset, "tabClasseContravention");
            html += pageRisque.getHtml();

            var pageDelit  = new pw3.PageRisqueEtDelit(this.tabAsset, "tabClasseDelit");
            html += pageDelit.getHtml();

            html+='</section>';


            $("body").removeClass();
            $("body").addClass('risques pedagogie');
            $("#contenuAjax").html(html).attr("style", "");
            $("#pictoAvancementHeader").text(picto);
        }
        catch(e){catchError(e);}
    },
    AfficheSignalisation: function(dataAsset,  dataStatSignalisation, titreModule, couleurCours, themeId, puce, picto){
        try{
            var html = "";
            this.themeId = themeId;
            if(!this.dataAsset){this.dataAsset = dataAsset;}

            var tblPanneaux = arraySignalisation(ASSET_TYPE_SIGNALISATION, this.themeId, this.dataAsset);

            var tblFormeDispo = funcFiltreId(tblPanneaux, "signalisation_forme_id");

            var pageSignalisation = new pw3.signalisation(tblFormeDispo, couleurCours);
            html += pageSignalisation.getHtml();

            $("h2 .titreModulePrincipal").text(puce+" / "+titreModule);
            $("#couleurBarreHorizontal").attr("style", "background-color:"+couleurCours);

            var pictoAvancement = pw3.Stat.inspectPictoAvancement(pw3.Stat.dataStat["stat"][cleanID(this["splitUrl"][2])].is_fini, false, "");
            $("#pictoAvancementHeader").text(pictoAvancement.pictoAvancement);

            $("body").removeClass();
            $("body").addClass('panneaux pedagogie');
            $("#contenuAjax").html(html).attr("style", "");
            $("#pictoAvancementHeader").text(picto);
        }
        catch(e){catchError(e);}
    },
    AfficheCouleurSignalisation: function(idForme){
        try{
            this.forme = idForme;

            var tblSignalisation = arraySignalisation(ASSET_TYPE_SIGNALISATION, this.themeId, this.dataAsset);
            var tblCouleurDispo = funcFiltreId(tblSignalisation, "signalisation_couleur_id", this.forme);

            //On désactive tous les boutons de couleur.
            $("#idTblCouleurPanneaux div[id^='couleur_']").addClass("nolink desactiveCouleur").removeClass("link activeCouleur");

            //Je réinitialise la liste des pannaux
            $("#listePanneaux, #detailPanneaux").html("");

            for (var idCouleur in tblCouleurDispo) {
                $("#couleur_"+tblCouleurDispo[idCouleur]).addClass("link activeCouleur").removeClass("nolink desactiveCouleur");
            }

            $(".panneaux #contenuAjax .couleur.ligne").addClass("active");

            $("body").removeClass();
            $("body").addClass('panneaux pedagogie');
        }
        catch(e){catchError(e);}
    },
    AffichePanneauListe : function(idCouleur){
        try{
            var nbColonesPanneau = 5;
            var colonnePanneau = 1;
            var lignePanneau = 1;
            var constructTbl = "";
            var cartouche = "";
            var arrSituation = [];
            var html = '';
            var htmlTblPanneau = '';
            this.couleur = idCouleur.replace("couleur_", "");
            var tblSignalisation = arraySignalisation(ASSET_TYPE_SIGNALISATION, this.themeId, this.dataAsset);
            var tblPanneau = funcFiltreId(tblSignalisation, "", this.forme, this.couleur);

            for(var idPanneau in tblPanneau){
                var assetMeta = tblPanneau[idPanneau]["AssetMeta"];
                if(colonnePanneau==1){htmlTblPanneau+="<tr>";}
                htmlTblPanneau+="<td id=\"ligne"+lignePanneau+"Col"+colonnePanneau+"_"+assetMeta.signalisation_reference+"_"+tblPanneau[idPanneau].id+"_"+cleanID(this["splitUrl"][2])+"\" class=\"link sendServer\">";
                htmlTblPanneau+="    <img title=\""+assetMeta.signalisation_titre+"\" src=\""+RACINE_MEDIA+"signalisation/"+assetMeta.contenu+"\" alt=\""+assetMeta.signalisation_reference+"\" />";
                htmlTblPanneau+="</td>";
                colonnePanneau ++;
                lignePanneau ++;
                if(colonnePanneau==nbColonesPanneau){htmlTblPanneau+="</tr>";colonnePanneau=1;}
            }

            if(colonnePanneau<nbColonesPanneau){
                for(var i=colonnePanneau; i<=nbColonesPanneau; i++){
                    htmlTblPanneau+="<td id=\"ligne"+lignePanneau+"Col"+i+"_none\"></td>";
                }
                htmlTblPanneau+="</tr>";
            }

            html+= '<div id="listePanneaux" class="listePanneaux scroller">' +
                '<table>'+htmlTblPanneau+'</table>'+
                '</div>';

            $("body").removeClass();
            $("body").addClass('panneaux pedagogie');

            $(".generalPanneaux .coteGauche").html(html);
            $(".generalPanneaux .coteDroit .detailPanneaux").html("<div class=\"labelPanneauZoom\"></div>");
            $(".scroller").mCustomScrollbar();
        }
        catch(e){catchError(e);}
    },
    AfficheStructurePage: function (){
        try{
            console.log('AfficheStructurePage');
            isStructureLoaded = true;
            $("#BoxDialogue, div.full.overlayBox, #voileOverlay").remove();
            $("body").append(pw3.structure(pw3.Page.obEleve)).removeClass().addClass("index");
            var raisonSociale = "Votre auto-école ";

            if((!pw3.Page.obEleve.Agence) && (login_password === false)){
                pw3.Page.obEleve = new DataAgence(callData.agence.setAgence(sous_domaine()));
            }

            if((pw3.Page.obEleve.Agence) && (pw3.Page.obEleve.Agence.RaisonSociale) && (pw3.Page.obEleve.Agence.RaisonSociale !=="" )){
                raisonSociale = pw3.Page.obEleve.Agence.RaisonSociale;
            }
            else if((login_password === true) && (pw3.Page.obAgence.raison_sociale) && (pw3.Page.obAgence.raison_sociale !=="" )){
                raisonSociale = pw3.Page.obAgence.raison_sociale;
            }

            $(".titreModulePrincipal").text(raisonSociale+" vous souhaite la bienvenue sur sa plateforme d'éducation routière");
        }
        catch(e){catchError(e);}
    },
    AfficheVignetteFormation: function (dataVignette, dataStat, typeBranche) {
        try{
            var html = '';
            dataVignette.forEach(function(element) {
                var test_id = " testThematiqueExistePas";
                if(pw3.Stat.dataStat.stat[element.id].test_id){test_id = " testId_"+pw3.Stat.dataStat.stat[element.id].test_id;}
                var returnAvancement;
                if((typeBranche)&&(typeBranche==="examens")){returnAvancement = pw3.Stat.inspectPictoAvancement(pw3.Stat.dataStat.stat[element.id].test_thematique, true, "", test_id);}
                else{returnAvancement = pw3.Stat.inspectPictoAvancement2(pw3.Stat.dataStat.stat[element.id].is_fini, pw3.Stat.dataStat.stat[element.id].test_thematique, test_id);}

                var vignette = new pw3.vignette(element.pos_x, element.pos_y, element.color, element.color2, element.titre, element.asset, element.id, element.puceModule, element.idParent, element.puce, returnAvancement.pictoAvancement, test_id, returnAvancement.addClassPicto, returnAvancement.classActiveModule, returnAvancement.activeTestThematique);
                html += vignette.getHtml();
            });
            $("#contentVignette").html(html);
        }
        catch(e){catchError(e);}
    },
    AfficheMenuSequence: function (dataSequence, dataStatSequence, titreModule, couleurCours, picto, puce){
        try{
            var html = "";
            var tempsVideo = 0;
            var pageSequence = new pw3.PageSequence(dataSequence["Meta"].id, dataSequence["tabContenu"], dataSequence["tabObjectLang"][0].titre, titreModule, couleurCours);
            html += pageSequence.getHtml();
            $("body").removeClass();
            $("body").addClass('cours sequence');
            $("#contenuAjax").html(html).attr("style", "");
            for(var idTabContenu in dataSequence["tabContenu"]){
                var tabAsset = dataSequence["tabContenu"][idTabContenu]["tabAsset"];
                for(var idtabAsset in tabAsset){
                    if(tabAsset[idtabAsset]["AssetMeta"].meta){
                        var metaFile = JSON.parse(tabAsset[idtabAsset]["AssetMeta"].meta);
                        if((metaFile)&&(metaFile.mime=="video/mp4")&&(tabAsset[idtabAsset].asset_type_id==3)){
                            tempsVideo = metaFile.time;
                        }
                    }
                }
            }

            //On considère la séquence lue étant donné que l'utilisateur est en train de la lancer la vidéo
            pw3.Stat.dataStat.stat[dataSequence["Meta"].id].ouvert = 1;
            pw3.Stat.initTabStat("sequence", dataSequence["Meta"].id, tempsVideo, pw3.Stat.dataStat.stat[dataSequence["Meta"].id].vu);

            chargementCoursVideo();
            initVideo();
            inspectSon();
            $("body").removeClass();
            $("body").addClass('cours sequence');
            $("h2 .titreModulePrincipal").text(puce+" / "+titreModule);
            $("#couleurBarreHorizontal").attr("style", "background-color:"+couleurCours);

            $("#pictoAvancementHeader").text(picto);
        }
        catch(e){catchError(e);}
    },
    AfficheMenuPartie: function(dataMenuPartie, dataStatPartie, titrePartie, couleurCours, puce, titreModule, picto){
        try{
            var html = "";
            var imgBanner = "";
            var nbVignettes = ObjectLength(dataMenuPartie["tabChild"]);
            var addClassNbVignette = " nbVignettesInferieurEgaleA6";
            var nbVignetteTotal = 6;

            if(nbVignettes>6){
                addClassNbVignette = " nbVignettesInferieurEgaleA10";
                nbVignetteTotal = 12;
            }

            for(var elementContenu in dataMenuPartie["tabContenu"]){
                for(var elementAsset in dataMenuPartie["tabContenu"][elementContenu]["tabAsset"]){
                    if(elementAsset){
                        imgBanner = RACINE_MEDIA+"image/"+encodeURIComponent(dataMenuPartie["tabContenu"][elementContenu]["tabAsset"][elementAsset]["AssetMeta"].contenu);
                    }
                }
            }

            html +='<div class="sequence illustrationLateral left" style="background:url(\''+imgBanner+'\') no-repeat scroll 0 0 / 115% auto transparent;">' +
                '<div class="degrade"></div></div><div class="sequences left"><div class="sequence titre"><h3>'+titrePartie+'</h3></div><div class="clear"></div>';

            //pour pouvoir faire un sort, je met toutes mes données dans un tableau
            var tblDataMenuPartie = [];
            if(dataMenuPartie["tabChild"]){
                for(var element in dataMenuPartie["tabChild"]){
                    tblDataMenuPartie.push(dataMenuPartie["tabChild"][element]);
                }
                tblDataMenuPartie.sort(function(a, b){return a.Meta.ordre-b.Meta.ordre});
            }

            for(var element in tblDataMenuPartie){
                if(element){
                    var fichierImageVignette = "";
                    var dossierImageVignette = "";
                    var partie = tblDataMenuPartie[element];
                    var partieMeta = tblDataMenuPartie[element]["Meta"];
                    var partieLang = tblDataMenuPartie[element]["tabObjectLang"][0];

                    for(var idPartie in partie["tabContenu"]) {
                        if (partie["tabContenu"][idPartie]["FormationContenuType"].id == 2){
                            for (var idAsset in partie["tabContenu"][idPartie]["tabAsset"]) {
                                var Asset = partie["tabContenu"][idPartie]["tabAsset"][idAsset];
                                if ((Asset["AssetType"].id) && (Asset["AssetType"].id == 1)) {
                                    fichierImageVignette = Asset["AssetMeta"].contenu;
                                    dossierImageVignette = Asset["AssetType"].titre.toLowerCase();
                                    break;
                                }
                            }
                        }
                    }

                    var imageSequence = RACINE_MEDIA+"placeholder/"+PLACEHOLDER_VIGNETTE;

                    if(fichierImageVignette!="" && dossierImageVignette!=""){
                        imageSequence = RACINE_MEDIA+dossierImageVignette+"/"+encodeURIComponent(fichierImageVignette);
                    }

                    var vignettePartie = new pw3.vignettePartie(partie.id, partieMeta.order, partieLang.titre, imageSequence, addClassNbVignette, pw3.Stat.dataStat.stat[partie.id].is_fini, couleurCours);
                    html += vignettePartie.getHtml();
                }
            }

            if(nbVignettes < nbVignetteTotal){
                for(var i=0; i<(nbVignetteTotal-nbVignettes); i++){
                    html +='<div class="sequence left nonActif nolink'+addClassNbVignette+'"></div>';
                }
            }
            $("body").removeClass().addClass('cours partie');
            $("#contenuAjax").html(html).attr("style", "");
            $("h2 .titreModulePrincipal").text(puce+" / "+titreModule);
            $("#couleurBarreHorizontal").attr("style", "background-color:"+couleurCours);
            $("#pictoAvancementHeader").text(picto);
        }
        catch(e){catchError(e);}
    },
    AfficheMenuModule: function(dataMenuModule, dataStatModule, picto){
        try{
            var html = constructionVignetteModule(dataMenuModule);

            //Replacement taille et proportion des vignettes quand on redimentionne
            $(window).resize(function(e){
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                refreschModule(dataMenuModule.tabChild)
            });

            $("body").removeClass().addClass('cours module');
            $("#contenuAjax").html(html).attr("style", "");
            $("h2 .titreModulePrincipal").text(dataMenuModule["Meta"].puce+" / "+dataMenuModule["tabObjectLang"][0].titre);
            $("#couleurBarreHorizontal").attr("style", "background-color:"+dataMenuModule["Meta"].color);
            $("#pictoAvancementHeader").text(picto);

            var pourquoiRegleIsFini = inspectPourquoiRegleFini();
            var assetFini = inspectAssetFini();
            var AssetClient = new AssetFunction(callData.formation.callAsset());
            var moduleToEnable = testAsset(dataMenuModule.Meta.theme_id, AssetClient);
            var idModule = dataMenuModule.id;
            var statModule = pw3.Stat.dataStat.stat[idModule];

            if(
                ((pourquoiRegleIsFini === 0) && (statModule.vu >= (statModule.quantite*1)-1)) ||
                ((statModule.vu >= statModule.quantite) && (assetFini.signalisation===0) && (moduleToEnable.is_signalisation===true) && (assetFini.risque===0)) ||
                ((statModule.vu >= statModule.quantite) && (assetFini.risque===0) && (moduleToEnable.is_risque===true) && (assetFini.signalisation===0))
            ){
                AfficheBoiteMessage("#boxInfoFinFormation");
                var boxConstruct = new pw3.box();
                var html = boxConstruct.getAfficheBoxFinFormation();
                $("#listeBox").html(html);
                AfficheBoiteMessage("#boxInfoFinFormation", true);
            }
        }
        catch(e){catchError(e);}
    },
    AfficheMenuFormation: function(dataMenu){
        try{
            var html = '<nav id="menuTheme" class="menuTheme left"><ul>';
            buildElementHtml.elementBase("nav", "#degradeLivre", "menuTheme", "menuTheme left");
            buildElementHtml.elementBase("div", "#degradeLivre", "contentVignette", "contentVignette");
            dataMenu.sort(function(a, b){return a.ordre-b.ordre});

            for(var dataMenuId in dataMenu){
                var picto = {};
                picto.pictoAvancement = "m";

                if(pw3.Stat.dataStat.stat[dataMenu[dataMenuId].id] && pw3.Stat.dataStat.stat[dataMenu[dataMenuId].id].is_fini){
                    picto =  pw3.Stat.inspectPictoAvancement(pw3.Stat.dataStat.stat[dataMenu[dataMenuId].id].is_fini, true, "");
                }
                var menu = new pw3.menu(dataMenu[dataMenuId].id, dataMenu[dataMenuId].titre, dataMenu[dataMenuId].color, dataMenu[dataMenuId].puce, picto.pictoAvancement, dataMenu[dataMenuId].color2);
                html += menu.getHtml();
            }

            html += "</ul></nav><div id='contentVignette' class='contentVignette'></div>";
            $("#degradeLivre").html(html);
        }
        catch(e){catchError(e)}
    },
    AfficheBox: function(idBox){
        try{
            var html = '';
            var id = "";
            idBox.forEach(function(element) {
                var box = new pw3.box(element.id, element.titre, element.picto, element.body);
                html += box.getHtml();
                id = element.id;
            });
            buildElementHtml.elementBase("section", "#listeBox", id, "dialogueBox msg none", "", html);
        }
        catch(e){catchError(e)}
    },
    AffichePageAccueil: function(){
        try{
            console.log('AffichePageAccueil');
            checkPWVersion();

            var html = '';
            var contentPageAccueil = new pw3.PageAccueil();
            html += contentPageAccueil.getHtml();
            $(".full.overlayBox").remove();
            pw3.Page.AfficheStructurePage();
            $("#contenuAjax").html(html);
            updateNbMessage();
        }
        catch(e){catchError(e)}
    },
    AffichePageMobile: function(){
        try{
            var html = '';
            var urlSplit = window.location.host.split(".");
            var sousDomaine =  urlSplit[0];
            if(!sousDomaine || (sousDomaine.indexOf("localhost")!=-1)){sousDomaine = "demo";}
            dataObj = {login: EDITEUR, password: APIKEY, sous_domaine: sousDomaine};
            ObjectAgence= new CallObj("getAutoEcole", PHP_CONNECT, dataObj, "setObjInVar", "autoecole");
            agence =  ObjectAgence.getObj();
            pw3.Page.agence = agence;
            var contentPageObsolette = new pw3.PageChoixMobileView();
            html += contentPageObsolette.getHtml();
            $("body .overlayBox").remove();
            $("#lastScript").after(html);
        }
        catch(e){catchError(e)}
    },
    AffichePageMaintenace: function(description){
        try{
            var html = '';
            var contentPageMaintenace = new pw3.AffichePageMaintenaceView(description);
            html += contentPageMaintenace.getHtml();
            $("#lastScript").after(html);
        }
        catch(e){catchError(e)}
    },
    AffichePageObsolette: function(){
        try{
            var html = '';
            var contentPageObsolette = new pw3.affichePageObsoletteView();
            html += contentPageObsolette.getHtml();
            $("#lastScript").after(html);
        }
        catch(e){catchError(e)}
    },
    AfficheNavigationPrivee : function(){
        try{
            var html = pw3.afficheNavigationPrivee();
            $("#lastScript").after(html);
        }
        catch(e){catchError(e)}
    }, 
    AffichePageLogin: function(){
        try{
            var urlSplit = window.location.host.split(".");
            var sousDomaine =  urlSplit[0];
            if(!sousDomaine || (sousDomaine.indexOf("localhost")!=-1)){sousDomaine = "demo";}
            dataObj = {login: EDITEUR, password: APIKEY, sous_domaine: sousDomaine};
            ObjectAgence= new CallObj("getAutoEcole", PHP_CONNECT, dataObj, "setObjInVar", "autoecole");
            agence =  ObjectAgence.getObj();
            pw3.Page.agence = agence;

            var html = '';
            var contentPageAccueil = new pw3.PageLogin();
            html += contentPageAccueil.getHtml(login_password);
            $(".overlayBox.full, #BoxDialogue, #voileOverlay").remove();
            $("body").append(html).attr("class", "index");;
            personnalise();

        }
        catch(e){catchError(e)}
    },
    AfficheExamensPage : function(dataTest, picto){
        try{
            var html = '';
            var oExamenEnCours = {};
            var mediaType = "imageQuestion";

            oExamenEnCours.oTestEnCours = {};
            oExamenEnCours.oQuestion = '';

            if(typeof dataTest == "object"){
                addContextToLog("Affichage de la question");
                oExamenEnCours.nbQuestion = ObjectLength(dataTest.oTestEnCours.Question);
                oExamenEnCours.oTestEnCours.Compteur = dataTest.oTestEnCours.Compteur;
                questionEnCours = oExamenEnCours.oTestEnCours.Compteur;
                oExamenEnCours.idQuestion = parseInt(oExamenEnCours.oTestEnCours.Compteur) - 1;
                oExamenEnCours.oTestEnCours.oQuestion = dataTest.oTestEnCours.Question[oExamenEnCours.idQuestion];
                oExamenEnCours.oDataTest = dataTest;
                oExamenEnCours.type = "question";
                oExamenEnCours.mp3 = findQuestionMp3(oExamenEnCours.oTestEnCours.oQuestion.media, oExamenEnCours.oTestEnCours.oQuestion.version);
                oExamenEnCours.video = findQuestionVideo(oExamenEnCours.oTestEnCours.oQuestion.media, oExamenEnCours.oTestEnCours.oQuestion.version);
                oExamenEnCours.loop = findQuestionLoop(oExamenEnCours.oTestEnCours.oQuestion.media, oExamenEnCours.oTestEnCours.oQuestion.version);
            }
            else{
                addContextToLog("Affichage de la réponse");
                oExamenEnCours.nbQuestion = ObjectLength(pw3.Examens.oTestEnCours.Question);
                oExamenEnCours.oTestEnCours.Compteur = (dataTest*1)+1;
                oExamenEnCours.idQuestion = dataTest;
                oExamenEnCours.oTestEnCours.oQuestion = pw3.Examens.oTestEnCours.Question[dataTest];
                oExamenEnCours.oDataTest = pw3.Examens;
                oExamenEnCours.type = "reponse";
                oExamenEnCours.mp3 = findReponseMp3(oExamenEnCours.oTestEnCours.oQuestion.media, oExamenEnCours.oTestEnCours.oQuestion.version);
                oExamenEnCours.video = findReponseVideo(oExamenEnCours.oTestEnCours.oQuestion.media, oExamenEnCours.oTestEnCours.oQuestion.version);
                oExamenEnCours.loop = findReponseLoop(oExamenEnCours.oTestEnCours.oQuestion.media, oExamenEnCours.oTestEnCours.oQuestion.version);
            }

            pw3.Examens.oTestEnCours.type = oExamenEnCours.type;
            pw3.Examens.initBarreQuestionExamen();

            var pageExamen = new pw3.PageExamen();

            pageExamen.getHtml(oExamenEnCours).then(function (contentHtml){
                try{
                    html += contentHtml;
                    $("#contenuAjax").html(html).attr("style", "");

                    //corrige le bug d'affichage des fichiers GIF
                    $("#imageQuestion").css("height", "calc(68.8% + 1px)");

                    //masque les flêches de naviguation si on est à la première ou a la dernière question
                    if((oExamenEnCours.type == "revoirQuestion")&&(oExamenEnCours.idQuestion == 0)){$("#questionPrecedente").addClass("masque");}
                    else if((oExamenEnCours.type == "revoirQuestion")&&(oExamenEnCours.idQuestion ==  oExamenEnCours.nbQuestion-1)){$("#questionSuivante").addClass("masque");}
                    else{$("#questionSuivante, #questionPrecedente").removeClass("masque");}

                    inspectSon();

                    if(oExamenEnCours.video) {
                        pw3.Examens.prepareLaBaliseVideo(oExamenEnCours);
                        if(oExamenEnCours.type=="question"){ //Active les litener que dans le cadre d'une question
                            videoQuestionHandler = initEventOnBalise("videoQuestion", oExamenEnCours);
                        }
                    }

                    if (oExamenEnCours.mp3 != "") {
                        addContextToLog("Activation des listeners ");
                        if(oExamenEnCours.type=="question"){ //Active les litener que dans le cadre d'une question
                            audioQuestionHandler = initEventOnBalise("audioQuestion", oExamenEnCours);
                        }
                    }

                    if(oExamenEnCours.video){mediaType = "videoQuestion";}

                    if(oExamenEnCours.video && (oExamenEnCours.type=="revoirQuestion")){mediaType = "videoReponse";}

                    if(picto){$("#pictoAvancementHeader").text(picto);}

                    $("h2#couleurBarreHorizontal .titreModulePrincipal").html('<span class="left nbQuestionEtRang">Question '+oExamenEnCours.oTestEnCours.Compteur+'/'+oExamenEnCours.nbQuestion+'</span><span class="right">Réf. '+oExamenEnCours.oTestEnCours.oQuestion.reference+'</span>');

                    //pour le mode débug (évite d'attendre la fin de la lécture de la question
                    if(pw3.Page.debug=="true"){$("#btnSuivant").removeClass("nolink").addClass("link");}

                    if((oExamenEnCours.loop==true)||((!oExamenEnCours.video)&&(oExamenEnCours.type=="question"))){
                        pw3.Examens.inspectImageComplete(mediaType, oExamenEnCours.mp3, 1, oExamenEnCours.type);
                    }
                    else if(oExamenEnCours.type=="reponse"){
                        pw3.Examens.inspectImageComplete(mediaType, oExamenEnCours.mp3, 1, oExamenEnCours.type);
                    }
                    else if(mediaType == "videoQuestion"){
                        setTimeout( function(){
                            var videoDuration = (($("#contenuAjax .partieQuestion #videoQuestion").get(0).duration) * 1000) + 1000;
                            //Sécurité pour SAFARI
                            if (!isFinite(videoDuration) || isNaN(videoDuration) || videoDuration == 0 || ((videoDuration - 1000) / 1000) == 0) {
                                addContextToLog("BUG NAVIGATEUR - Durée de la vidéo non disponible : Initialisation de la durée à 10 secondes");
                                videoDuration = 3000;
                            }
                            clearTimerSecuriteVideo();
                            addContextToLog("La sécurité vidéo sera déclenchée dans " + ((videoDuration - 1000) / 1000) + " secondes si la lecture du média n'est pas terminée");
                            addTimerSecuriteVideo(videoSecurityNeeded, true, videoDuration, oExamenEnCours.type, oExamenEnCours.mp3);
                        }, 400);
                    }

                    $("body").addClass("examens");
                }
                catch(e){catchError(e);}
            });
        }
        catch(e){catchError(e);}

    },
    AfficheExamensResultat : function(dataTest, TestCorrection){
        try{
            var html = '';
            var pageExamen = new pw3.PageExamenResult(dataTest, TestCorrection);
            html += pageExamen.getHtml();
            $("#contenuAjax").html(html).attr("style", "");
            $("h2#couleurBarreHorizontal .titreModulePrincipal").html('FORMATION THEORIQUE AU PERMIS DE CONDUIRE');
        }
        catch(e){catchError(e)}
    },
    AfficheStatistiquesMesInfos : function(newParam){
        try{
            var html = '';
            var pageStatistiquesMenu = new pw3.PageStatistiquesMenu();
            html += pageStatistiquesMenu.getHtml();
            var pageStatistiquesMesInfos = new pw3.PageStatistiquesMesInfos();
            html += pageStatistiquesMesInfos.getHtml();
            $("#contenuAjax").html(html).attr("style", "");
            $("#"+newParam).addClass("active");
            $(".titreModulePrincipal").text($("#"+newParam+" .label b").text());
        }
        catch(e){catchError(e);}
    },
    AfficheStatistiquesMesOptions : function(newParam){
        try{
            var html = '';
            var pageStatistiquesMenu = new pw3.PageStatistiquesMenu();
            html += pageStatistiquesMenu.getHtml();
            var pageStatistiquesMesOptions = new pw3.PageStatistiquesMesOptions(newParam);
            html += pageStatistiquesMesOptions.getHtml();
            $("#contenuAjax").html(html).attr("style", "");
            $("#"+newParam).addClass("active");
            $("#mesoptions").addClass("active");
            $(".titreModulePrincipal").html("&nbsp;&nbsp;MES OPTIONS");
        }
        catch(e){catchError(e);}
    },
    AfficheStatistiquesMonAAC : function(newParam){
        try{
            var html = '';
            var pageStatistiquesMenu = new pw3.PageStatistiquesMenu();
            html += pageStatistiquesMenu.getHtml();
            var pageStatistiquesMonAAC = new pw3.PageStatistiquesMonAAC();
            html += pageStatistiquesMonAAC.getHtml();
            $("#contenuAjax").html(html).attr("style", "");
            $("#"+newParam).addClass("active");
            $(".titreModulePrincipal").text($("#"+newParam+" .label b").text());
            activeFlipBook();
        }
        catch(e){catchError(e)}
    },
    AfficheStatistiquesMonCode : function(newParam){
        try{
            var html = '';
            var pageStatistiquesMenu = new pw3.PageStatistiquesMenu();
            html += pageStatistiquesMenu.getHtml();

            var pageStatistiquesMonCode = new pw3.PageStatistiquesMonCode();
            html += pageStatistiquesMonCode.getHtml();

            $("#contenuAjax").html(html).attr("style", "");
            $("#"+newParam).addClass("active");
            $(".titreModulePrincipal").addClass("titreLivret");
            $(".titreModulePrincipal").text($("#"+newParam+" .label b").text());
            $(".scroller").mCustomScrollbar();

            var elementGraphique = pageStatistiquesMonCode.getGraph();

            setTimeout( function(){
                camambertCodeDeLaRoute(elementGraphique.codeDeLaRouteObjectif, elementGraphique.couleurVerte, elementGraphique.codeDeLaRouteObjectifResteaFaire, elementGraphique.couleurBleu, elementGraphique.codeDeLaRouteLu, elementGraphique.couleurBleu, elementGraphique.codeDeLaRouteResteAlireNonFait, '#canvasCodeDeLaRoute');
                camambertCodeDeLaRoute(0, elementGraphique.couleurVerte, elementGraphique.examenBlancObjectifResteaFaire, elementGraphique.couleurOrange, elementGraphique.examenBlancLu, elementGraphique.couleurOrange, elementGraphique.examenBlancResteAlireNonFait, '#canvasExamBlanc');
                courbeExamenBlanc('#canvasCourbe', elementGraphique.tblY, elementGraphique.titreCourbeCodeDeLaRoute, elementGraphique.titreCourbeExamenBlanc, elementGraphique.titreCourbeSynthese, elementGraphique.tblCodeDeLaRoute, elementGraphique.tblExamenBlanc, elementGraphique.tblSynthese, elementGraphique.couleurBleu, elementGraphique.couleurOrange, elementGraphique.couleurRouge);
            }, 800);
        }
        catch(e){catchError(e)}
    },
    AfficheStatistiquesMaConduite : function(newParam){
        try{
            var html = '';
            var pageStatistiquesMenu = new pw3.PageStatistiquesMenu();
            html += pageStatistiquesMenu.getHtml();
            var pageStatistiquesMaConduite = new pw3.PageStatistiquesMaConduite();
            var retourView = pageStatistiquesMaConduite.getHtml();
            html += retourView.html;
            $("#contenuAjax").html(html).attr("style", "");
            $("#"+newParam).addClass("active");
            $(".titreModulePrincipal").text($("#"+newParam+" .label b").text());
            $(".scroller").mCustomScrollbar();
            jaugeProgressionConduite(retourView.tblEtapePourcentage);
        }
        catch(e){catchError(e)}
    }
};

var buildElementHtml = {
    elementBase: function(elementType, parentElement, elementId, elementClass, title, content, style){
        try{
            var newElement = document.createElement(elementType);
            if((elementClass)||(elementClass!=="")){newElement.setAttribute("class", elementClass);}
            if((elementId)||(elementId!=="")){newElement.setAttribute("id", elementId);}
            if(title){newElement.setAttribute("title", title);}
            if(style){newElement.setAttribute("style", style);}
            if(content){newElement.innerHTML = content;}
            $(parentElement).append(newElement);
        }
        catch(e){catchError(e);}
    }
};

var callData = {
    service:{
        login_mdp: function(login, mdp, sous_domaine, environnement){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, login_eleve: login, code_eleve: mdp, sous_domaine: sous_domaine, environnement: environnement};
                var ObjectForm= new CallObj("setEleve", "", dataObj, "sessionStorage", "eleve");
                var retour = ObjectForm.getObj();
                return retour;
            }
            catch(e){catchError(e);}
        },
        login: function(mdp, sous_domaine, environnement){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, code_eleve: mdp, sous_domaine: sous_domaine, environnement: environnement};
                var ObjectForm= new CallObj("getEleve", PHP_CONNECT, dataObj, "sessionStorage", "eleve");
                var retour = ObjectForm.getObj();
                if(retour){
                    numEleve = retour.NumEleve;
                    numAutoEcole = retour.Agence.NumAutoEcole;
                }
                return retour;
            }
            catch(e){catchError(e);}
        },
        afficheCours: function(NumFormation){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, id_sequence: NumFormation};
                var ObjectForm= new CallObj("getSequence", PHP_FORMATION, dataObj, "temporaire");
                var retour = JSON.parse(ObjectForm.getObj());

                for(var idTabContenu in retour.tabContenu){
                    var tabAsset = retour.tabContenu[idTabContenu];
                    for(var idTabAsset in tabAsset.tabAsset){
                        if(tabAsset.tabAsset[idTabAsset].AssetType.id===ASSET_TYPE_HTML){
                            var textHtml = tabAsset.tabAsset[idTabAsset].AssetMeta.contenu;
                        }
                    }
                }
                return textHtml;
            }
            catch(e){catchError(e);}
        }
    },
    examen:{
        testEnCours: function(){
            try{
                var ObjectForm= new CallObj("", "", "", "sessionStorage", "TestEnCours");
                return ObjectForm.getObj();
            }
            catch(e){catchError(e);}
        },
        testCorrection:   function(){
            try{
                var ObjectForm= new CallObj("", "", "", "sessionStorage", "TestCorrection");
                return ObjectForm.getObj();
            }
            catch(e){catchError(e);}
        },
        examen:   function(){
            try{
                var ObjectForm= new CallObj("", "", "", "sessionStorage", "examen");
                return ObjectForm.getObj();
            }
            catch(e){catchError(e);}
        }
    },
    formation:{
        checkFormationVarInLs: function(){
            try{
                if(localStorage.getItem("LS_formation1") === null) {
                    return false;
                }
                return true;
            }
            catch(e){catchError(e);}
        },
        checkVersionFormationInLs: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY};
                var ObjectVersion = new CallObj("getVersion", PHP_CONNECT, dataObj, "localStorage", "version");
                var version = ObjectVersion.getObj();
                return version;
            }
            catch(e){catchError(e);}
        },
        updateVersionFormationInLs: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY};
                var ObjectVersion = new CallObj("getVersion", PHP_CONNECT, dataObj, "forceGetLocalStorage", "version");
                var version = ObjectVersion.getObj();
                return version;
            }
            catch(e){catchError(e);}
        },
        checkVersionFormationInServeur: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY};
                var ObjectVersion = new CallObj("getVersion", PHP_CONNECT, dataObj, "setObjInVar", "version");
                var version = ObjectVersion.getObj();
                return version;
            }
            catch(e){catchError(e);}
        },
        forceUpdateFormation: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, id_formation: FORMATION};
                //Charge le localStorage avec les 4 tableaux, formation, asset, couleur et forme
                var ObjectFormation = new CallObj("getFormation", PHP_FORMATION, dataObj, "forceGetLocalStorage", "formation");
                var formation = ObjectFormation.getObj();
                //Retourne la formation du localStorage.
                var ObjectFormation = new CallObj("getFormation", PHP_FORMATION, dataObj, "localStorage", "formation");
                var formation = ObjectFormation.getObj();
                return formation;
            }
            catch(e){catchError(e);}
        },
        callFormation: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, id_formation: FORMATION};
                //Charge le localStorage avec les 4 tableaux, formation, asset, couleur et forme
                var ObjectFormation = new CallObj("getFormation", PHP_FORMATION, dataObj, "localStorage", "formation");
                var formation = ObjectFormation.getObj();
                return formation;
            }
            catch(e){catchError(e);}
        },
        callAsset: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, id_formation: FORMATION};
                var ObjectAsset = new CallObj("getFormation", PHP_FORMATION, dataObj, "localStorage", "asset");
                var retour = ObjectAsset.getObj();
                return retour;
            }
            catch(e){catchError(e);}
        },
        callFormationCouleur: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, id_formation: FORMATION};
                var ObjectFormation = new CallObj("getFormation", "", dataObj, "localStorage", "couleur");
                var retour = ObjectFormation.getObj();
                return retour;
            }
            catch(e){catchError(e);}
        },
        callFormationForme: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, id_formation: FORMATION};
                var ObjectFormation = new CallObj("getFormation", "", dataObj, "localStorage", "forme");
                var retour = ObjectFormation.getObj();
                return retour;
            }
            catch(e){catchError(e);}
        }
    },
    statistiques:{
        callStatistique: function(){
            try{
                var recalcul_stat = false;
                if(pw3.Page.obEleve.recalcul_stat==1){recalcul_stat=true}

                var dataObj = {login: EDITEUR, password: APIKEY, id_eleve: numEleve, init: recalcul_stat};
                //version originale
                    //var ObjectStat = new CallObj("getStats", PHP_STATS, dataObj, "sessionStorage", "formation");
                //version prenant en compte les tests incomplet
                var ObjectStat = new CallObj("getStatsAndIncomplet", PHP_STATS, dataObj, "sessionStorage", "formation");
                var stat = ObjectStat.getObj();
                return stat;
            }
            catch(e){catchError(e);}
        },
        setStatCours: function(){
            try{
                var dataObj = {login: EDITEUR, password: APIKEY, data: oStatSequence};
                var ObjectForm = new CallObj("setStats", PHP_STATS, dataObj);
                return ObjectForm.callElement(true);
            }
            catch(e){catchError(e);}
        },
        setStatCoursSessionStorage: function(dataObj){
            var ObjectStat = new CallObj("getStats", "", dataObj, "updateStatsFormation", "formation");
            return ObjectStat.getObj();
        }
    },
    agence:{
        setAgence: function(sousDomaine){
            try{
                if(!sousDomaine){sousDomaine = "demo";}
                var dataObj = {login: EDITEUR, password: APIKEY, sous_domaine: sousDomaine};
                var ObjectAgence= new CallObj("getAutoEcole", PHP_CONNECT, dataObj, "forceGetSessionStorage", "autoecole");
                var agence =  ObjectAgence.getObj();
                return agence; 
            }
            catch(e){catchError(e);}
        }
    }
};
