/**
 * Created by d.cian on 10/09/2015.
 */

//Test si il y a de nouveaux messages
setInterval('updateNbMessage()', 6000000);

//message d'avertissement sur la page quand on actualise la page ou qu'on essait d'utiliser les boutons de navigation.
//window.onbeforeunload = function(){return;};


/*window.onbeforeunload = function(){
 logOut();
 };*/

$(window).on('beforeunload', function() {
    logOut();
});

$(document).ready(function() {

    completeAgenceInfo();

    completeVersion();

    $(document).keyup(function (e) {
        if (e.which == 122) {
            $("body").toggleClass("fullScreen");
            $("#fullScreen").toggleClass("active");
            var idCours = cleanID(pw3.Page.splitUrl[1]);
            var idModule = cleanID(pw3.Page.splitUrl[2]);
            refreschModule(pw3.Page.dataFormation.formation.tabChild[idCours].tabChild[idModule].tabChild);
        }
    }).on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function () {
        $("body").toggleClass("fullScreen");
        $("#fullScreen").toggleClass("active");
        // que si c'est un module de la partie cours
        if ((pw3.Page.splitUrl[2]) && (pw3.Page.splitUrl[1] == "cours") && (pw3.Page.splitUrl[2].charAt(0) == "m")) {
            var idCours = cleanID(pw3.Page.splitUrl[1]);
            var idModule = cleanID(pw3.Page.splitUrl[2]);
            refreschModule(pw3.Page.dataFormation.formation.tabChild[idCours].tabChild[idModule].tabChild);
        }
    }).on("click", ".btnSignalBug", function (e) {
        e.preventDefault();
        e.stopPropagation();

        addContextToLog("XXX CLICK SUR LE BOUTON DEBUG");
        pw3.Examens.extentionImageExamen = "";
        if(pw3.Examens.oTestEnCours && pw3.Examens.oTestEnCours.Question){
            var listMedia = pw3.Examens.oTestEnCours.Question[questionEnCours-1].media;
            for(var idMedia in listMedia){
                if(listMedia[idMedia].type=="Photo"){
                    var fichierImage = listMedia[idMedia].contenu;
                    pw3.Examens.extentionImageExamen = fichierImage.split(".");
                    pw3.Examens.extentionImageExamen = pw3.Examens.extentionImageExamen[1];
                    break;
                }
            }
        }

        pw3.Examens.questionEnErreur = (
            pw3.Examens.oTestEnCours && pw3.Examens.oTestEnCours.Question
                ? " [" + questionEnCours + "/" + pw3.Examens.oTestEnCours.Question.length + "] - version : " + appVersion + " - Nb tests : " + nbTests
                : ""
        );

        var id0 = "boxSignalBug";
        var body0 = '<select id="selectIdBug"><option value="" disabled selected>Veuillez choisir...</option><option>Question qui saute</option><option>Pas de son</option><option>Pas d’image</option><option>Autre (à indiquer ci-dessous)</option></select>' +
            '<div id="avertissementBoxBug" ></div>' +
            '<br/><br/><textarea></textarea>' +
            '<br/>' +
            '<div id="warni"><font color="red"><small>Ce formulaire est un outil d\'aide à l\'analyse et au diagnostic du fonctionnement de votre plateforme e-learning.' +
            '<br/>Pour toute question portant sur la pédagogie, veuillez vous rapprocher de votre auto école.' +
            '<br/>Nous vous remercions de votre participation.</small></font></div>' +
            '<br/>' +
            '<div class="envoiForm"><input id="boxSignalBug_btnValide" class="inputSubmit" type="submit" name="envoyer" value="Envoyer"> <input id="boxSignalBug_btnAnnule" class="inputAnnule" type="button" name="annule" value="Annuler"></div>';
        var picto0 = "E";
        var titre0 = " Quel problème avez-vous rencontré ?";
        var idBox0 = "signalBug";
        var boxConstruct0 = new pw3.box(id0, titre0, picto0, body0, idBox0);
        var html0 = boxConstruct0.getHtml();
        $("#listeBox").html(html0);
        AfficheBoiteMessage("#" + idBox0, true);
    }).on("change", "#selectIdBug", function(){
        //Si on choisi bug image avec firefox pour bug gif
        var browser=navigator.userAgent.toLowerCase();
        if(($("#selectIdBug" ).prop('selectedIndex')==3)&&(browser.indexOf('firefox') > -1)&&(pw3.Examens.extentionImageExamen=="gif")){
            $("#avertissementBoxBug").html("Nous avons détecté que vous utilisez Firefox. Ce navigateur comporte un bug connu et en cours de correction pour afficher certaines images de type \"gif animé\". Si vous rencontrez souvent ce genre de problème, nous vous conseillons d'utiliser Chrome.");
            $("#avertissementBoxBug").css({"background-color": "#E3001B", "color":"#ffffff" , "margin-top":"10px" , "padding":"10px"});
        }
    }).on("click", "#affTelMobile", function (e) {
        e.preventDefault();
        e.stopPropagation();
        pw3.Page.mobileScreen = "true";
        $(location).attr('href', 'https://fastcode.ediser.com/home/');
    }).on("click", "#affTablette", function (e) {
        e.preventDefault();
        e.stopPropagation();
        pw3.Page.mobileScreen = "false";
        pw3.Page.AffichePage();
    }).on("click", "#menuExamen li.rep", function (e) {
        e.preventDefault();
        e.stopPropagation();

        $(this).toggleClass('active');

        var reponse = "";
        $(".examens #menuExamen li.rep.active").each(function (){
            reponse += $(this).text();
        });
        addContextToLog("Réponses cochées : " + reponse);

    }).on("click", ".examens #btnSuivant.link", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).removeClass("link").addClass("nolink");
        addContextToLog("Clic sur le bouton suivant");
        pw3.Examens.goToNextQuestion();
    }).on('click', '#ongletZonetextReponse.close', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("#ongletZonetextReponse").removeClass("close").addClass("open");
        $("#contentZoneText").css("display", "none");
        $("#zoneTextReponse").animate({"right": "-34%"}, "slow");
        $("#contenerZoneText").animate({"width": "0%"}, "slow");
    }).on('click', '#ongletZonetextReponse.open', function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("#ongletZonetextReponse").removeClass("open").addClass("close");
        $("#zoneTextReponse").animate({"right": "0%"}, "slow");
        $("#contenerZoneText").animate({"width": "100%"}, "slow", function () {
            $("#contentZoneText").css("display", "block");
        });
    }).on("dblclick", "*", function (e) {
        e.preventDefault();
        e.stopPropagation();
    }).on("tap", "*", function (e) {
        e.stopPropagation();
        $(e.target).trigger('click');
    }).on("click", "nav.menuTheme li", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var splitUrl = attrUrl();
        var idCours = $(this).closest(".cours").attr("id");
        pw3.Page.AffichePage(splitUrl[0], idCours);
    }).on("click", "#btnSubmitLoginMdp", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();

        if($("#login").val()!=""){
            NProgress.set(0.2);
            apiCall("GET", "v2/students/connexion/product/new/?login=" + $("#login").val() + "&password=" + $("#mot_pass").val() + "&produit_id=169")
                .then(function(connexion){
                    if(connexion.code == 0){
                        pw3.login = $("#login").val();
                        pw3.Droit.Login($("#mot_pass").val(), true);
                        //on teste qu'il y a au moins 1 permis sinon on met par defaut le B
                        if(!connexion.message.student.cerfa.hasOwnProperty("permis_demande") || connexion.message.student.cerfa.permis_demande == null || connexion.message.student.cerfa.permis_demande == "" ) {
                            connexion.message.student.cerfa.permis_demande ="B";
                        }
                        var ObjectForm = new CallObj("", "", connexion.message.student.cerfa.permis_demande, "sessionStorage", "", "permis_demande");
                        pw3.PermisDemande = ObjectForm.getObj();
                    }
                    else{
                        var id0 = "erreurPasseBox";
                        var body0 = '<div class="contentAscenseur scrollerBox"><div class="label center">Il y a une erreur dans la saisie de votre login ou mot de passe. Merci de recommencer.<br/><br/><br/></div><div class="submit center"><input type="submit" id="recommencerMdp" value="Recommencer" class="submit link closeBox" /></div></div>';
                        var picto0 = "J";
                        var titre0 = " Mot de passe ou login erron&eacute;";
                        var idBox0 = "erreurPasse";
                        var boxConstruct0 = new pw3.box(id0, titre0, picto0, body0, idBox0);
                        var html0 = boxConstruct0.getHtml();
                        $("#listeBox").html(html0);
                        AfficheBoiteMessage("#"+idBox0, true);
                        sessionStorage.clear();
                    }
                    NProgress.set(1.0);
                });


        }
    }).on("click", "#btnSubmitLogin", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();

        if($("#login").val()!=""){
            NProgress.set(0.2);
            pw3.Droit.Login($("#login").val(), true);
            NProgress.set(1.0);
        }
    }).on('click', '#ongletMonCode #onglet_tests:not(.load)', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).addClass("load");
        $("#page_tests .ajaxLoader").removeClass("none");
        var pageStatistiquesMonCodeOngletTest = new pw3.PageStatistiquesMonCodeOngletTest();
        var html = pageStatistiquesMonCodeOngletTest.getHtml();
        $("#contenuOnglet").append(html);
        $(".scroller").mCustomScrollbar();
    }).on("click", "#closeSequence, #closeBoxPedago", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        if ($(this).attr("id") == "closeSequence") {
            pw3.Stat.sendServer();
        }
        var idPartie = attrUrl();
        var pourquoiRegle = findPourquoiRegle();
        if (pourquoiRegle.idSequence === cleanID(idPartie[4]) || ($(this).attr("id") == "closeBoxPedago")) {
            pw3.Page.AffichePage("cours", idPartie[2]);
        }
        else {
            pw3.Page.AffichePage("cours", idPartie[3]);
        }
    }).on("click", "#idTblFormePanneaux .onglet.activeForme", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        $(".panneaux .formePanneaux td").removeClass("active");
        $(".panneaux .couleur td").removeClass("active");
        $(this).closest("td").addClass("active");
        var idForme = $(this).attr("id").replace("forme_", "");
        pw3.Page.AfficheCouleurSignalisation(idForme);
    }).on("click", "#idTblCouleurPanneaux .onglet.activeCouleur", function (e) {
        e.stopPropagation();
        reinitListePanneauEtDetail();
        $(".panneaux .couleur td").removeClass("active");
        $(this).closest("td").addClass("active");
        var idCouleur = $(this).attr("id");
        pw3.Page.AffichePanneauListe(idCouleur);
    }).on("click", ".examens .etiquetteModule, body.cours .etiquetteEvaluation.link ", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        sessionStorage.removeItem("SS_TestEnCours1");
        if (pw3.Examens.oTestEnCours) {
            pw3.Examens.oTestEnCours.Compteur = 0;
        }
        elementThis = $(this).closest(".etiquetteModule");
        var idModule = $(this).closest(".etiquetteModule").attr("id");
        if ($("body").hasClass("cours")) {
            pw3.Examens.origineTypeExamen = "cours";
            pw3.Examens.origineUrlExamen = lienPagePrecedente();
        }
        else if ($("body").hasClass("examens")) {
            pw3.Examens.origineTypeExamen = "examens";
            pw3.Examens.origineUrlExamen = lienPagePrecedente(1);
        }
        initNewExam();
        NProgress.set(0.2);
        pw3.Page.AffichePage("examens", idModule);
        NProgress.set(1.0);
    }).on("click", "#listePanneaux td", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        if ($(this).hasClass("sendServer")) {
            var SplitIdLigneActive = $(this).closest("td").addClass("active").attr("id").split("_");
            var objPanneaux = {};
            objPanneaux.id_eleve = numEleve;
            objPanneaux.id_autoecole = numAutoEcole;
            objPanneaux.id_formation = SplitIdLigneActive[3];
            objPanneaux.id_asset = SplitIdLigneActive[2];
            objPanneaux.is_fini = 1;
            objPanneaux.type = "signalisation";
            pw3.Stat.sendServer(objPanneaux);
        }
        $("#listePanneaux td").removeClass("sendServer");
        $(".panneaux .listePanneaux td").removeClass("active");
        if ($(this).hasClass('link')) {
            $(this).addClass("active");
        }
        $(".panneaux .detailPanneaux .cartouche td").removeClass("active");
        $(".panneaux .detailPanneaux .cartouche td.zoomPanneaux").addClass("active");
        var urlImage = $(this).find("img").attr("src");
        var titreImage = $(this).find("img").attr("title");
        $(".panneaux .detailPanneaux").css("background-image", "url(" + urlImage + ")");
        $(".panneaux .detailPanneaux .labelPanneauZoom").text(titreImage != "undefined" ? titreImage : "");
        $(".panneaux .detailPanneaux .cartouche").addClass("none");
        $(".panneaux footer nav .menuFourtous .panneaux").removeClass("grayscale");
    }).on("click", ".btnOnglet:not(.sendServer)", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var Idelement = $(this).attr("id");
        var element = Idelement.replace("BtnOnglet", "");
        var prefixid = $(this).closest("nav").attr("id");
        $(this).parent().find("li").addClass("desactive").removeClass("active");
        $(this).removeClass("desactive").addClass("active");
        $("#contenuOnglet" + prefixid + " > div").addClass("none");
        $("#" + element + "ContentOnglet").removeClass("none");
        $(".scroller").mCustomScrollbar();
    }).on("click", "body.cours .etiquetteMap.link", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idModule = $(this).closest(".etiquetteModule").attr("id");
        var splitUrl = attrUrl();
        pw3.Page.AffichePage(splitUrl[0], idModule);
    }).on("click", "body.module .carreModule.link", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idPartie = $(this).closest(".partie").attr("id");
        pw3.Page.AffichePage("cours", idPartie);
    }).on("click", "body.partie .sequence.link", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idSequence = $(this).closest(".sequence").attr("id");
        pw3.Page.AffichePage("cours", idSequence);
    }).on("click", "#logoPause, #menuVideo #btnPlay.playPause .link, #menuVideo .cours .active", function (e) {
        e.stopPropagation();
        masqueCours();
        videoPlay();
    }).on("click", ".risques #menuOnglet nav ul li.btnOnglet.desactive", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var listeClass = $(this).attr("class");
        var explode1 = listeClass.split("numModule");
        var numModule = explode1[1].split(" ");
        var Idelement = $(this).attr("id");
        var element = Idelement.replace("BtnOnglet", "");
        var prefixid = $(this).closest("nav").attr("id");
        var SplitIdLigneActive = attrUrl();
        if ($(this).hasClass("sendServer")) {
            var objRisque = {};
            objRisque.id_eleve = numEleve;
            objRisque.id_autoecole = numAutoEcole;
            objRisque.id_formation = cleanID(SplitIdLigneActive[2]);
            objRisque.is_fini = 1;
            objRisque.type = "risque";
            pw3.Stat.sendServer(objRisque);
            $(".risques nav#Chiffre .btnOnglet").removeClass("sendServer");
        }
        $("#Chiffre .btnOnglet").addClass("desactive").removeClass("active");
        $("#" + Idelement).removeClass("desactive").addClass("active");
        $("#contenuOnglet" + prefixid + " > div").addClass("none");
        $("#" + element + "ContentOnglet").removeClass("none");
        $(".scroller").mCustomScrollbar();
        $(".risques footer nav .menuFourtous .risque").removeClass("grayscale");
    }).on("click", "#logOut", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        logOut();
        $(this).addClass("click");
        $(this).text("j");
        sessionStorage.clear();
        numAutoEcole = "";
        numEleve = "";
        pw3.Page.connect = null;
        pw3.Page.obEleve = null;
        pw3.Droit.obDroit = null;
        pw3.Stat.dataStat = null;
        pw3.Examens.obExamen = null;
        pw3.Page.obQuiz = null;
        pw3.Page.obMobile = null;
        pw3.Page.obEtg = null;
        //pw3.Page.versionFormationLs         = null;
        pw3.Page.versionFormationServeur = null;
        $("header, section, footer, div").remove();
        pw3.Page.AffichePage();
        completeVersion();
    }).on("click", "#cours.link, #examens.link, #statistiques.link", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var dossier = $(this).attr("id");
        if (dossier == "statistiques") {
            NProgress.set(0.2);
            var oEleve = pw3.Page.obEleve;
            if(pw3.PermisDemande == null || !pw3.hasOwnProperty('PermisDemande') ){
                var ObjectForm = new CallObj("", "", "", "sessionStorage", "", "permis_demande");
                pw3.PermisDemande = ObjectForm.getObj();
            }
            var PermisDemande = pw3.PermisDemande;
            var findPermisB = -1;
            if(PermisDemande != null && PermisDemande != "") {
                findPermisB = PermisDemande.toLowerCase().indexOf('b');
            }

            var etatCours = "nolink";

            var oDroit = pw3.Droit.obDroit;
            if((((oDroit.Cours*1) == 1)||((oDroit.Tests*1) == 1)||((oDroit.Boitier*1) == 1))){etatCours = "link";}

            if(findPermisB >= 0){
                if(etatCours == "link") {
                    pw3.Page.AffichePage(dossier, "moncode");
                } else {
                    pw3.Page.AffichePage(dossier, "mesoptions");
                }

            } else {
                pw3.Page.AffichePage(dossier, "mesoptions");
            }
            NProgress.set(1.0);
        }
        else if (dossier != "examens") {
            pw3.Page.AffichePage(dossier, dossier);
        }
        else {
            $(this).find(".btnMulti").removeClass("none");
            $(this).find(".btnCodeRoute").addClass("none");
        }
    }).on("click", "#thematique.link", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var dossier = $(this).closest(".index .btnType.link").attr("id");
        pw3.Page.AffichePage(dossier);
    }).on("mouseenter", "#cours,#examens,#statistiques", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        $(this).stop(true, true).addClass("hover", 1000);
    }).on("mouseleave", ".btnType", function () {
        $(this).stop(true, true).removeClass("hover", 100);
    }).on("click", ".sequence.cours #menuVideo .cours div:not(.active) ", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        $("#btnPlay .link.fontEdiser").removeClass("active");
        videoPause();
        afficheCours();
    }).on("click", "#btnPlay.pause .link", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        videoPause();
        $(".sequence .pauseLayer").addClass("pause");
    }).on("click", "#menuFourtous li.panneaux.affiche:not(.masque) .link", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        pw3.Page.AffichePage("cours", "signalisation");
    }).on("click", "#menuFourtous li.risque .link", function (e) {
        e.stopPropagation();
        pw3.Page.AffichePage("cours", "risque");
    }).on("click", "#btnRetour, #acceuilFullscreen", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        //en masque le cours et on envoie le resultat des stats si on sort d'un séquence
        if ($("body").hasClass("sequence")) {
            masqueCours();
            pw3.Stat.sendServer();
        }
        var idPartie = attrUrl();
        var pourquoiRegle = findPourquoiRegle();
        if (pw3.Page.splitUrl[0] == "cours" && pw3.Page.splitUrl.length == 1) {
            pw3.Page.AffichePage("");
            //Pour la naviguation de la partie cours on laisse tomber le histroy -1
        } else if (pourquoiRegle.idSequence === cleanID(idPartie[4]) || ($(this).attr("id") == "closeBoxPedago")) {
            pw3.Page.AffichePage("cours", idPartie[2]);
        }
        else if (pw3.Page.splitUrl[0] == "cours") {
            if (pw3.Page.splitUrl.length == 1) {
                pw3.Page.AffichePage("");
                return;
            }
            var racine = pw3.Page.splitUrl[0];
            var page = pw3.Page.splitUrl[pw3.Page.splitUrl.length - 2];
            //Si on est sur la page d'accueil du cours.
            if (page == racine) {
                page = ""
            }
            //Si il n'y a aucune page on retourne sur la page d'accueil
            if (!pw3.Page.splitUrl[1]) {
                racine = ""
            }
            pw3.Page.AffichePage(racine, page);
        } else {
            pushHistorySs = false;
            var returnNavHistory = readHistoryPrecedent(1);
            pw3.Page.AffichePage(returnNavHistory.tree, returnNavHistory.objHistoryNav);
        }
        pw3.Page.splitUrl = attrUrl();
    }).on("click", "#btnRetourAcceil, #logoPrincipal", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        //remet la balise video a la racine du body
        reinitQuestionVideo();
        //en masque le cours et on envoie le resultat des stats si on sort d'un séquence
        if ($("body").hasClass("sequence")) {
            masqueCours();
            pw3.Stat.sendServer();
        }
        pw3.Page.AffichePage("");
    }).on('click', '#revoirReponse tr td', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        //$("#btnPlayAudio").trigger( "click" );
        var question = $(this).attr("id");
        question = question.split("question");
        questionEnCours = (question[1]*1)+1;
        pw3.Page.AffichePage("examens", "revoir" + question[1]);
    }).on('click', '#questionPrecedente', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        reinitQuestionVideo();
        reponsePrecedente();
    }).on('click', '#questionSuivante', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        reinitQuestionVideo();
        reponseSuivante();
    }).on('click', '#resultTest', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        reinitQuestionVideo();
        pw3.Page.AffichePage("examens", "resultat");
    }).on('click', '#audio .link, #audioRelire .link', function (e) {
        e.stopPropagation();
        switchSon();
    }).on('click', '#examBlanc, #nouveauTest', function (e) {
        e.stopPropagation();
        clearLogContext();
        initNewExam();
        sessionStorage.removeItem("SS_TestEnCours1");
        if (pw3.Droit.obDroit.Tests > 0) {
            NProgress.set(0.2);
            pw3.Page.AffichePage("examens", "examens");
            NProgress.set(1.0);
        }
    }).on('click', '#menuStatOnglet .onglet.link', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idOnglet = $(this).attr("id");
        if (idOnglet == "maconduite") {NProgress.set(0.2);}
        
        pw3.Page.AffichePage("statistiques", "statistiques-" + idOnglet);
        
        if (idOnglet == "maconduite") {
            NProgress.set(1.0);
        }
    }).on('click', '#mes_options td:not(.desactive)', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();

        //pw3.Page.AffichePage("statistiques", "statistiques-" + $(this).attr("id"));

        if($(this).attr("id") == "classeVirtuelle") {
            NProgress.set(0.2);
            apiCall("GET", "v2/classe_virtuelle/client/"+pw3.Page.obEleve.Agence.NumAutoEcole)
                .then(function(connexion){
                    var listeClasse = [];
                    if(connexion.code == 0){
                        listeClasse = connexion.message;
                    }else{
                        console.log("erreur get classe virtuelle");
                    }
                    pw3.ListeClasseVirtuelle = listeClasse;
                    pw3.Page.AffichePage("statistiques", "statistiques-classeVirtuelle");
                    NProgress.set(1.0);
                });
        } else {
            pw3.Page.AffichePage("statistiques", "statistiques-" + $(this).attr("id"));
        }

    }).on('click', '#tvPneu td.link', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        var lienVideo = $(this).attr("title");
        var id = $(this).attr("id");
        var body = '<video id="sequenceTVPneu" width="688" controls="true"><source src="' + RACINE_MEDIA + '/' + lienVideo + '"></source></video>';
        var picto = "H";
        var titre = "Video";
        var idBox = "boxVideo";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getHtml();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#" + idBox, true);
        var elmVideo = $("#sequenceTVPneu");
        if (elmVideo.length) {
            elmVideo.get(0).load();
        }
        elmVideo.get(0).play();
    }).on('click', '#listeBox .closeBox', function (e) {
        e.stopPropagation();
        var idBox = $(this).closest("section").attr("id");
        if ($("#audioQuestionRevoir") && $("#audioQuestionRevoir").get(0)){$("#audioQuestionRevoir").get(0).pause();}
        closeBoiteMessage(idBox);
    }).on('click', '#boxSignalBug_btnValide', function(e){
        e.preventDefault();
        e.stopPropagation();
        var objContexte = {};
        objContexte.objet = addslashes($("#signalBug select").val());
        objContexte.detail = addslashes($("#signalBug textarea").val());

        objContexte.detail += "\r\n\r\n-----NUMQUESTION : \r\n" + pw3.Examens.questionEnErreur + "\r\n\r\n";
        var tabExamLogJoin = addslashes(tabExamLog.join("\r\n"));
        objContexte.detail += "\r\n\r\n-----CONTEXTE : \r\n" + tabExamLogJoin + "\r\n\r\n";

        var plugins = "";
        try{
            var p = navigator.plugins.length;
            for (i=0 ; i<p ; i++){
                plugins += navigator.plugins[i].name + "\r\n";
            }
        }catch(e){catchError(e);}

        objContexte.detail += "\r\n\r\n-----PLUGINS : \r\n" + plugins + "\r\n";
        objContexte.detail += "\r\n\r\n-----AUTOECOLE : \r\n" + (pw3.Page && pw3.Page.agence ? pw3.Page.agence.NumAutoEcole : "" ) + "\r\n";
        objContexte.detail += "\r\n\r\n-----ELEVE : \r\n" + (pw3.Page && pw3.Page.obEleve ? pw3.Page.obEleve.Nom + (pw3.Page.obEleve.NomNaissance ? " " +  pw3.Page.obEleve.NomNaissance : "" ) + " " + pw3.Page.obEleve.Prenom + " ( " + pw3.Page.obEleve.Email + " )" : "" ) + "\r\n\r\n";

        objContexte.NumStatTest = pw3.Examens.NumStatTest;

        sendLog("eleve", objContexte, "" );
        var idBox = $(this).closest("section").attr("id");
        closeBoiteMessage(idBox);

        // Préparation du mail
        var codeEleve = (pw3.Page.obEleve ? pw3.Page.obEleve.CodeEleve : "#n/a");
        var history = [];
        var tmpHistory = JSON.parse(sessionStorage.getItem("SS_historyNavPrecedent"));
        if(tmpHistory){
            history.push(tmpHistory[0]);
            if(tmpHistory.length > 1){
                history.push(tmpHistory[1]);
            }
        }

        //Mail si Timeout
        if(tabExamLogJoin.indexOf("TimeOut")>=0){
            var dataMailTimeOut = {"to":pw3.Page.obEleve.Email,"subject":"Concernant votre signalement d'erreur sur le PackWeb","text":"Bonjour,\r\n\r\nNous vous remercions pour votre signalement d’erreur.\r\nToutefois, nous avons remarqué que votre connexion internet présentait des lenteurs qui ont provoqué des délais anormalement longs pour le chargement des images et des sons pendant ce test.\r\nNous vous invitons donc à vérifier votre débit internet et à vous assurer que personne ne télécharge ou ne joue en ligne en même temps que vous effectuez vos tests.\r\n\r\nCordialement,\r\n\r\nEDISER."};
            apiCall("POST", "managements/email", dataMailTimeOut);
        }

        // Envoi du mail
        var dataApi = {"to":"p.fernandez@ediser.com;f.bernier@ediser.com;d.cian@ediser.com","subject":"Signalement d'erreur par un élève","text":objContexte.objet+" \r\n\r\n-----DETAIL : \r\n"+objContexte.detail+" -----USERAGENT : \r\n"+navigator.userAgent+" \r\n\r\n-----CODEELEVE : \r\n"+ codeEleve};
        apiCall("POST", "managements/email", dataApi)
           .then(function(){
                // PopUp de confirmation
                var id = "BugEnvoieBox";
                var body = 'Merci pour votre aide, nous prenons en compte votre observation afin d\'améliorer rapidement votre expérience de navigation.';
                var picto = "E";
                var titre = "Quel problème avez-vous rencontré ?";
                var idBox = "BugEnvoie";
                var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
                var html = boxConstruct.getHtml();
                $("#listeBox").html(html);
                AfficheBoiteMessage("#" + idBox, true);
           });
        clearLogContext();

    }).on('click', '#boxSignalBug_btnAnnule', function(e){
        e.preventDefault();
        e.stopPropagation();
        var idBox = $(this).closest("section").attr("id");
        closeBoiteMessage(idBox);
    }).on('click', 'a.btnMoto', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        var id = $(this).attr("id");
        var body = '';
        var picto = "y";
        var titre = $(this).text();
        var idBox = "boxCoursMoto";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getBoxMoto(id, titre, picto, body, idBox);
        $("#listeBox").html(html);
        AfficheBoiteMessage("#"+idBox, true);
        $(".scroller").mCustomScrollbar();
    }).on('click', '.bodyBox .tabs a', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        $('.tabs li').removeClass('selected');
        $('.flip-book').hide();
        $(this).parent().addClass('selected');
        $($(this).attr('href')).show();
        var activeScene = $(this).attr('class');

        if (activeScene=='scene1') {
            $('.scene1Ilustration').show();
            $('.scene2Ilustration').hide();
        } else {
            $('.scene2Ilustration').show();
            $('.scene1Ilustration').hide();
        }
    }).on("click", "#navpageBookNext", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        $("#book").turn("next");
    }).on("click", "#navpageBookPreviou", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        $("#book").turn("previous");
    }).on('click', '#ongletMonCode .onglet:not(.active)', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        var onglet = $(this).attr("id");
        var pageOnglet = onglet.replace("onglet_", "");
        $("#ongletMonCode").removeClass();
        $("#ongletMonCode").addClass(pageOnglet);
        $("#ongletMonCode .onglet").removeClass("active");
        $("#"+onglet).addClass("active");
        $("#contenuOnglet .pageOnglet:not(.none)").addClass("none");
        $("#contenuOnglet #page_"+pageOnglet).removeClass("none");
    }).on('click', '#page_tests .ligneResultat .extand.inactive', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idLigne = $(this).attr("id");
        var idLigneResult = idLigne.replace("ligneNum", "ligneResultNum");
        $(this).find("span").text("-");
        $(this).removeClass("inactive").addClass("active");
        $(this).closest(".ligneResultat").addClass("active");
        $("#"+idLigneResult).removeClass("none");
    }).on('click', '#page_tests .ligneResultat .extand.active', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idLigne = $(this).attr("id");
        var idLigneResult = idLigne.replace("ligneNum", "ligneResultNum");
        $(this).find("span").text("+");
        $(this).removeClass("active").addClass("inactive");
        $(this).closest(".ligneResultat").removeClass("active");
        $("#"+idLigneResult).addClass("none");
    }).on('click', '#page_tests div.puceQuestionResultat.link', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var id = $(this).attr("id");
        var body = '';
        var picto = "q";
        var titre = "Revoir la question...";
        var idBox = "boxRevoirQuestion";
        var elt = $(this).closest(".ligneResultatQuestions");
        var idSelector = elt.attr("id").replace("ligneResultNum", "");
        var typeExamen = $("#LigneResultat" + idSelector + " .serieType").attr('class').split(" ")[1];
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        
        boxConstruct.getBoxRevoirReponse(typeExamen).then(function(html){
                $("#listeBox").html(html);
                AfficheBoiteMessage("#"+idBox, true);
                $("#audioQuestionRevoir").get(0).load();
                $("#audioQuestionRevoir").get(0).play();
                $("#revoirQuestionStat").get(0).load();
                $("#revoirQuestionStat").get(0).play();
            }
        );
    }).on("click", "#numEtapeOngletEtape .ongletEtape", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.stopImmediatePropagation();
        var element = $(this).attr("id");
        onglet(element, "etape", "contenuEtape", "ongletEtape");
    }).on("click", "#historique .ligne .ES, #tblMaConduite .btnGlobal", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idDiv = $(this).attr("id");
        if (idDiv!="numNomapadnumEvaluation" && idDiv != "numNomapadundefinednumEvaluationundefined"){
            afficheIfram($(this).attr("id"), "nomapad_synthese_PW3.php");
        }
    }).on("click", "#listeBtnExamFinal .boutonExamFinal", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idDiv = $(this).attr("id");
        if (idDiv!="numNomapadnumEvaluation" && idDiv != "numNomapadundefinednumEvaluationundefined"){
            afficheIfram($(this).attr("id"), "nomapad_examen_PW3.php");
        }
    }).on('click', '#fullScreen, #exitFullScreen', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.stopImmediatePropagation();
        funcfullScreen();
    }).on("click", ".historique .ligne .btnDessin", function(e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();

        var id = $(this).attr("id");
        var valObs = id.replace("btnDes", "");
        var dataDessinNomapad = new DataAssetConduite(valObs);
        var bodyBoxDessin = new pw3.afficheBodyBoxDessin(dataDessinNomapad.affiche());
        var body = bodyBoxDessin.getHtml();
        var picto = "I";
        var titre = "Dessins";
        var idBox = "boxDessinNomapad";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getHtml();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#" + idBox, true);
        $("#cartouchNumDessin1").addClass('active');

    }).on("click", "#BoxDialogue #boxDessinNomapad #cartouche div.link", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idImageActive = $(this).attr('id').replace("cartouchNumDessin", "");
        $("#BoxDialogue #boxDessinNomapad div.images").addClass("none");
        $("#BoxDialogue #boxDessinNomapad #cartouche div").removeClass('active');
        $(this).addClass('active');
        $("#BoxDialogue #boxDessinNomapad .bodyBox #numDessin"+idImageActive).removeClass("none");
    }).on("click", ".historique .ligne .btnObservation", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();

        var id = $(this).attr("id");
        var valObs = id.replace("btnObs", "");
        var dataObservationNomapad = new DataAssetConduite(valObs);
        var bodyBoxObservation= new pw3.afficheBodyBoxObservation(dataObservationNomapad.affiche());
        var body = bodyBoxObservation.getHtml();
        var picto = "h";
        var titre = "Observations";
        var idBox = "boxObsNomapad";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getHtml();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#"+idBox, true);
    }).on("click", "#menu", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.stopImmediatePropagation();
        var id = "sommaire";
        var dataSommaire = new DataSommaire();
        var bodyBoxSommaire= new pw3.bodyBoxSommaire(dataSommaire.affiche());
        var body = bodyBoxSommaire.getHtml();
        var picto = "D";
        var titre = "Sommaire";
        var idBox = "boxSommaire";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getHtml();
        $("#listeBox").html(html);
        razSommaire();
        AfficheBoiteMessage("#"+idBox, true);
        $(".scroller").mCustomScrollbar();
    }).on("click", "#menuFourtous .pourquoi", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        AffichePourquoiRegle();
    }).on({
        mouseenter: function(e){e.stopPropagation();$(this).removeClass('desactive').addClass("active");},
        mouseleave: function(e){e.stopPropagation();$(this).addClass('desactive').removeClass("active");}
    }, "#boxSommaire .ligneNiv0").on("click", "#sommaireLvl1 .ligneNiv0 .titreLigneSommaireNiv0 .titremoduleNiv0", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        razSommaire();
        var element = $(this).closest(".ligneNiv0");
        element.addClass('deplis');
        element.next(".spacer").css("height", "1rem");
        element.prev(".spacer").css("height", "1rem");
        element.find(".tblNiv1").removeClass("none");
        $(".scroller").mCustomScrollbar("update");
    }).on("click", "#boxSommaire .ligneNiv0.deplis .titreLigneSommaireNiv0 .titremoduleNiv0", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        razSommaire();
    }).on("click", "#sommaireLvl1 .titreLigneSommaireNiv1 .titremoduleNiv1", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        $("#boxSommaire .ligneNiv1").removeClass("deplis");
        $("#boxSommaire .titreLigneSommaireNiv1 .tblNiv2").addClass("none");
        $(this).closest(".ligneNiv1").addClass("deplis");
        $(this).closest(".titreLigneSommaireNiv1").find(".tblNiv2").removeClass("none");
        $(".scroller").mCustomScrollbar("update");
    }).on("click", "#sommaireLvl1 table.tblNiv1 .ligneNiv1.deplis .titremoduleNiv1", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        $(this).closest(".ligneNiv1").removeClass("deplis");
        $(this).closest(".titreLigneSommaireNiv1").find(".tblNiv2").addClass("none");
        $(".scroller").mCustomScrollbar("update");
    }).on("click", "#sommaireLvl1 .titreLigneSommaireNiv2", function(e){
        e.stopPropagation();

        if($(this).closest(".ligneNiv2").attr('class').indexOf("nolink") > 0){return;}

        //en masque le cours et on envoie le resultat des stats si on sort d'un séquence
        if($("body").hasClass("sequence")){
            masqueCours();
            pw3.Stat.sendServer();
        }
        var cours = $(this).closest(".ligneNiv0").attr("id");
        var idcours = cours.split("_");
        var module = $(this).closest(".ligneNiv1").attr("id");
        var idmodule = module.split("_");
        var partie = $(this).closest(".ligneNiv2").attr("id");
        var idPartie = partie.split("_");
        closeBoiteMessage("boxSommaire");
        pw3.Page.AffichePage("cours","cours-c"+idcours[1]+"-"+idmodule[1]+"-"+idPartie[1]);
    }).on('click', '#tuto1,#tuto2,#tuto3', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        closeBoiteMessage();
        var url = $(this).attr("title");
        lireFichierVideo(url);
    }).on("click", "#boutonLancement", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var url = $(this).attr("title");
        lireFichierFlash(url, "Module alcool&eacute;mie");
    }).on('click', '#InfosLegales', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        var id = "InfosLegalesBox";
        var bodyBoxInfoLegale = new pw3.afficheBoxInfoLegale(pw3.Page.oAgence);
        var body = bodyBoxInfoLegale.getHtml();
        var picto = "";
        var titre = "Informations l&eacute;gales";
        var idBox = "boxInfosLegales";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getHtml();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#"+idBox, true);
    }).on('click', '#oublierMotDePasse', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();

        login_password=!login_password;

        var html = '';
        var contentPageAccueil = new pw3.PageLogin();
        html += contentPageAccueil.getHtml(login_password);
        $(".overlayBox.full, #BoxDialogue, #voileOverlay").remove();
        $("body").append(html).attr("class", "index");

        personnalise();

        /*e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        var id = "MdpOublierBox";
        var body = '<div class="contentAscenseur scrollerBox"><div class="label center">Pour recevoir votre code d\'acc&egrave;s, saisissez votre adresse e-mail.</div><div class="mail center"><input type="text" class="inputMail" name="mailOublier" id="mailOublier" /></div><div class="submit center"><input type="submit" id="mailSubmit" value="Envoyer" class="submit link" /></div></div>';
        var picto = "J";
        var titre = "R&eacute;cup&eacuteration de code d'acc&egrave;s";
        var idBox = "boxMdpOublier";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getHtml();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#"+idBox, true);*/
    }).on('click', '#mailSubmit', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        closeBoiteMessage("boxMdpOublier");
        var emailConfirmation = $('#mailOublier').val();
        if(emailConfirmation!=""){
            var dataObj = {login: EDITEUR, password: APIKEY, email: emailConfirmation, sous_domaine: pw3.Page.oAgence.agence.SousDomaine};
            var ObjectForm= new CallObj("getPassword", PHP_CONNECT, dataObj, "temporaire");
            var retour = ObjectForm.getObj();
            if(retour){
                var id = "MdpEnvoieBox";
                var body = 'Votre mot de passe vient de vous &ecirc;tre renvoy&eacute; par mail. Si vous ne le trouvez pas, v&eacute;rifiez votre r&eacute;pertoire "courrier ind&eacute;sirable".';
                var picto = "J";
                var titre = "R&eacutecup&eacuteration de code d'acc&egrave;s";
                var idBox = "boxMdpEnvoie";
                var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
                var html = boxConstruct.getHtml();
                $("#listeBox").html(html);
            }else{
                var id = "MdpErrorBox";
                var body = 'L\'adresse mail que vous avez saisie n\'existe pas. Merci de saisir l\'adresse indiqu&eacute;e sur votre dossier d\'inscription.';
                var picto = "J";
                var titre = "R&eacutecup&eacuteration de code d'acc&egrave;s";
                var idBox = "boxMdpError";
                var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
                var html = boxConstruct.getHtml();
                $("#listeBox").html(html);
            }
            AfficheBoiteMessage("#"+idBox, true);
        }
    }).on("focus", "input.datepicker", function () {
        $("input.datepicker").datepicker({
            changeMonth: true,
            changeYear: true,
            defaultDate: "-15y",
            yearRange: "-75y:+15y"
        });
        $("input.datepicker").datepicker($.datepicker.regional['fr']);
    }).on("click", "#boxPremiereConnexion .submit", function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        var dataUserserializeObject =$('#frmComplementInfo').serializeObject();

        dataUserserializeObject.nomNaissance = dataUserserializeObject.nomNaissance.trim();
        dataUserserializeObject.nomUsage = dataUserserializeObject.nomUsage.trim();
        dataUserserializeObject.prenom = dataUserserializeObject.prenom.trim();
        dataUserserializeObject.AdresseCommune = dataUserserializeObject.AdresseCommune.trim();

        var dataUser =JSON.stringify(dataUserserializeObject);
        var frmInfoEleve = new DataMajCompteUtilisateur(dataUser);
        frmInfoEleve.retourPremiereConectionUser();
        pw3.Page.AffichePage();
    }).on('click', '#messagerie, #boxConfDelMessage .envoiForm .inputAnnule', function(e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        var id = "message";
        var picto = "E";
        var titre = " Courrier";
        var idBox = "boxMessage";
        var boxConstruct = new pw3.box(id, titre, picto, "", idBox);
        var html = boxConstruct.getBoxMessagerie();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#" + idBox, true);
        $(".scroller").mCustomScrollbar();
    }).on('click', '#boxMessage .listeMessages .ligneMessage', function(e){
        e.stopPropagation();
        e.stopImmediatePropagation();
        var idLigne = $(this).attr("id");
        idLigne = idLigne.replace("id_", "");
        $("#boxMessage .ligneMessage").removeClass("active");
        if($(this).hasClass("nonLu")){
            var nbNewMessageInBoxMsg = (($("#boxMessage .rondRougeNbMessage").text())*1);
            if(nbNewMessageInBoxMsg==1){$(".rondNbMessage, .messagerie .link.fontEdiser").addClass("none");}else{$(".rondRougeNbMessage").text(nbNewMessageInBoxMsg-1);}
        }
        $(this).removeClass("nonLu").addClass("active");
        var messageLu = new DataMessageLu(idLigne);
        var retourMessageLu = JSON.parse(messageLu.setMessageLu());
        var boxConstruct = new pw3.box();
        var afficheMessage = boxConstruct.getAfficheMessage(retourMessageLu);
        $("#boxMessage .suiteMessege").html(afficheMessage);
        $("#boxMessage .message").removeClass("none");
        $("#boxMessage .divListeMessage").addClass("active");
        $(".scroller").mCustomScrollbar();
    }).on("click", "#repondreMessage h2", function(e){
        e.stopPropagation();
        repondreMessagerie();
    }).on("click", "#repondreMessage.active .envoiForm .inputAnnule", function(e){
        e.stopPropagation();
        masqueRepondreMessagerie();
    }).on('click', '#boxMessage #sendMessage .envoiForm .inputSubmit', function(e){
        e.preventDefault();
        e.stopPropagation();
        var objReponse = {};
        objReponse.sujet = $("#sendMessage input[name='sujet']").val();
        objReponse.idMessage = $("#sendMessage input[name='idMessage']").val();
        objReponse.reponse = $("#sendMessage textarea[name='reponse']").val();
        var messageEnvoie = new DataEnvoieMessage(objReponse);
        messageEnvoie.setReponse();
        masqueRepondreMessagerie();
    }).on('click', '#boxMessage .listeMessages .ligneMessage .corbeille', function(e){
        e.stopPropagation();
        var idLigne = $(this).closest("tr").attr("id");
        idLigne = idLigne.replace("id_", "");
        $("#boxMessage .ligneMessage").removeClass("active");
        $(this).removeClass("nonLu").addClass("active");
        var id = "message_"+idLigne;
        var body = 'Êtes-vous sûr de vouloir effacer ce message ?<br /><div class="envoiForm center"><input class="inputSubmit" type="button" value="Oui" name="Oui"><input class="inputAnnule" type="button" value="Non" name="Non"></div>';
        var picto = "?";
        var titre = "Confirmation";
        var idBox = "boxConfDelMessage";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getHtml();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#" + idBox, true)
    }).on('click', '#boxConfDelMessage .envoiForm .inputSubmit', function(e){
        e.stopPropagation();
        var idLigne = $(this).closest(".background").attr("id").replace('message_', '');
        var messageDel = new DataDelMessage(idLigne);
        var test = JSON.parse(messageDel.delMessage());
        var id = "message";
        var picto = "E";
        var titre = " Courrier";
        var idBox = "boxMessage";
        var boxConstruct = new pw3.box(id, titre, picto, "", idBox);
        var html = boxConstruct.getBoxMessagerie();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#" + idBox, true);
    }).on('click', '#retourFormation', function(){
        pw3.Page.AffichePage(pw3.Examens.origineUrlExamen[0], pw3.Examens.origineUrlExamen[0]+"-"+pw3.Examens.origineUrlExamen[1]);
        initVarOrigineTest();
    }).on('click', '#retourThema', function(){
        pw3.Page.AffichePage("examens");
        initVarOrigineTest();
    });
});
