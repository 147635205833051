/**
 * Created by d.cian on 08/09/2015.
 */

var FormationFunction = function(){

    if((!pw3.Page.versionFormationServeur)||(pw3.Page.versionFormationServeur=="")){
        pw3.Page.versionFormationServeur = callData.formation.checkVersionFormationInServeur();
    }

    if((!pw3.Page.versionFormationLs)||(pw3.Page.versionFormationLs=="")){
        pw3.Page.versionFormationLs = callData.formation.checkVersionFormationInLs();
    }

    if((!pw3.Page.dataFormation)||(callData.formation.checkFormationVarInLs===false)||(isEmpty(pw3.Page.dataFormation))){
        pw3.Page.dataFormation = {};
        pw3.Page.dataCouleur = {};
        pw3.Page.dataForme = {};
        //Le premier appel stoque les infos dans le local storage
        pw3.Page.dataFormation.formation = callData.formation.callFormation();
        //Le deusième renvois la formation cette action ne peut pas être éfféctuer en un seul appel.
        pw3.Page.dataFormation.formation = callData.formation.callFormation();
        pw3.Page.dataCouleur.couleur = callData.formation.callFormationCouleur();
        pw3.Page.dataForme.forme = callData.formation.callFormationForme();
    }
    else if((pw3.Page.versionFormationLs != pw3.Page.versionFormationServeur)){
        pw3.Page.dataFormation = {};
        pw3.Page.dataCouleur = {};
        pw3.Page.dataForme = {};
        pw3.Page.dataFormation.formation = callData.formation.forceUpdateFormation();
        pw3.Page.dataCouleur.couleur = callData.formation.callFormationCouleur();
        pw3.Page.dataForme.forme = callData.formation.callFormationForme();
        pw3.Page.versionFormationLs = callData.formation.updateVersionFormationInLs();
    }
};

var DataStat = function(){
    if(!pw3.Stat.dataStat){
        pw3.Stat.dataStat = {};
        pw3.Stat.dataStat.stat = callData.statistiques.callStatistique();

        var objMobile = new CallObj("mobile", "", "", "sessionStorage", "mobile");
        pw3.Page.obMobile = objMobile.getObj();

        var objEtg = new CallObj("etg", "", "", "sessionStorage", "etg");
        pw3.Page.obEtg = objEtg.getObj();

        var objQuiz = new CallObj("quiz", "", "", "sessionStorage", "quiz");
        pw3.Page.obQuiz = objQuiz.getObj();

        var objExamen = new CallObj("examen", "", "", "sessionStorage", "examen");
        pw3.Examens.obExamen = objExamen.getObj();

        var objFormation = new CallObj("formation", "", "", "sessionStorage", "formation");
        pw3.Stat.dataStat.stat = objFormation.getObj();
    }
};

var AssetFunction = function(){
    if(!pw3.Page.dataAsset) {
        pw3.Page.dataAsset = {};
        pw3.Page.dataAsset.asset = callData.formation.callAsset();
    }
};

var loadTestEnCours = function(){
    if(!pw3.Examens.oTestEnCours) {
        pw3.Examens.TestCorrection = callData.examen.testCorrection();
        pw3.Examens.oTestEnCours = callData.examen.testEnCours();
        pw3.Examens.obExamen = callData.examen.examen();
        if(pw3.Examens.oTestEnCours.Compteur){
            pw3.Examens.oTestEnCours.Compteur++;
            var oTestEnCours = new CallObj("", "", pw3.Examens.oTestEnCours, "update", "TestEnCours");
            pw3.Examens.oTestEnCours = oTestEnCours.getObj();
        }
    }
};

var DataAgence = function(agence){this.Agence = agence;};

var DataCouleur = function(couleur){this.couleur = couleur;};

var ExamensFunction = function(){};
var DataPage = function(){};
var DataAssetConduite = function(valObs){this.valObs = valObs;};
var DataSommaire = function(){};

var DataMessageList = function(){};
var DataMessageLu = function(idMessage){this.idMessage = idMessage;};
var DataEnvoieMessage = function(msgReponse){this.dataReponse = msgReponse;};
var DataDelMessage = function(idMessage){this.idMessage=idMessage;};

DataDelMessage.prototype.delMessage = function(){
    var objAtribut = {};
    objAtribut.Supprime = true;
    objAtribut.aze = true;
    var dataObj = {login: EDITEUR, password: APIKEY, id_message: this.idMessage, data:objAtribut};
    var ObjectMessage= new CallObj("patchMessage", PHP_MESSAGERIE, dataObj, "temporaire", "");
    var retourMessage = ObjectMessage.getObj();
    return retourMessage;
};
DataEnvoieMessage.prototype.setReponse = function(){
    var dataObj = {login: EDITEUR, password: APIKEY, id_eleve: pw3.Page.obEleve.NumEleve, sujet: this.dataReponse.sujet, contenu: this.dataReponse.reponse};
    var ObjectMessage= new CallObj("setMessage", PHP_MESSAGERIE, dataObj, "temporaire", "");
    var retourMessage = ObjectMessage.getObj();
    return retourMessage;
};
DataMessageLu.prototype.setMessageLu = function(){
    var objAtribut = {};
    objAtribut.DateLecture = true;
    var dataObj = {login: EDITEUR, password: APIKEY, id_message: this.idMessage, data:objAtribut};
    var ObjectMessage= new CallObj("patchMessage", PHP_MESSAGERIE, dataObj, "temporaire", "");
    var retourMessage = ObjectMessage.getObj();
    return retourMessage;
};

DataMessageList.prototype.getDataMessageList = function(){
    var dataObj = {login: EDITEUR, password: APIKEY, id_eleve: pw3.Page.obEleve.NumEleve};
    var ObjectMessage= new CallObj("getMessage", PHP_MESSAGERIE, dataObj, "temporaire", "");
    var retourMessage = ObjectMessage.getObj();
    return retourMessage;
};

var DataMajCompteUtilisateur = function(data){this.data = data;};

DataMajCompteUtilisateur.prototype.retourPremiereConectionUser = function(){
    var champObligatoire = 0;
    this.data = JSON.parse(this.data);
    var dateNaissance = this.data.dateNaissance.substr(6,4)+this.data.dateNaissance.substr(3,2)+this.data.dateNaissance.substr(0,2);
    var msgRetour = "";

    //contrôle les champs obligatoire
    if(this.data.nomNaissance === ""){msgRetour = "Vous devez saisir votre votre nom de naissance.";}
    else if(this.data.nomUsage != '' && validateName(this.data.nomUsage)===false){
        msgRetour = "Votre nom d'usage est incorrect";
    }
    else if(validateName(this.data.nomNaissance)===false){msgRetour = "Votre nom de naissance est incorrect";}
    else if(validateName(this.data.prenom)===false){msgRetour = "Votre prénom est incorrect";}
    else if(this.data.dateNaissance===""){msgRetour = "Vous devez saisir votre date de naissance";}
    else if(this.data.prenom===""){msgRetour = "Vous devez saisir votre prénom";}
    else if(this.data.courriel===""){msgRetour = "Vous devez saisir votre adresse électronique";}
    else if(this.data.AdresseCommune===""){msgRetour = "Vous devez saisir votre ville";}
    else if(this.data.AdresseCodePostal===""){msgRetour = "Vous devez saisir votre code postal";}
    else if(validateEmail(this.data.courriel)===false){msgRetour = "Votre adresse email est mal formatée";}
    else{champObligatoire = 1;}

    pw3.Page.obEleve.erreurMessageMajInfo = null;
    pw3.Page.obEleve.erreurMessageSerializeInfo = null;

    if(champObligatoire === 1) {
        //Le flag, la date de fin et la date de début sont géré par le webservice
        var nom;
        var oEleve = {};

        oEleve.Nom = this.data.nomUsage;
        oEleve.NomNaissance = this.data.nomNaissance;
        oEleve.Prenom = this.data.prenom;
        oEleve.Email = this.data.courriel;
        oEleve.DateNaissance = dateNaissance;
        oEleve.NumEleve = pw3.Page.obEleve.NumEleve;

        if (oEleve.Nom == "") {nom = this.data.nomNaissance;}
        else {nom = this.data.nomUsage;}

        pw3.Page.connect = 4;

        var dataObj = {login: EDITEUR, password: APIKEY, data: oEleve};
        var ObjectEleve= new CallObj("setEleve", PHP_CONNECT, dataObj, "updateEleve", "eleve");
        pw3.Page.obEleve = ObjectEleve.getObj();
    }
    else{
        pw3.Page.obEleve.erreurMessageMajInfo = msgRetour;
        pw3.Page.obEleve.erreurMessageSerializeInfo = JSON.stringify(this.data);
    }
};
DataSommaire.prototype.affiche = function(){
    var formation = pw3.Page.dataFormation.formation["tabChild"];
    var formationStat = pw3.Stat.dataStat.stat;
    var tblSommaire = [];
    for(var idFormation in formation){
        var objSomaireDataCours = {};
        var idCours = formation[idFormation].id;
        var module = formation[idFormation].tabChild;
        objSomaireDataCours.ordre = formation[idFormation].Meta.ordre;
        objSomaireDataCours.id = idCours;
        objSomaireDataCours.puce = formation[idFormation].Meta.puce;
        objSomaireDataCours.color = formation[idFormation].Meta.color;
        objSomaireDataCours.titre = formation[idFormation].tabObjectLang[0].titre;
        objSomaireDataCours.pictoAvancement = formationStat[idCours].is_fini;
        objSomaireDataCours.tabChild = [];
        for(var idModule in module){
            var sequence = module[idModule].tabChild;
            var objSomaireDataModule = {};
            objSomaireDataModule.id = idModule;
            objSomaireDataModule.puce = module[idModule].Meta.puce;
            objSomaireDataModule.ordre = module[idModule].Meta.ordre;
            objSomaireDataModule.titre = module[idModule].tabObjectLang[0].titre;
            objSomaireDataModule.pictoAvancement = formationStat[idModule].is_fini;
            objSomaireDataModule.tabChild = [];
            for(var idSequence in sequence){
                var objSomaireDataSequence = {};
                objSomaireDataSequence.id = idSequence;
                objSomaireDataSequence.ordre = sequence[idSequence].Meta.ordre;
                objSomaireDataSequence.titre = sequence[idSequence].tabObjectLang[0].titre;
                objSomaireDataSequence.pictoAvancement = formationStat[idSequence].is_fini;
                objSomaireDataModule.tabChild.push(objSomaireDataSequence);
                objSomaireDataSequence.tabChild = sequence[idSequence].tabChild;
            }
            objSomaireDataCours.tabChild.push(objSomaireDataModule);
        }
        tblSommaire.push(objSomaireDataCours);
    }
    return tblSommaire;
};
DataAssetConduite.prototype.affiche = function(){
    var ObjectForm= new CallObj("", "", "", "sessionStorage", "historique", "stats");
    this.getConduite = ObjectForm.getObj();
    var assetMaConduite = {};
    for(var idGetConduite in this.getConduite){
        if(idGetConduite===this.valObs){
            if(this.getConduite[idGetConduite].Observations){
                assetMaConduite.observation = nl2br(this.getConduite[idGetConduite].Observations);
            }
            if(this.getConduite[idGetConduite].Dessins){
                assetMaConduite.urlDessin = this.getConduite[idGetConduite].Dessins.split(" ");
            }
        }
    }
    return assetMaConduite;
};
ExamensFunction.prototype.getTest = function(){
    var deferGetTest = Q.defer();

    var test_id;
    var module_id;

    if((elementThis)&&(elementThis!="")){

        module_id = elementThis.attr("id");
        var ListClass = elementThis.attr("class");
        var splitClass = ListClass.split(" ");
        for(var idSplitClass in splitClass){
            var stringSearch = "testId_";
            if(splitClass[idSplitClass].substring(0, 7)==stringSearch){
                test_id = splitClass[idSplitClass].replace(stringSearch, "");
            }
        }
        module_id = cleanID(module_id);
    }

    //On lance le test

    pw3.Examens.lanceTest(test_id, module_id).then(function(retour){
        deferGetTest.resolve(retour);
    });

    return deferGetTest.promise;
};
FormationFunction.prototype.getVinetteInfo = function(){
    var treeChild = pw3.Page.dataFormation.formation["tabChild"];
    var vignette;
    var tabChildTbl;
    var tabContenu;
    var tabAssetId;
    var asset = "";
    var result = [];
    buildElementHtml.elementBase("div", "#degradeLivre", "", "clear");
    for(vignette in treeChild ) {
        for (tabChildTbl in treeChild[vignette].tabChild){
            var tabChildMeta = treeChild[vignette].tabChild[tabChildTbl]["Meta"];
            var tabChildAsset = treeChild[vignette].tabChild[tabChildTbl]["tabContenu"];
            var tabChildObjectLang= treeChild[vignette].tabChild[tabChildTbl]["tabObjectLang"][0];
            for(tabContenu in tabChildAsset){
                for(tabAssetId in tabChildAsset[tabContenu]["tabAsset"]){
                    asset = tabChildAsset[tabContenu]["tabAsset"][tabAssetId]["AssetMeta"].contenu;
                }
            }
            result.push({pos_x:tabChildMeta.pos_x, pos_y:tabChildMeta.pos_y, color:treeChild[vignette]["Meta"].color, color2:treeChild[vignette]["Meta"].color2, titre:tabChildObjectLang.titre, asset:asset, id:tabChildMeta.id, puceModule:tabChildMeta.puce, idParent:treeChild[vignette].id, puce:treeChild[vignette]["Meta"].puce});
        }
    }
    return result;
};
FormationFunction.prototype.getCouleurCours = function(idCours){
    var colorCours = pw3.Page.dataFormation.formation["tabChild"][idCours]["Meta"].color;
    return colorCours;
};
FormationFunction.prototype.getTitreCours = function(idCours){
    var titreCours = pw3.Page.dataFormation.formation["tabChild"][idCours]["tabObjectLang"][0].titre;
    return titreCours;
};
FormationFunction.prototype.getTitreModule = function(idCours, idModule){
    var titreModule = pw3.Page.dataFormation.formation["tabChild"][idCours]["tabChild"][idModule]["tabObjectLang"][0].titre;
    return titreModule;
};
FormationFunction.prototype.getThemeId = function(idCours, idModule){
    var theme_id = pw3.Page.dataFormation.formation["tabChild"][idCours]["tabChild"][idModule]["Meta"].theme_id;
    return theme_id;
};
FormationFunction.prototype.getPuce = function(idCours, idModule){
    var puceModule = pw3.Page.dataFormation.formation["tabChild"][idCours]["tabChild"][idModule]["Meta"].puce;
    return puceModule;
};
FormationFunction.prototype.getTitrePartie = function(idCours, idModule, idPartie){
    var titrePartie = pw3.Page.dataFormation.formation["tabChild"][idCours]["tabChild"][idModule]["tabChild"][idPartie]["tabObjectLang"][0].titre;
    return titrePartie;
};
FormationFunction.prototype.getMenuInfo = function(){

    reloadFormation();

    var treeChild = pw3.Page.dataFormation.formation.tabChild;
    var result = [];
    for(var li in treeChild){
        var metaChild = treeChild[li]["Meta"];
        var tabObjectLangChild = treeChild[li]["tabObjectLang"]["0"];
        if(!metaChild.color2){metaChild.color2=metaChild.color;}
        result.push({id:treeChild[li].id, titre:tabObjectLangChild.titre, color:metaChild.color, puce:metaChild.puce, ordre:metaChild.ordre, color2:metaChild.color2});
    }

    return result;
};
AssetFunction.prototype.getTree = function(){
    var treeChild = pw3.Page.dataAsset.asset;
    return treeChild;
};
DataCouleur.prototype.getTree = function(){
    var couleur = this.couleur;
    return couleur;
};
FormationFunction.prototype.getTree = function(treeNav){
    var idsNav = treeNav.split("-");
    var treeChild = "";
    idsNav.shift();
    switch(idsNav.length){
        case 1 :
            treeChild = getTreeFormation(pw3.Page.dataFormation.formation, cleanID(idsNav[0]));
        case 2 :
            treeChild = getTreeFormation(pw3.Page.dataFormation.formation, cleanID(idsNav[0]), cleanID(idsNav[1]));
        case 3 :
            treeChild = getTreeFormation(pw3.Page.dataFormation.formation, cleanID(idsNav[0]), cleanID(idsNav[1]), cleanID(idsNav[2]));
        case 4 :
            treeChild = getTreeFormation(pw3.Page.dataFormation.formation, cleanID(idsNav[0]), cleanID(idsNav[1]), cleanID(idsNav[2]), cleanID(idsNav[3]));
    }

    var treeChildClone = clone(treeChild);

    if(treeChildClone.tabChild){
        //sort le pourquoi de la regle de l'objet renvoyé.
        delete treeChildClone.tabChild[findPourquoiRegle().idPartie];
    }

    return treeChildClone;
};
DataPage.prototype.getBoxInfo = function(idBox){
    var result = [];
    var titreBox ="";
    var pictoBox = "";
    var bodyBox = "";

    if(idBox=="test"){
        titreBox = "test";
        pictoBox = "R";
        bodyBox = "bodyBox";
    }

    result.push({id:idBox, titre:titreBox, picto:pictoBox, body:bodyBox});
    return result;
};

