/**
 * Created by d.cian on 01/09/2015.
 */

//**************************
//** Google Analytics
//**************************
(function (i, s, o, g, r, a, m) {
    try{
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
        a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
    }
    catch(e){catchError(e);}
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

function addGoogleAnalyticsLine(url, title){
    try{
        //Data Google Analytics
        ga('create', 'UA-970174-21', 'packweb3.com');
        ga('send', { 'hitType': 'pageview', url: url, 'title': title});
    }
    catch(e){catchError(e);}
}

function detectMobile(){
    try{

        addContextToLog("user agent : "+navigator.userAgent);
        addContextToLog("mobileScreen : "+pw3.Page.mobileScreen);

        var mobile = "false";
        if(
            (
                navigator.userAgent.match(/Android/i)||
                navigator.userAgent.match(/webOS/i)||
                navigator.userAgent.match(/iPhone/i)||
                navigator.userAgent.match(/iPad/i)||
                navigator.userAgent.match(/iPod/i)||
                navigator.userAgent.match(/BlackBerry/i)||
                navigator.userAgent.match(/Windows Phone/i)
            )&&((pw3.Page.mobileScreen=="true")||(!pw3.Page.mobileScreen))
        ){
            $("body").removeClass("desktop");
            $("body").addClass("mobile");
            mobile="true";
        }else{
            $("body").removeClass("mobile");
            $("body").addClass("desktop");
        }
        addContextToLog("mobile : "+mobile);
        return mobile;
    }
    catch(e){catchError(e);}
}

function logOut(){
    try{
        if(numEleve && numEleve!=""){
            var dataObj = {login: EDITEUR, password: APIKEY, eleve_id: numEleve};
            var recalculeStatLogOut = new CallObj("setDeconnect", PHP_CONNECT, dataObj, "setLogOut", "");
            recalculeStatLogOut.getObj();
        }
    }
    catch(e){catchError(e);}
}

function selectColor(ref){
    try{
        var colorToUse;

        //Conversion en pourcentage
        var pst = (100*ref)/0.035;

        if(pst < 60 ){colorToUse = "#8ac0f7";}
        //else if(pst < 60 ){colorToUse = "#9d90eb";}
        else if(pst < 70 ){colorToUse = "#dc68ec";}
        else if(pst < 80 ){colorToUse = "#df50c0";}
        else if(pst < 90 ){colorToUse = "#e41b64";}
        else {colorToUse = "#e90000";}
        return colorToUse;
    }
    catch(e){catchError(e);}
}

function drawPie(ctx, x, y, radius, startAngle, endAngle ) {
    try{
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.arc(x, y, radius, startAngle - Math.PI/2, endAngle - Math.PI/2, false);
        ctx.fill();
    }
    catch(e){catchError(e);}
}

function repondreMessagerie(){
    try{
        $("#boxMessage .repondreMessage h2.link").addClass("none");
        $("#boxMessage .repondreMessage .sujet").removeClass("none");
        var target = $("#boxMessage .repondreMessage");
        target.addClass("active");
        target.find("#sendMessage").removeClass("none");
        target.find("#sendMessage textarea").val("");
    }
    catch(e){catchError(e);}
}

function testNavigateur(){
    try{
        var navigateurEtVerssion;
        var myVideoMp4 = document.createElement('video');
        var oldNav = false;

        myVideoMp4 = (typeof myVideoMp4.canPlayType === "function" && myVideoMp4.canPlayType('video/mp4') !== "");
        navigateurEtVerssion = navigatorEtVersion();

        if((myVideoMp4===false)
            ||(navigateurEtVerssion === "Safari 5")
            ||(navigateurEtVerssion === "IE 10")
            ||(navigateurEtVerssion === "MSIE 10")
            ||(navigateurEtVerssion === "IE 9")
            ||(navigateurEtVerssion === "MSIE 9")
            ||(navigateurEtVerssion === "IE 8")
            ||(navigateurEtVerssion === "MSIE 8")
            ||(navigateurEtVerssion === "IE 7")
            ||(navigateurEtVerssion === "IE 6")){
            oldNav = true;
        }
        return oldNav;
    }
    catch(e){
        console.log('testNavigateur');
        catchError(e);
    }
}

function maintenance(){
    try{
        var retour = "";
        var modeMaintenace = {};
        modeMaintenace.maintenance = false;
        $.ajax({
            method: "GET",
            url: protocoleMedia + "://maintenance.ediser.com/maintenance-services.php?service=pw3",
            dataType: "json",
            async: false,
            success: function(e){
                retour = e;
            },
            timeout: 1000
        });

        if(retour.maintenance==1){
            modeMaintenace.maintenance = true;
            modeMaintenace.description = retour.description;
        }
        return modeMaintenace;
    }
    catch(e){
        console.log('maintenance');
        catchError(e);
    }
}

function masqueRepondreMessagerie(){
    try{
        var target = $("#boxMessage .repondreMessage");
        target.removeClass("active");
        target.find("#sendMessage").addClass("none");
        $("#boxMessage .repondreMessage h2.link").removeClass("none");
        $("#boxMessage .repondreMessage .sujet").addClass("none");
    }
    catch(e){
        console.log('masqueRepondreMessagerie');
        catchError(e);
    }
}

function navigatorEtVersion(){
    try{
        var ua= navigator.userAgent, tem,
            M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
        }
        if(M[1]=== 'Chrome'){
            tem= ua.match(/\bOPR\/(\d+)/);
            if(tem!== null){return 'Opera '+tem[1];}
        }
        M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem= ua.match(/version\/(\d+)/i))!== null){M.splice(1, 1, tem[1]);}
        return M.join(' ');
    }
    catch(e){
        console.log('navigatorEtVersion');
        catchError(e);
    }
}

function strtotime(text, now) {
    var parsed, match, today, year, date, days, ranges, len, times, regex, i, fail = false;
    if(!text){return fail;}
    text = text.replace(/^\s+|\s+$/g, '').replace(/\s{2,}/g, ' ').replace(/[\t\r\n]/g, '').toLowerCase();

    match = text.match(/^(\d{1,4})([\-\.\/\:])(\d{1,2})([\-\.\/\:])(\d{1,4})(?:\s(\d{1,2}):(\d{2})?:?(\d{2})?)?(?:\s([A-Z]+)?)?$/);

    if (match && match[2] === match[4]) {
        if (match[1] > 1901) {
            switch (match[2]) {
                case '-':{if (match[3] > 12 || match[5] > 31) {return fail;}
                    return new Date(match[1], parseInt(match[3], 10) - 1, match[5],  match[6] || 0, match[7] || 0, match[8] || 0, match[9] || 0) / 1000;
                }
                case '.':{return fail;
                }
                case '/':
                { // YYYY/M/D
                    if (match[3] > 12 || match[5] > 31) {
                        return fail;
                    }

                    return new Date(match[1], parseInt(match[3], 10) - 1, match[5],
                            match[6] || 0, match[7] || 0, match[8] || 0, match[9] || 0) / 1000;
                }
            }
        } else if (match[5] > 1901) {
            switch (match[2]) {
                case '-':
                { // D-M-YYYY
                    if (match[3] > 12 || match[1] > 31) {
                        return fail;
                    }

                    return new Date(match[5], parseInt(match[3], 10) - 1, match[1],
                            match[6] || 0, match[7] || 0, match[8] || 0, match[9] || 0) / 1000;
                }
                case '.':
                { // D.M.YYYY
                    if (match[3] > 12 || match[1] > 31) {
                        return fail;
                    }

                    return new Date(match[5], parseInt(match[3], 10) - 1, match[1],
                            match[6] || 0, match[7] || 0, match[8] || 0, match[9] || 0) / 1000;
                }
                case '/':
                { // M/D/YYYY
                    if (match[1] > 12 || match[3] > 31) {
                        return fail;
                    }

                    return new Date(match[5], parseInt(match[1], 10) - 1, match[3],
                            match[6] || 0, match[7] || 0, match[8] || 0, match[9] || 0) / 1000;
                }
            }
        } else {
            switch (match[2]) {
                case '-':
                { // YY-M-D
                    if (match[3] > 12 || match[5] > 31 || (match[1] < 70 && match[1] > 38)) {
                        return fail;
                    }

                    year = match[1] >= 0 && match[1] <= 38 ? +match[1] + 2000 : match[1];
                    return new Date(year, parseInt(match[3], 10) - 1, match[5],
                            match[6] || 0, match[7] || 0, match[8] || 0, match[9] || 0) / 1000;
                }
                case '.':
                { // D.M.YY or H.MM.SS
                    if (match[5] >= 70) { // D.M.YY
                        if (match[3] > 12 || match[1] > 31) {
                            return fail;
                        }

                        return new Date(match[5], parseInt(match[3], 10) - 1, match[1],
                                match[6] || 0, match[7] || 0, match[8] || 0, match[9] || 0) / 1000;
                    }
                    if (match[5] < 60 && !match[6]) { // H.MM.SS
                        if (match[1] > 23 || match[3] > 59) {
                            return fail;
                        }

                        today = new Date();
                        return new Date(today.getFullYear(), today.getMonth(), today.getDate(),
                                match[1] || 0, match[3] || 0, match[5] || 0, match[9] || 0) / 1000;
                    }

                    return fail; // invalid format, cannot be parsed
                }
                case '/':
                { // M/D/YY
                    if (match[1] > 12 || match[3] > 31 || (match[5] < 70 && match[5] > 38)) {
                        return fail;
                    }

                    year = match[5] >= 0 && match[5] <= 38 ? +match[5] + 2000 : match[5];
                    return new Date(year, parseInt(match[1], 10) - 1, match[3],
                            match[6] || 0, match[7] || 0, match[8] || 0, match[9] || 0) / 1000;
                }
                case ':':
                { // HH:MM:SS
                    if (match[1] > 23 || match[3] > 59 || match[5] > 59) {
                        return fail;
                    }

                    today = new Date();
                    return new Date(today.getFullYear(), today.getMonth(), today.getDate(),
                            match[1] || 0, match[3] || 0, match[5] || 0) / 1000;
                }
            }
        }
    }

    // other formats and "now" should be parsed by Date.parse()
    if (text === 'now') {
        return now === null || isNaN(now) ? new Date()
                .getTime() / 1000 | 0 : now | 0;
    }
    if (!isNaN(parsed = Date.parse(text))) {
        return parsed / 1000 | 0;
    }

    date = now ? new Date(now * 1000) : new Date();
    days = {
        'dim': 0,
        'lun': 1,
        'mar': 2,
        'mer': 3,
        'jeu': 4,
        'ven': 5,
        'sam': 6
    };
    ranges = {
        'yea': 'FullYear',
        'mon': 'Month',
        'day': 'Date',
        'hou': 'Hours',
        'min': 'Minutes',
        'sec': 'Seconds'
    };

    function lastNext(type, range, modifier) {
        var diff, day = days[range];

        if (typeof day !== 'undefined') {
            diff = day - date.getDay();

            if (diff === 0) {
                diff = 7 * modifier;
            } else if (diff > 0 && type === 'last') {
                diff -= 7;
            } else if (diff < 0 && type === 'next') {
                diff += 7;
            }

            date.setDate(date.getDate() + diff);
        }
    }

    function process(val) {
        var splt = val.split(' '), // Todo: Reconcile this with regex using \s, taking into account browser issues with split and regexes
            type = splt[0],
            range = splt[1].substring(0, 3),
            typeIsNumber = /\d+/.test(type),
            ago = splt[2] === 'ago',
            num = (type === 'last' ? -1 : 1) * (ago ? -1 : 1);

        if (typeIsNumber) {
            num *= parseInt(type, 10);
        }

        if (ranges.hasOwnProperty(range) && !splt[1].match(/^mon(day|\.)?$/i)) {
            return date['set' + ranges[range]](date['get' + ranges[range]]() + num);
        }

        if (range === 'wee') {
            return date.setDate(date.getDate() + (num * 7));
        }

        if (type === 'next' || type === 'last') {
            lastNext(type, range, num);
        } else if (!typeIsNumber) {
            return false;
        }

        return true;
    }

    times = '(years?|months?|weeks?|days?|hours?|minutes?|min|seconds?|sec' +
        '|sunday|sun\\.?|monday|mon\\.?|tuesday|tue\\.?|wednesday|wed\\.?' +
        '|thursday|thu\\.?|friday|fri\\.?|saturday|sat\\.?)';
    regex = '([+-]?\\d+\\s' + times + '|' + '(last|next)\\s' + times + ')(\\sago)?';

    match = text.match(new RegExp(regex, 'gi'));
    if (!match) {
        return fail;
    }

    for (i = 0, len = match.length; i < len; i++) {
        if (!process(match[i])) {
            return fail;
        }
    }
    return (date.getTime() / 1000);
}

function timeToStr(){
    try{
        return moment().format("YYYYMMDDHHmmss");
    }
    catch(e){
        console.log('timeToStr');
        catchError(e);
    }
}

function lienPagePrecedente(n){
    try{
        if(!n){n=0;}
        var historyNavObj = JSON.parse(sessionStorage.getItem("SS_historyNavPrecedent"));
        return historyNavObj[n];
    }
    catch(e){
        console.log('lienPagePrecedente');
        catchError(e);}
}

function pushHistory(url) {
    try{
        if (pushHistorySs === true) {
            if (pw3.Page.rechargement === true) {
                pw3.Page.rechargement = false;
            }
            else if ((url) || (url == "")) {
                var historyNavArray = [];
                var nbUrlHistorique = 40;
                var historyNavObj = JSON.parse(sessionStorage.getItem("SS_historyNavPrecedent"));
                var splitUrl = url.split("-");

                if (historyNavObj !== null) {
                    for (var idHistoryNavObj in historyNavObj) {
                        historyNavArray.push(historyNavObj[idHistoryNavObj]);
                    }
                }

                historyNavArray.unshift(splitUrl);
                if (historyNavArray.length > nbUrlHistorique) {
                    historyNavArray.pop(0, 1);
                }
                var historyNavNewResult = sessionStorage.setItem("SS_historyNavPrecedent", JSON.stringify(historyNavArray));
                return historyNavNewResult;
            }
        }
        else {pushHistorySs = true;}
    }
    catch(e){
        console.log('pushHistory');
        catchError(e);
    }
}

function reinitQuestionVideo(){
    try{
        $("#contenuAjax .partieQuestion #videoQuestion").clone().prependTo("body");
        $("#contenuAjax .partieQuestion #videoQuestion").remove();
        $("body #videoQuestion").addClass("none");
    }
    catch(e){
        console.log('reinitQuestionVideo');
        catchError(e);
    }
}

function initEventOnBalise(element, oExamenEnCours) {
    try{
        return Handler.addListener(document.getElementById(element), 'ended', function() {
            if(element == "videoQuestion"){
                $("#audioQuestion").get(0).load();

                pw3.Examens.inspectImageComplete(element, oExamenEnCours.mp3, 1, oExamenEnCours.type);
                Handler.removeListener(videoQuestionHandler);
            }
            else if(element == "audioQuestion"){
                clearTimerSecuriteAudio();
                addContextToLog("Fichier audio lu ");
                pw3.Examens.progressBarreQuestionExamen();
            }
        }, false);
    }
    catch(e){
        console.log('initEventOnBalise');
        catchError(e);}
}

function findValueInArray(arr,obj) {
    try{return (arr.indexOf(obj) != -1);}
    catch(e){
        console.log('findValueInArray');
        catchError(e);
    }
}

function getQuestionVersion(asset, version){
    try{
        var result = "";
        if(asset && asset != ""){
            result = asset;
            result +=(version ? "?v=" + version : "");
        }
        return result;
    }
    catch(e){
        console.log('getQuestionVersion');
        catchError(e);}

}

function formatRepertoirMedia(str){
    try{return str.replace(" ", "").toLowerCase();}
    catch(e){
        console.log('formatRepertoirMedia');
        catchError(e);}
}

function findQuestionLoop(media, version){
    try{
        var loop = false;
        for(var idMedia in media){
            if(media[idMedia].type_id== 22){
                loop = true;
                break;
            }
        }
        return loop;
    }
    catch(e){
        console.log('findQuestionLoop');
        catchError(e);}
}

function findReponseLoop(media, version){
    try{
        var loop = false;
        for(var idMediaR in media){
            if((media[idMediaR].type_id== 23)||(media[idMediaR].type_id== 22)){
                loop = true;
                break;
            }
        }
        return loop;
    }
    catch(e){
        console.log('findReponseLoop');
        catchError(e);}
}

function findQuestionVideo(media, version){
    try{
        for(var idMedia in media){

            console.log('media[idMedia]', media[idMedia]);

            addContextToLog("Liste des asset type id : "+media[idMedia].type_id);

            if ( //Si il n'y a pas de vidéo spécifique a la réponse on utilise la video de la question
            (media[idMedia].tag == "packweb question") && (media[idMedia].type_id == 3)
            ) {
                var obQVideo = {};
                obQVideo.obj = media[idMedia];
                obQVideo.url = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-Q-video/" + media[idMedia].contenu, version);
                break;
            }

            if((media[idMedia].type_id == 2)||(media[idMedia].type_id == 12)||(media[idMedia].type_id == 14)||(media[idMedia].type_id == 16)){
                addContextToLog("URL image/loop/video : " + RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+media[idMedia].contenu);
            }

            if((media[idMedia].type_id == 20)||(media[idMedia].type_id == 22)){
                var obQVideo = {};
                obQVideo.obj = media[idMedia];
                obQVideo.url = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
                break;
            }
        }
        return obQVideo;
    }
    catch(e){
        console.log('findQuestionVideo');
        catchError(e);}
}

function FormatAssetTypeId(tabAsset){
    $(tabAsset).each(function(){
        if(!this.type_id){this.type_id = this.asset_type_id;}
        if(!this.type){this.type = this.titre;}
    });
    return tabAsset;
}

function findReponseVideo(media, version){
    try{
        for(var idMedia in media){
            //if(media[idMedia].type_id < 14) {
            if (                                           //On cherhce si il y a une vidéo spécifique a la réponse
            (media[idMedia].tag == "packweb reponse")&& (media[idMedia].type_id == 3)
            ) {
                var obQVideo = {};
                obQVideo.obj = media[idMedia];
                obQVideo.url = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-R-video/" + media[idMedia].contenu, version);
                break;
            }
            else if (                                      //Si il n'y a pas de vidéo spécifique a la réponse on utilise la video de la question
            (media[idMedia].tag == "packweb question")&& (media[idMedia].type_id == 3)
            ) {
                var obQVideo = {};
                obQVideo.obj = media[idMedia];
                obQVideo.url = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-Q-video/" + media[idMedia].contenu, version);
                break;
            }
            /*}
             else{*/
            if ((media[idMedia].type_id == 21)||(media[idMedia].type_id == 23)){
                var obQVideo = {};
                obQVideo.obj = media[idMedia];
                obQVideo.url = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
                break;
            }
            else if ((media[idMedia].type_id == 20)||(media[idMedia].type_id == 22)){
                var obQVideo = {};
                obQVideo.obj = media[idMedia];
                obQVideo.url = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
            }
            //}
        }
        return obQVideo;
    }
    catch(e){
        console.log('findReponseVideo');
        catchError(e);}
}

function findQuestionMp3(media, version){
    try {
        for (var idMedia in media) {
            //if (media[idMedia].type_id < 14) {
            if ((findValueInArray(media[idMedia].tag, "question") === true) && (media[idMedia].type_id == 5)) {
                var file = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-Q-flash/" + media[idMedia].contenu, version);
                addContextToLog("Fichier son : " + file);
                return file;
            }
            /*}
             else {*/
            if (media[idMedia].type_id == 18) {
                var file = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
                addContextToLog("Fichier son : " + file);
                return file;
            }
            //}
        }
    }
    catch(e){
        console.log('findQuestionMp3');
        catchError(e);}
}

function findReponseMp3(media, version){
    try {
        for (var idMedia in media) {
            //if (media[idMedia].type_id < 14) {
            if ((findValueInArray(media[idMedia].tag, "reponse") === true) && (media[idMedia].type_id == 5)) {
                var file = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-R-flash/" + media[idMedia].contenu, version);
                addContextToLog("Fichier son : " + file);
                return file;
            }
            /*}
             else {*/
            if (media[idMedia].type_id == 19) {
                var file = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
                addContextToLog("Fichier son : " + file);
                return file;
            }
            //}
        }
    }
    catch(e){
        console.log('findReponseMp3');
        catchError(e);}
}

function findQuestionImage(media, version){

    console.log('media', media);

    try {
        var deferFindQuestionImage = Q.defer();
        var imgInCache = (sessionStorage.getItem("SS_tamponImageExamen_" + pw3.Examens.oTestEnCours.Compteur));

        if ((imgInCache) && (pw3.Examens.oTestEnCours.type == "question")) {
            try {
                var base64Image = JSON.parse(eval("LZString." + lzStringDecompressionType)(imgInCache));
                deferFindQuestionImage.resolve(base64Image.dataURL);
            }
            catch (error) {
                deferFindQuestionImage.resolve(findUrlImage(media, version, pw3.Examens.oTestEnCours.type ));
            }
        }
        else{
            var file = "";
            for (var idMedia in media) {
                if ((findValueInArray(media[idMedia].tag, "question") === true) && ((media[idMedia].type_id == 1)||(media[idMedia].type_id == 2))) {
                    file = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-Q-image/" + media[idMedia].contenu, version);
                }
                if((media[idMedia].type_id == 14)||(media[idMedia].type_id == 16)){
                    file = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
                }
            }
            addContextToLog("Fichier image de secours  : " + file);
            deferFindQuestionImage.resolve(file);
        }

        return deferFindQuestionImage.promise;
    }
    catch(e){
        console.log('findQuestionImage');
        catchError(e);}
}

function findReponseImage(media, version){
    try {
        var file = "";
        for (var idMedia in media) {
            if((media[idMedia].type_id == 15)||(media[idMedia].type_id == 17)){
                file = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
                break;
            }
            else if((findValueInArray(media[idMedia].tag, "reponse") === true) && ((media[idMedia].type_id == 1)||(media[idMedia].type_id == 2))){
                file = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-Q-image/" + media[idMedia].contenu, version);
                break;
            }
            else if((media[idMedia].type_id == 14)||(media[idMedia].type_id == 16)){
                file = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
            }
            else if((findValueInArray(media[idMedia].tag, "question") === true) && ((media[idMedia].type_id == 1)||(media[idMedia].type_id == 2))){
                file = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-Q-image/" + media[idMedia].contenu, version);
            }
        }
        return file;
    }
    catch(e){
        console.log('findReponseImage');
        catchError(e);}
}

function findUrlImage(media, version, type){
    try{
        var racineMedia = RACINE_MEDIA_QUESTION_RESCU;
        var racineMediaNew = RACINE_MEDIA_QUESTION_NEW_RESCU;
        var file = "";

        if(type!="question"){
            racineMedia = RACINE_MEDIA_QUESTION;
            racineMediaNew = RACINE_MEDIA_QUESTION_NEW;
        }

        for (var idMedia in media) {
            if ((findValueInArray(media[idMedia].tag, "question") === true) && ((media[idMedia].type_id == 1)||(media[idMedia].type_id == 2))) {
                file = getQuestionVersion(racineMedia + "web-Q-image/" + media[idMedia].contenu, version);
                addContextToLog("Fichier image : " + file);
                return file;
            }

            if((media[idMedia].type_id == 14)||(media[idMedia].type_id == 16)){
                file = getQuestionVersion(racineMediaNew + formatRepertoirMedia(media[idMedia].type) +"/"+ media[idMedia].contenu, version);
                addContextToLog("Fichier image : " + file);
                return file;
            }
        }
    }
    catch(e){
        console.log('findUrlImage');
        catchError(e);}
}

function initVarOrigineTest(){
    pw3.Examens.origineTypeExamen = "";
    pw3.Examens.origineUrlExamen = "";
}

function formatNewMenuParam(locationHref){
    try{
        var objreturn = {};
        var locationHrefSplit;

        objreturn.newMenu = locationHref.tree;
        objreturn.newParam = locationHref.objHistoryNav;
        if(locationHref.tree==""){
            objreturn.newMenu = locationHref.objHistoryNav;
            objreturn.newParam = "";
        }
        return objreturn;
    }
    catch(e){
        console.log('readHistorySuivant');
        catchError(e);
    }
}

function readHistorySuivant(n){
    try{
        var objTypeHistoryNav = {};
        objTypeHistoryNav.objHistoryNav = "";
        var historyNavSuivantArray = JSON.parse(sessionStorage.getItem("SS_historyNavSuivant"));

        if (historyNavSuivantArray !== null) {
            if (n >= 1) {
                var historyNavSuivantArrayNew = [];
                for (var idHistoryNavSuivantArray in historyNavSuivantArray) {
                    historyNavSuivantArrayNew.push(historyNavSuivantArray[idHistoryNavSuivantArray]);
                }

                var historyNavPrecedentArray = JSON.parse(sessionStorage.getItem("SS_historyNavPrecedent"));
                var historyNavPrecedentArrayNew = [];
                for (var idHistoryNavPrecedentArray in historyNavPrecedentArray) {
                    historyNavPrecedentArrayNew.push(historyNavPrecedentArray[idHistoryNavPrecedentArray]);
                }

                for(var i=1; i<=n; i++){
                    historyNavPrecedentArrayNew.unshift(historyNavSuivantArrayNew[0]);
                    historyNavSuivantArrayNew.shift();
                }

                objTypeHistoryNav.arrayHistoryNav = historyNavSuivantArray[0];

                for(var idObjHistoryNav in objTypeHistoryNav.arrayHistoryNav){
                    objTypeHistoryNav.objHistoryNav +=  "-"+objTypeHistoryNav.arrayHistoryNav[idObjHistoryNav];
                }

                objTypeHistoryNav.length = historyNavPrecedentArray.length;
                objTypeHistoryNav.objHistoryNav = objTypeHistoryNav.objHistoryNav.substring(1);
                objTypeHistoryNav.tree = objTypeHistoryNav.arrayHistoryNav[0];

                if(objTypeHistoryNav.objHistoryNav === objTypeHistoryNav.tree){
                    objTypeHistoryNav.tree = "";
                }

                return objTypeHistoryNav;
            }
        }
    }
    catch(e){
        console.log('readHistorySuivant');
        catchError(e);
    }
}

function readHistoryPrecedent(n){
    try{
        var objTypeHistoryNav = {};
        objTypeHistoryNav.objHistoryNav = "";
        objTypeHistoryNav.tree = "";

        var historyNavPrecedentArray = JSON.parse(sessionStorage.getItem("SS_historyNavPrecedent"));

        if (historyNavPrecedentArray !== null) {

            if(n>=1){
                var historyNavPrecedentArrayNew = [];
                for (var idHistoryNavPrecedentArray in historyNavPrecedentArray) {
                    historyNavPrecedentArrayNew.push(historyNavPrecedentArray[idHistoryNavPrecedentArray]);
                }

                var historyNavSuivantArray = JSON.parse(sessionStorage.getItem("SS_historyNavSuivant"));
                var historyNavSuivantArrayNew = [];
                for (var idHistoryNavSuivantArray in historyNavSuivantArray) {
                    historyNavSuivantArrayNew.push(historyNavSuivantArray[idHistoryNavSuivantArray]);
                }

                for(var i=1; i<=n; i++){
                    historyNavSuivantArrayNew.unshift(historyNavPrecedentArrayNew[0]);
                    historyNavPrecedentArrayNew.shift();
                }

                sessionStorage.setItem("SS_historyNavSuivant", JSON.stringify(historyNavSuivantArrayNew));
                sessionStorage.setItem("SS_historyNavPrecedent", JSON.stringify(historyNavPrecedentArrayNew));

                historyNavPrecedentArray = JSON.parse(sessionStorage.getItem("SS_historyNavPrecedent"));
            }

            objTypeHistoryNav.arrayHistoryNav = historyNavPrecedentArray[0];

            for(var idObjHistoryNav in objTypeHistoryNav.arrayHistoryNav){
                objTypeHistoryNav.objHistoryNav +=  "-"+objTypeHistoryNav.arrayHistoryNav[idObjHistoryNav];
            }
            objTypeHistoryNav.length = historyNavPrecedentArray.length;
            objTypeHistoryNav.objHistoryNav = objTypeHistoryNav.objHistoryNav.substring(1);
            if(objTypeHistoryNav.arrayHistoryNav){
                objTypeHistoryNav.tree = objTypeHistoryNav.arrayHistoryNav[0];
            }

            if(objTypeHistoryNav.objHistoryNav === objTypeHistoryNav.tree){
                objTypeHistoryNav.tree = "";
            }

        }else{objTypeHistoryNav = "";}

        return objTypeHistoryNav;
    }
    catch(e){
        console.log('readHistoryPrecedent');
        catchError(e);
    }
}

function convertNumvsPictoAvancement(valeur){
    try{
        var picto = " ";
        if(valeur!==false){
            if(valeur>=0){
                if(valeur == 0) {
                    picto = "m";
                }else if(valeur == 1){
                    picto = "n";
                } else if(valeur == 2){
                    picto = "o";
                } else if(valeur == 3){
                    picto = "l";
                }
            }
        }
        return picto;
    }
    catch(e){
        console.log('convertNumvsPictoAvancement');
        catchError(e);
    }
}

function switchSon(){
    try{
        if($('#audioQuestion').length){var elemAudio = $('#audioQuestion').get(0);}

        if($('#sequenceVideo').length){var elemAudio = $("#sequenceVideo").get(0);}

        if(elemAudio.length!=0){
            if(elemAudio.volume==1){
                elemAudio.volume = 0.001;
                $("#audioRelire .link ").text("L").addClass("sonOff");
                $("#audio .link ").text("L").addClass("sonOff");
            }
            else{
                elemAudio.volume = 1;
                $("#audioRelire .link ").text("t").removeClass("sonOff");
                $("#audio .link ").text("t").removeClass("sonOff");
            }
        }
    }
    catch(e){
        console.log('switchSon');
        catchError(e);}
}

function reponseSuivante(){
    try{
        var url= attrUrl();
        var numReponse = url[1].replace("revoir", "");
        var numreponseSuivante = (numReponse*1)+1;
        questionEnCours = numreponseSuivante+1;
        if(numreponseSuivante < ObjectLength(pw3.Examens.oTestEnCours.Question)){
            pw3.Page.AffichePage("examens", "revoir"+numreponseSuivante);
        }
    }
    catch(e){
        console.log('reponseSuivante');
        catchError(e);
    }
}

function reponsePrecedente(){
    try{
        var url= attrUrl();
        var numReponse = url[1].replace("revoir", "");
        var numreponsePrecedente = (numReponse*1)-1;
        questionEnCours = numreponsePrecedente+1;
        if(numreponsePrecedente >= 0){pw3.Page.AffichePage("examens", "revoir"+numreponsePrecedente);}
    }
    catch(e){
        console.log('reponsePrecedente');
        catchError(e);
    }
}

function lireFichierFlash(url, titre){
    try{
        var id = "boxFlashVideo";
        var body = '';
        var picto = "H";
        var idBox = "boxFlash";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getBoxFlash();
        $("#listeBox").html(html);
        AfficheBoiteMessage("section#boxFlash");

        $(".full.overlayBox").addClass("grayscale");
        $("#boxFlash embed").attr('src', RACINE_MEDIA+url);
        $("#boxFlash embed").attr('allowscriptaccess', 'always');
        $("#boxFlash embed").attr('src', RACINE_MEDIA+url);
        $("#boxFlash object").attr('codebase', RACINE_MEDIA+url);
        $('#boxFlash object param[name=movie]').attr('value',RACINE_MEDIA+url);
    }
    catch(e){
        console.log('lireFichierFlash');
        catchError(e);
    }
}

function zeroInitial(n) {
    try{return (n < 10) ? ("0" + n) : n;}
    catch(e){
        console.log('zeroInitial');
        catchError(e);
    }
}

function getTabAsset(param){
    try{
        var tabResult = [];
        var tabReturn = [];

        for(var idParam in param.asset_type_id) {
            tabResult["idAsset_"+param.asset_type_id[idParam]] = [];
        }

        var dataAsset = pw3.Page.dataAsset.asset;

        for(var idAsset in dataAsset){
            for(var idParam in param.asset_type_id){
                if(dataAsset[idAsset].asset_type_id==param.asset_type_id[idParam]){
                    var tabTheme = dataAsset[idAsset]["tabTheme"];
                    for(var idTheme in tabTheme){
                        if(param.themeId==idTheme){
                            tabResult["idAsset_"+param.asset_type_id[idParam]].push(dataAsset[idAsset]);
                        }
                    }
                }
            }
        }

        var tabClasseContravention = [];
        var tabClasseDelit = [];
        var tabRisque = [];
        var numClasse = [];

        var dataAssetSanction = tabResult["idAsset_"+ASSET_TYPE_SANCTION];
        var dataAssetRisque = tabResult["idAsset_"+ASSET_TYPE_RISQUE];

        for(var idAsset in dataAssetSanction){
            //var tabSanction = [];
            var DataAssetMeta = dataAssetSanction[idAsset]["AssetMeta"];
            var oMeta = {};
            oMeta.contenu = DataAssetMeta.contenu;
            oMeta.sanction_type = DataAssetMeta.sanction_type;
            oMeta.sanction_classe = DataAssetMeta.sanction_classe;
            oMeta.sanction_point = DataAssetMeta.sanction_point;
            oMeta.sanction_classe = DataAssetMeta.sanction_classe;

            var tabSanction = oMeta;

            if(!in_array(oMeta.sanction_classe , numClasse)){
                if(oMeta.sanction_type != 3){numClasse.push(oMeta.sanction_classe);}
            }

            if(oMeta.sanction_type == 1){
                if((tabClasseContravention[oMeta.sanction_classe])&&
                    (tabClasseContravention[oMeta.sanction_classe]["contenu"])&&
                    (tabClasseContravention[oMeta.sanction_classe]["contenu"][oMeta.sanction_point])){
                    tabClasseContravention[oMeta.sanction_classe]["contenu"][oMeta.sanction_point].push(tabSanction);
                }
                else{
                    if(!tabClasseContravention[oMeta.sanction_classe]){
                        tabClasseContravention[oMeta.sanction_classe] = [];
                    }
                    if(!tabClasseContravention[oMeta.sanction_classe]["contenu"]){
                        tabClasseContravention[oMeta.sanction_classe]["contenu"] = [];
                        tabClasseContravention[oMeta.sanction_classe]["listePoint"] = [];
                    }
                    if(!tabClasseContravention[oMeta.sanction_classe]["contenu"][oMeta.sanction_point]){
                        tabClasseContravention[oMeta.sanction_classe]["contenu"][oMeta.sanction_point] = [];
                    }
                    tabClasseContravention[oMeta.sanction_classe]["contenu"][oMeta.sanction_point].push(tabSanction);
                }
                tabClasseContravention[oMeta.sanction_classe]["listePoint"][oMeta.sanction_point] = oMeta.sanction_point;
            }
            else if(oMeta.sanction_type == 2){
                if((tabClasseDelit[0])&&(tabClasseDelit[0]["contenu"][oMeta.sanction_point])){
                    tabClasseDelit[0]["contenu"][oMeta.sanction_point].push(tabSanction);
                }
                else{
                    tabClasseDelit[0]=[];
                    tabClasseDelit[0]["contenu"]=[];
                    tabClasseDelit[0]["contenu"][oMeta.sanction_point]=[];
                    tabClasseDelit[0]["contenu"][oMeta.sanction_point].push(tabSanction);
                }
                if(!tabClasseDelit[0]["listePoint"]){
                    tabClasseDelit[0]["listePoint"] = [];
                }
                tabClasseDelit[0]["listePoint"][oMeta.sanction_point] = oMeta.sanction_point;
            }
            else if(oMeta.sanction_type == 3){
                if(oMeta.sanction_classe == null || oMeta.sanction_classe == 0 ){
                    if(!tabClasseDelit[0]){
                        tabClasseDelit[0] = [];
                    }
                    if(!tabClasseDelit[0]["contenuDocumentation"]){
                        tabClasseDelit[0]["contenuDocumentation"]=[];
                    }
                    tabClasseDelit[0]["contenuDocumentation"][oMeta.sanction_point] = tabSanction;
                }
                else{
                    if((tabClasseContravention[oMeta.sanction_classe]) && (tabClasseContravention[oMeta.sanction_classe]["contenuDocumentation"])){
                        tabClasseContravention[oMeta.sanction_classe]["contenuDocumentation"].push(tabSanction);
                    }
                    else{
                        if(!tabClasseContravention[oMeta.sanction_classe]){
                            tabClasseContravention[oMeta.sanction_classe]=[];

                        }
                        if(!tabClasseContravention[oMeta.sanction_classe]["contenuDocumentation"]){
                            tabClasseContravention[oMeta.sanction_classe]["contenuDocumentation"]=[];
                        }
                        tabClasseContravention[oMeta.sanction_classe]["contenuDocumentation"].push(tabSanction);
                    }
                }
            }
        }

        ksort(tabClasseContravention);
        ksort(tabClasseDelit);
        sort(numClasse);

        tabReturn["tabClasseContravention"] = tabClasseContravention;
        tabReturn["tabClasseDelit"] = tabClasseDelit;
        tabReturn["numClasse"] = numClasse;
        tabReturn["tabRisque"] = dataAssetRisque;

        return tabReturn;
    }
    catch(e){
        console.log('getTabAsset');
        catchError(e);
    }
}

function showContenuOnglet(numClasse , tabTypeSanction){
    try{
        var html = '';
        var isActive ='';
        html+='<section id="contenuOngletClass'+numClasse+'" class="contenuMenuClass  clear">';
        var compteurPoint = 0;
        for (var idTabTypeSanction in tabTypeSanction){
            for(var idListePoint in tabTypeSanction[numClasse]["listePoint"]){
                if((tabTypeSanction[idTabTypeSanction])&&(tabTypeSanction[idTabTypeSanction]["contenu"])&&(tabTypeSanction[idTabTypeSanction]["contenu"][tabTypeSanction[numClasse]["listePoint"][idListePoint]])) {
                    if (compteurPoint != 0) {
                        isActive = ' none';
                    }
                    compteurPoint = 1;
                    html += '<div id="perte' + idListePoint + numClasse + 'ContentOnglet" class="contentOngletPage' + isActive + '">';
                    for (var idTabTypeSanctionContenu in tabTypeSanction[numClasse]["contenu"][idListePoint]) {
                        html += '<p>' + tabTypeSanction[numClasse]["contenu"][idListePoint][idTabTypeSanctionContenu].contenu + '</p>';
                    }
                    html += '</div>';
                }
            }
        }
        html+='</section>';
        return html;
    }
    catch(e){
        console.log('showContenuOnglet');
        catchError(e);
    }
}

function ksort(inputArr, sort_flags) {
    try{
        var tmp_arr = {},
            keys = [],
            sorter, i, k, that = this,
            strictForIn = false,
            populateArr = {};

        switch (sort_flags) {
            case 'SORT_STRING':
                sorter = function(a, b){return that.strnatcmp(a, b);};
                break;
            case 'SORT_LOCALE_STRING':
                var loc = this.i18n_loc_get_default();
                sorter = this.php_js.i18nLocales[loc].sorting;
                break;
            case 'SORT_NUMERIC':
                // compare items numerically
                sorter = function(a, b){return ((a + 0) - (b + 0));};
                break;
            default:
                sorter = function(a, b) {
                    var aFloat = parseFloat(a),
                        bFloat = parseFloat(b),
                        aNumeric = aFloat + '' === a,
                        bNumeric = bFloat + '' === b;
                    if (aNumeric && bNumeric) {
                        return aFloat > bFloat ? 1 : aFloat < bFloat ? -1 : 0;
                    } else if (aNumeric && !bNumeric) {
                        return 1;
                    } else if (!aNumeric && bNumeric) {
                        return -1;
                    }
                    return a > b ? 1 : a < b ? -1 : 0;
                };
                break;
        }

        for (k in inputArr) {if (inputArr.hasOwnProperty(k)){keys.push(k);}}
        keys.sort(sorter);

        this.php_js = this.php_js || {};
        this.php_js.ini = this.php_js.ini || {};
        strictForIn = this.php_js.ini['phpjs.strictForIn'] && this.php_js.ini['phpjs.strictForIn'].local_value && this.php_js
                .ini['phpjs.strictForIn'].local_value !== 'off';
        populateArr = strictForIn ? inputArr : populateArr;

        for (i = 0; i < keys.length; i++) {
            k = keys[i];
            tmp_arr[k] = inputArr[k];
            if(strictForIn) {delete inputArr[k];}
        }
        for (i in tmp_arr) {if (tmp_arr.hasOwnProperty(i)){populateArr[i] = tmp_arr[i];}}
        return strictForIn || populateArr;
    }
    catch(e){
        console.log('ksort');
        catchError(e);
    }
}

function sort(inputArr, sort_flags) {
    try{
        var valArr = [],
            keyArr = [],
            k = '',
            i = 0,
            sorter = false,
            that = this,
            strictForIn = false,
            populateArr = [];

        switch (sort_flags) {
            case 'SORT_STRING':
                sorter = function(a, b){return that.strnatcmp(a, b);};
                break;
            case 'SORT_LOCALE_STRING':
                var loc = this.i18n_loc_get_default();
                sorter = this.php_js.i18nLocales[loc].sorting;
                break;
            case 'SORT_NUMERIC':
                sorter = function(a, b) {
                    return (a - b);
                };
                break;
            case 'SORT_REGULAR':
            default:
                sorter = function(a, b) {
                    var aFloat = parseFloat(a),
                        bFloat = parseFloat(b),
                        aNumeric = aFloat + '' === a,
                        bNumeric = bFloat + '' === b;
                    if (aNumeric && bNumeric) {
                        return aFloat > bFloat ? 1 : aFloat < bFloat ? -1 : 0;}else if (aNumeric && !bNumeric) {return 1;} else if (!aNumeric && bNumeric) {return -1;}
                    return a > b ? 1 : a < b ? -1 : 0;
                };
                break;
        }
        try {this.php_js = this.php_js || {};} catch (e) {this.php_js = {};}
        this.php_js.ini = this.php_js.ini || {};
        strictForIn = this.php_js.ini['phpjs.strictForIn'] && this.php_js.ini['phpjs.strictForIn'].local_value && this.php_js
                .ini['phpjs.strictForIn'].local_value !== 'off';
        populateArr = strictForIn ? inputArr : populateArr;

        for (k in inputArr) { // Get key and value arrays
            if(inputArr.hasOwnProperty(k)){
                valArr.push(inputArr[k]);
                if (strictForIn) {delete inputArr[k];}
            }
        }
        valArr.sort(sorter);
        for (i = 0; i < valArr.length; i++) {populateArr[i] = valArr[i];}
        return strictForIn || populateArr;
    }
    catch(e){
        console.log('sort');
        catchError(e);
    }
}

function masqueBoutonRisqueSanction(data){
    try{
        if(data.is_signalisation==false){
            $("#menuFourtous li.panneaux").addClass("masque grayscale");
            $("#menuFourtous li.panneaux div").removeClass("link").addClass("nolink");
        }
        if(data.is_risque==false){
            $("#menuFourtous li.risque").addClass("masque grayscale");
            $("#menuFourtous li.risque div").removeClass("link").addClass("nolink");
        }
    }
    catch(e){
        console.log('masqueBoutonRisqueSanction');
        catchError(e);
    }
}

function moduleBtnRisqueSanction(){
    try{
        var objResult = {};
        var idModule = pw3.Page.splitUrl[pw3.Page.splitUrl.length-1].replace("m","");
        var objModule = pw3.Stat.dataStat.stat[idModule];
        objResult.risqueIsVu = objModule.risque;
        objResult.sanction = objModule.sanction;
        objResult.signalisation = objModule.signalisation;
        return objResult;
    }
    catch(e){
        console.log('moduleBtnRisqueSanction');
        catchError(e);
    }
}

function testAsset(id_theme){
    try{
        var tabAsset = pw3.Page.dataAsset.asset;
        var is_risque = false;
        var is_signalisation = false;
        var id_sanction = 0;
        for(var idAsset in tabAsset){
            if(!tabAsset[idAsset].date_suppression) {
                var tabTheme = tabAsset[idAsset]["tabTheme"];
                if (tabTheme) {
                    for(var idTabTheme in tabTheme){
                        if (tabAsset[idAsset].asset_type_id == 7 && tabTheme[idTabTheme].id == id_theme) {is_risque = true;}
                        if (tabAsset[idAsset].asset_type_id == 9 && tabTheme[idTabTheme].id == id_theme) {is_signalisation = true;}
                    }
                }
            }
        }
        var test_asset = {};
        test_asset.is_risque = is_risque;
        test_asset.is_signalisation = is_signalisation;
        test_asset.id_sanction = id_sanction;

        return test_asset;
    }
    catch(e){
        console.log('testAsset');
        catchError(e);
    }
}

function switchSon(){
    try{
        var elemAudio;
        if($('#audioQuestion').length){ elemAudio = $('#audioQuestion').get(0); }
        if($('#sequenceVideo').length){ elemAudio = $("#sequenceVideo").get(0); }

        if(elemAudio.length!==0){
            if(elemAudio.volume==1){
                elemAudio.volume = 0.001;
                $("#audioRelire .link ").text("L").addClass("sonOff");
                $("#audio .link ").text("L").addClass("sonOff");
            }
            else{
                elemAudio.volume = 1;
                $("#audioRelire .link ").text("t").removeClass("sonOff");
                $("#audio .link ").text("t").removeClass("sonOff");
            }
        }
    }
    catch(e){
        console.log('switchSon');
        catchError(e);
    }
}

function masqueCours(){
    try{
        calculeTempsOuvertureCours();
        calculeTempsPauseVideo();
        $("#coursVideo .contenuTxtVideo").css("opacity", 0);
        $("#coursVideo").addClass("repliVolet").removeClass("depliVolet");
        $(".sequence .blackOpacity, .sequence .pauseLayer").removeClass("active");
        $(".sequence .blackOpacity, .sequence .pauseLayer .txt.center").addClass("none");
        $(".sequence #menuVideo .cours div").removeClass("active");
        $(".sequence .blackOpacity").addClass("none");
        $(".sequence .masqueTxt").removeClass("masque");
    }
    catch(e){
        console.log('masqueCours');
        catchError(e);
    }
}

function sous_domaine(){
    try{
        var urlSplit = window.location.host.split(".");
        return urlSplit[0];
    }
    catch(e){
        console.log('sous_domaine');
        catchError(e);
    }
}

function generateToken(){
    try{
        var objData = {grant_type : GRANT_TYPE,client_id : CLIENT_ID, client_secret : CLIENT_SECRET};
        return Q.promise(function (resolve, reject) {
            jQuery.ajax({
                url: URL_TOKEN,
                dataType: "json",
                headers: {"Content-Type": "application/json", "Accept": "application/json"},
                type: 'POST',
                async: false,
                contentType:"application/json; charset=utf-8",
                crossDomain: true,
                data: JSON.stringify(objData)
            }).then(function (data, textStatus, jqXHR) {
                delete jqXHR.then; // treat xhr as a non-promise
                pw3.Page.objToken = data;
                resolve(data);
            }, function (jqXHR, textStatus, errorThrown) {
                delete jqXHR.then; // treat xhr as a non-promise
                reject(jqXHR);
            });
        });
    }
    catch(e){
        console.log('generateToken');
        catchError(e);
    }
}

function apiCall(send, identifiantProduit, data){
    try{
        var deferApiCall = Q.defer();

        if(pw3.Page.objToken){
            loadDataFromServices(send, identifiantProduit, data)
                .then(function(retour){
                        if(retour == "ERROR"){
                            generateToken()
                                .then(function(response){
                                    loadDataFromServices(send, identifiantProduit, data)
                                        .then(
                                            function(retour){
                                                deferApiCall.resolve(retour)
                                            }
                                        );
                                });
                        }else{
                            deferApiCall.resolve(retour)}
                    }
                );

        }else{
            console.log('token pas ok');
            generateToken()
                .then(function(response){
                    loadDataFromServices(send, identifiantProduit, data)
                        .then(
                            function(retour){
                                deferApiCall.resolve(retour)
                            }
                        );
                });
        }

        return deferApiCall.promise;
    }
    catch(e){
        console.log('apiCall');
        catchError(e);
    }
}

function loadDataFromServices(send, identifiantProduit, data){
    try{
        var token_stat = pw3.Page.objToken.state;
        var token_type = pw3.Page.objToken.token_type;
        var token_access = pw3.Page.objToken.access_token;
        var data_to_send;

        if(send=="POST"){data_to_send = JSON.stringify(data);}
        else if(send=="GET"){
            data_to_send = "";
            for (var key in data) {
                if (data_to_send != "") {
                    data_to_send += "&";
                }
                data_to_send += key + "=" + encodeURIComponent(data[key]);
            }
        }

        return Q.promise(function (resolve, reject) {
            jQuery.ajax({
                url : URL_API+identifiantProduit,
                dataType : "json",
                headers: { "Content-Type":"application/json","Accept": "application/json", "x-csrf-token": token_stat, "authorization": token_type+" "+token_access},
                type : send,
                contentType:"application/json; charset=utf-8",
                crossDomain: true,
                async: false,
                data : data_to_send
            }).then(function (data, textStatus, jqXHR) {
                delete jqXHR.then; // treat xhr as a non-promise
                if(textStatus != "success"){
                    resolve("ERROR");
                }else{
                    resolve(data);
                }
            }, function (jqXHR, textStatus, errorThrown) {
                delete jqXHR.then; // treat xhr as a non-promise
                //reject(jqXHR);
                console.log("Token rejected");
                resolve("ERROR");
            });
        });
    }
    catch(e){
        console.log('loadDataFromServices');
        catchError(e);
    }
}

function afficheCours(){
    try{
        calculeTempsOuvertureCours();
        calculeTempsPauseVideo();
        debutOuvertureText = time();
        var sequence = attrUrl();
        var textCours = callData.service.afficheCours(cleanID(sequence[4]));
        $("#contenuTxtVideo").html(textCours);
        $(".sequence .blackOpacity, .sequence .pauseLayer .txt.center").removeClass("none");
        $(".sequence .pauseLayer").removeClass("pause");
        $("#coursVideo").addClass("depliVolet").removeClass("repliVolet none");
        $(".sequence .blackOpacity, .sequence .pauseLayer").addClass("active");
        $("#coursVideo .contenuTxtVideo").css("opacity", 1);
        $(".sequence #menuVideo .cours div").addClass("active");
        $(".sequence .blackOpacity").removeClass("none");
        $(".sequence .masqueTxt").addClass("masque");
        $(".scroller").mCustomScrollbar();
    }
    catch(e){
        console.log('afficheCours');
        catchError(e);
    }
}

function videoPause(){
    try{
        calculeTempsOuvertureCours();
        calculeTempsPauseVideo();
        debutPause = time();
        $(".sequence .blackOpacity, .sequence .pauseLayer").addClass("active");
        $(".sequence .blackOpacity").removeClass("none");
        $("#menuVideo #btnPlay").addClass("playPause").removeClass("pause");
        $("#menuVideo #btnPlay .link").text("r");
        if( $('#sequenceVideo').length ){
            $("#sequenceVideo").get(0).pause();
        }
    }
    catch(e){
        console.log('videoPause');
        catchError(e);
    }
}

function inspectSon(){
    try{
        var elemAudio;
        if($('#audioQuestion').length){elemAudio = $('#audioQuestion').get(0);}
        if($("#sequenceVideo").length){elemAudio = $("#sequenceVideo").get(0);}
        if((elemAudio)&&(elemAudio.length!==0)){
            if(($("#audio .link ").text() == "L")||($("#audio .link").text() == "LL")||$("#audio .link").hasClass("sonOff")){
                elemAudio.volume = 0.001;
            }else{elemAudio.volume = 1;}
        }
    }
    catch(e){
        console.log('inspectSon');
        catchError(e);
    }
}

function chargementCoursVideo(){
    try{
        var elmVideo = $("#sequenceVideo");
        if(elmVideo.length){
            elmVideo.get(0).load();
            videoPlay();
        }
    }
    catch(e){
        console.log('chargementCoursVideo');
        catchError(e);
    }
}

function calculeTempsPauseVideo(){
    try{
        if (debutPause !== 0){
            var finPause = time();
            var resultTempPause = Math.round(finPause - debutPause);
            tempsPause += resultTempPause;
            debutPause = 0;
        }
    }
    catch(e){
        console.log('calculeTempsPauseVideo');
        catchError(e);
    }
}

function calculeTempsOuvertureCours(){
    try{
        if (debutOuvertureText !== 0){
            var finOuvertureText = time();
            var resultTempOuverture = Math.round(finOuvertureText - debutOuvertureText);
            tempsOuvertureText += (resultTempOuverture*1);
            debutOuvertureText = 0;
        }
    }
    catch(e){
        console.log('calculeTempsOuvertureCours');
        catchError(e);
    }
}

function etatSonVideo(result, media){
    try{media.muted = result;}
    catch(e){catchError(e);}
}

function videoPlay() {
    try{
        calculeTempsOuvertureCours();
        calculeTempsPauseVideo();
        $(".sequence .pauseLayer").removeClass("pause");
        $("#btnPlay").addClass("pause").removeClass("playPause");
        $("#btnPlay .link.fontEdiser").removeClass("active");
        $("#menuVideo .cours .link.fontEdiser").removeClass("active");
        $("#sequenceVideo").get(0).play();
        $("#menuVideo #btnPlay .link").text("s");
        $("#timeBarre .puceLecture").css("width", $("#timeBarre .puceLecture").css("height"));
    }
    catch(e){
        console.log('videoPlay');
        catchError(e);
    }
}

function initVideo(){
    try{
        if($("#sequenceVideo").get(0)){
            var video = $("#sequenceVideo").get(0);

            video.addEventListener("timeupdate", function() {
                // Calculate the slider value
                var value = (100 / video.duration) * video.currentTime;
                $("#timeBarre .progressBarre").css("width", value+"%");
            });
            dragPuceLectureVideo(video);
        }
    }
    catch(e){
        console.log('initVideo');
        catchError(e);
    }
}

function dragPuceLectureVideo(videoC){
    try{
        $("#timeBarre .puceLecture").draggable({
            start: function() {

                $(".sequence .pauseLayer").addClass("pause");
            },
            drag: function(event, ui) {
                var currentPos = ui.offset.left;
                var timeBarreBg = $("#timeBarre .bgBarre");
                var maposition = currentPos-timeBarreBg.offset().left;
                var amplitude = (timeBarreBg.offset().left+timeBarreBg.width())-(timeBarreBg.offset().left);
                var value = (maposition*100)/amplitude;
                $("#timeBarre .progressBarre").css("width", value+"%");
                videoC.currentTime = videoC.duration * (value / 100);
            },
            stop: function() {
                videoPlay();
            },
            axis: "x",
            cursor: "pointer",
            containment: "#bgProgressBarre"
        });
    }
    catch(e){
        console.log('dragPuceLectureVideo');
        catchError(e);
    }
}

function time() {
    try{return Math.floor(new Date().getTime() / 1000);}
    catch(e){
        console.log('time');
        catchError(e);
    }
}

function examenSendToServer(TestCorrection){
    try{
        if((!pw3.Examens.oTestEnCours.SendToServer)||(pw3.Examens.oTestEnCours.SendToServer === false)) {
            var nbFautes = 0;
            var Reussite = TestCorrection.Reussite;

            for (var question in TestCorrection.Question) {
                if (TestCorrection.Question[question].Etat == 0) {
                    nbFautes++;
                }
            }
            TestCorrection.NbFautes = nbFautes;

            TestCorrection.Duree = Math.floor(returnTimeStamp() / 1000) - TestCorrection.Date;
            TestCorrection.EtatReussite = ( nbFautes <= Reussite ? 1 : 0 );

            //**************************************
            //**************************************
            //** ASTUCE : Pour être compatible avec les attentes du service SOAP, on clone l'objet TestCorrection dans soapResultToSend
            //** L'enveloppe attendue par le service SOAP nécessite un niveau supplémentaire dans l'objet Question (ajout d'un tableau d'item)
            //**************************************
            //**************************************
            var soapResultToSend = JSON.parse(JSON.stringify(TestCorrection));
            soapResultToSend.Question = {};
            var item = [];
            for (element in TestCorrection.Question) {
                item.push(TestCorrection.Question[element]);
            }
            soapResultToSend.Question.item = item;
            //Pour assurer la compatibilité avec les anciens test
            soapResultToSend.NumTest = (soapResultToSend.NumTest == ID_PRODUIT_TEST_ALEATOIRE ? 0 : soapResultToSend.NumTest );
            soapResultToSend.Type = (TestCorrection.Type == "Thematique" ? "Thematique_PW3" : TestCorrection.Type)

            var dataObj = {
                login: EDITEUR,
                password: APIKEY,
                id_eleve: numEleve,
                num_stat_test: TestCorrection.NumStatTest,
                tab_result: soapResultToSend,
                type_test: soapResultToSend.Type
                //debug : 1
            };

            var objSaveTest = new CallObj("saveTest", PHP_STATS, dataObj, "updateStatsExamen");
            var saveTest = objSaveTest.getObj();

            var items = [];

            for (element in TestCorrection.Question) {
                var item = [];
                item.push(TestCorrection.Question[element].NumQuestion);
                item.push(TestCorrection.Question[element].Etat);
                item.push(TestCorrection.Question[element].Reponse);
                items.push(item);
            }

            //On va mettre à jour les données du sessionStorage avec les données du test fraichement réalisé
            if (TestCorrection.NumTest && (parseInt(TestCorrection.NumTest) != ID_PRODUIT_TEST_ALEATOIRE && parseInt(TestCorrection.NumTest) != 0)) {
                //Dans le cas ou on est sur un test thématique
                var id_formation = cleanID($(elementThis).attr('id'));
                initStatsValuesInStorage(pw3.Page.obQuiz, "quiz", items, TestCorrection.Duree, TestCorrection.NbFautes, TestCorrection.EtatReussite, id_formation);
                pw3.Stat.setStatPW3ThematiqueCache(testAsset(pw3.Page.themeId, pw3.Page.dataAsset), id_formation, TestCorrection.EtatReussite, TestCorrection.Duree, TestCorrection.Duree, "sous_theme");

            }
            else {
                //Dans le cas d'un test examen
                initStatsValuesInStorage(pw3.Examens.obExamen, "examen", items, TestCorrection.Duree, TestCorrection.NbFautes, TestCorrection.EtatReussite);
                updateExamInCache();
            }

            var nbTestRealise = pw3.Examens.obExamen.liste.length;

            if ((nbTestRealise > pw3.Droit.obDroit.nbMaxTest) && (pw3.Droit.obDroit.nbMaxTest != 0) && (TestCorrection.NumTest == 0 || TestCorrection.NumTest == ID_PRODUIT_TEST_ALEATOIRE)) {
                pw3.Droit.obDroit.Tests = 0;
                pw3.Droit.obDroit.IsTestLimitAtteint = 1;
                var objSaveObDroit = new CallObj("saveTest", "", pw3.Droit.obDroit, "sessionStorage", "odroit");
                objSaveObDroit.getObj();
            }

            pw3.Examens.oTestEnCours.SendToServer = true;
        }
    }
    catch(e){
        console.log('examenSendToServer');
        catchError(e);
    }
}

function updateExamInCache(){
    try{
        var unique_id_questions = [];
        var duree               = 0;
        var nbFautes            = 0;
        var nbQuestions         = 0;

        var nbFautesDuJour       = 0;
        var nbTestsDuJour       = 0;

        var today = moment().format("YYYYMMDD");

        for (indice in pw3.Examens.obExamen.liste){
            if(pw3.Examens.obExamen.liste[indice].Termine == "1"){
                for(indice2 in pw3.Examens.obExamen.liste[indice].StatQuestion){
                    var id_question = pw3.Examens.obExamen.liste[indice].StatQuestion[indice2][0];
                    //Pour avoir des questions uniques
                    if (unique_id_questions.indexOf(id_question) == -1) unique_id_questions.push(id_question);
                }
                if(pw3.Examens.obExamen.liste[indice].Date.indexOf(today)){
                    nbFautesDuJour += parseInt(pw3.Examens.obExamen.liste[indice].NbFautes);
                    nbTestsDuJour++;
                }
                duree       += parseInt(pw3.Examens.obExamen.liste[indice].TempsConnexion);
                nbFautes    += parseInt(pw3.Examens.obExamen.liste[indice].NbFautes);
                nbQuestions += pw3.Examens.obExamen.liste[indice].StatQuestion.length;
            }
        }

        pw3.Examens.obExamen.question   = unique_id_questions.length;
        pw3.Examens.obExamen.duree      = duree;
        pw3.Examens.obExamen.date       = (pw3.Examens.obExamen.date ? pw3.Examens.obExamen.date : moment().locale("fr").format('L'));
        pw3.Examens.obExamen.moyenne    = (nbQuestions > 0 ? round( ( ( nbQuestions - nbFautes) * 100) / nbQuestions ) : 0);

        //On met à jour le cache des statistiques dans le session storage
        var update = new CallObj("", "", pw3.Examens.obExamen, "update", "examen");
        update.getObj();

        //On met à jour le graphique
        var objCourbe = new CallObj("getCourbe", "", "", "sessionStorage", "courbe");
        var obCourbe = objCourbe.getObj();

        if(jQuery.isEmptyObject(obCourbe)){
            var dataObj = {login: EDITEUR, password: APIKEY, id_eleve: pw3.Page.obEleve.NumEleve};
            objCourbe = new CallObj("getCourbe", PHP_STATS, dataObj, "sessionStorage", "courbe");
            obCourbe = objCourbe.getObj();
        }

        var today = moment().locale("fr").format('L');
        var moyenneDuJour = (nbFautesDuJour > 0 ? round( ( ( nbTestsDuJour - nbFautesDuJour) * 100) / nbTestsDuJour ) : 0);

        if(obCourbe.PW){
            obCourbe.PW[today] = moyenneDuJour;
            var update = new CallObj("", "", obCourbe, "update", "courbe");
            update.getObj();
        }
    }
    catch(e){
        console.log('updateExamInCache');
        catchError(e);
    }
}

function initStatsValuesInStorage(data, cacheToUpdate, questions, tempsConnexion, nbFautes, etatReussite, idFormation ){
    try{
        var element = data.liste[data.liste.length -1];
        element.StatQuestion = questions;
        element.TempsConnexion =  tempsConnexion;
        element.Termine = "1";
        element.NbFautes = nbFautes;
        element.Reussi = etatReussite;
        if(idFormation){
            element.id_formation = idFormation;
        }
        var update = new CallObj("", "", data, "update", cacheToUpdate);
        update.getObj();
    }
    catch(e){catchError(e);}
}

function catchError(e){
    console.log(e);
}

function getQueryString(){
    try{
        var url = window.location.href;
        var queryString = url.split("/?");
        var arrayQueryString = [];
        if(queryString[1]){
            var listStringAndValue = queryString[1].split("&");
            for(idListStringAndValue in listStringAndValue){
                var arrayQuerys = listStringAndValue[idListStringAndValue].split("=");
                var objQueryString = {};
                objQueryString.name = arrayQuerys[0];
                objQueryString.value = arrayQuerys[1];
                arrayQueryString.push(objQueryString);
            }
        }
        return arrayQueryString;
    }
    catch(e){catchError(e);}
}

function funcTypeCompression(){
    try{
        var string = " 1234567890)°=+azertyuiop^$qsdfghjklmù*wxcvbn,;:!/hjkhjkjkhjk @ààj!%;#{[| ";

        if(navigator.userAgent.indexOf("Chrome") > -1 && navigator.userAgent.indexOf("Edge") == -1 ){
            lzStringCompressionType = "compress";
            lzStringDecompressionType = "decompress"
        }else{
            lzStringCompressionType = "compressToEncodedURIComponent";
            lzStringDecompressionType = "decompressFromEncodedURIComponent";
        }

        sessionStorage.setItem("SS_test", eval("LZString."+lzStringCompressionType)(string));
        sessionStorage.removeItem("SS_test");
    }
    catch(e){
        catchError(e);

        lzStringCompressionType = "compressToEncodedURIComponent";
        lzStringDecompressionType = "decompressFromEncodedURIComponent";

        cantReadLocalStorage++;
        if(cantReadLocalStorage == 1){
            sessionStorage.clear();
            localStorage.clear();
            funcTypeCompression();
        }
    }
}

function lzStringTypeCompress(){
    try{
        funcTypeCompression();

        var sousDomaine = sous_domaine();
        var dataObj = {};
        var ObjectAgence = {};
        var agence;
        //si il n'y a pas de sous domaine je force le test sur le sous domaine de demo
        if(!sousDomaine){sousDomaine = "demo";}
        //je met le retour soap dans une variable pour essayer de le stocker dans le session storage.
        dataObj = {login: EDITEUR, password: APIKEY, sous_domaine: sousDomaine};
        ObjectAgence= new CallObj("getAutoEcole", PHP_CONNECT, dataObj, "setObjInVar", "autoecole");
        agence =  ObjectAgence.getObj();
        if(login_password === false){
            pw3.Page.obEleve.Agence = agence;
            pw3.Page.agence = agence;
            numAutoEcole = pw3.Page.obEleve.Agence.NumAutoEcole;
        }
        if (cantReadLocalStorage < 2) {
            if(login_password === true){
                sessionStorage.setItem("SS_autoecole1", eval("LZString." + lzStringCompressionType)(JSON.stringify(pw3.Page.obAgence)));
            }
            else{
                sessionStorage.setItem("SS_autoecole1", eval("LZString." + lzStringCompressionType)(JSON.stringify(agence)));
            }
        }
    }
    catch(e){catchError(e);}
}

function completeAgenceInfo(){
    try{
        if(pw3.Page.Agence){
            $("#agenceSIRET").html( (pw3.Page.Agence.NoSiret == ""                          ? 'Inconnu ' : pw3.Page.Agence.NoSiret));
            $("#agenceTVA").text(   (pw3.Page.Agence.NoTVA == ""                            ? '- ' : pw3.Page.Agence.NoTVA));
            $("#agenceAgrement").text( (pw3.Page.Agence.NoAgrement == ""                    ? 'Inconnu ' : pw3.Page.Agence.NoAgrement));
            $("#infoLegaleSIRET").text( (pw3.Page.Agence.NoSiret == ""                      ? '- ' : pw3.Page.Agence.NoSiret));
            $("#infoLegaleTVA").text(   (pw3.Page.Agence.NoTVA == ""                        ? 'Inconnu ' : pw3.Page.Agence.NoTVA));
            $("#infoLegaleCapitalSocial").text( (pw3.Page.Agence.capitalSocial== ""         ? 'Inconnu ' : pw3.Page.Agence.capitalSocial+' €'));
            $("#infoLegaleAutoriteAgrement").text( (pw3.Page.Agence.AutoriteAgrement==null  ? 'Inconnu ': pw3.Page.Agence.AutoriteAgrement));
            $("#infoLegalePrefectoralAgrement").text( (pw3.Page.Agence.NoAgrement == ''     ? '- ' : pw3.Page.Agence.NoAgrement +' du '+ date("d M Y", strtotime(pw3.Page.Agence.DateAgrement)) ) );
            $("#infoLegaleProprietaire").text( pw3.Page.Agence.RaisonSociale+'<br/>'+pw3.Page.agence.Adresse+'<br/>'+pw3.Page.Agence.CodePostal+' '+pw3.Page.Agence.Ville+'<br/>'+pw3.Page.Agence.Telephone+'<br/>');
        }
    }
    catch(e){catchError(e);}
}

function initContenuAjax(){
    try{
        $("#contenuAjax").empty();
        $("#contenuAjax").html('<div class="center loadQuestion bold">Test en cours de chargement...</div>');
    }
    catch(e){catchError(e);}
}

function isEmpty(obj) {
    try{
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    catch(e){catchError(e);}
}

function CallObj(nameFonct, url, jsonData, storage, nameElement , service){
    try{
        var jsonSetLocalStorage;
        this.nameFonct = nameFonct;
        this.url       = url;
        this.jsonData  = jsonData;
        this.storage  = storage;
        this.service = service;
        this.storagenamePrefixe = "SS_";
        if((this.storage=="localStorage")||(this.storage=="forceGetLocalStorage")||(this.storage=="getFormationLocalStorage")){this.storagenamePrefixe = "LS_";}
        this.nameElement = nameElement;
        this.jsonEncode = true;
        this.chunk = 900000;

        if((this.service!==undefined)&&(this.service!=="")){this.storagenamePrefixe += this.service+"_";}

        this.getObj = function(){
            switch (this.storage){
                case "setObjInVar" :
                    var obj = this.setObjInVar();
                    return JSON.parse(obj);

                case "updateStatsExamen" :
                case "setLogOut" :
                    this.updateStatsExamen();
                    return;

                case "temporaire" :
                    var setObjInVar = this.setObjInVar();
                    return setObjInVar;

                case "updateEleve" :
                    this.storage = "sessionStorage";
                    this.jsonData = this.setEleve();
                    this.jsonData = this.updateEleve();
                    return JSON.parse(this.jsonData);

                case "forceGetSessionStorage" :
                    this.storage = "sessionStorage";
                    var retourData = "";
                    var retourSoap = this.setObj();
                    if(this.jsonData!="" && retourSoap  && retourSoap != ""){retourData = JSON.parse(retourSoap);}
                    return retourData;

                case "forceGetLocalStorage" :
                    this.storage = "localStorage";
                    var retourData = "";
                    var retourSoap = this.setObj();
                    if(this.jsonData!="" && retourSoap  && retourSoap != ""){retourData = JSON.parse(retourSoap);}
                    return retourData;

                case "updateStatsFormation" :
                case "updateDroit" :
                case "updateReponseTest" :
                case "update" :
                    var update = this.updateStorage(true);
                    return update;
            }

            try {
                var getItemDeCompress="";
                var i = 1;
                var getItem = "";
                var elementStored = eval(this.storage).getItem(this.storagenamePrefixe+this.nameElement+i);
                if(elementStored){
                    do {
                        getItem += elementStored;
                        getItemDeCompress += eval("LZString."+lzStringDecompressionType)(elementStored );
                        i++;

                        if(eval(this.storage).getItem(this.storagenamePrefixe + this.nameElement + i)){
                            elementStored = eval(this.storage).getItem(this.storagenamePrefixe + this.nameElement + i);
                        }
                        else{
                            elementStored = false;
                        }
                    }while(elementStored);
                }

                if(((getItemDeCompress == "")||(getItemDeCompress===null))&&(this.jsonData!="")) {
                    if(this.url == ""){getItemDeCompress = this.SavObj();}
                    else{getItemDeCompress = this.setObj();}
                }

                getItemDeCompress = (getItemDeCompress == "" ? "{}" : getItemDeCompress);

                return JSON.parse(getItemDeCompress);
            }
            catch(err){
                localStorage.clear();
                sessionStorage.clear();
                AfficheAccueil();
            }
        };
        this.getFormationLocalStorage = function(){
            this.storage = "localStorage";
            this.SavObj();
            return this.jsonData;
        };
        this.updateEleve = function(){
            this.storage = "sessionStorage";
            this.jsonEncode = false;
            this.SavObj();
            return this.jsonData;
        };
        this.updateDroit = function(){
            this.storage = "sessionStorage";
            this.jsonEncode = true;
            this.SavObj();
            return this.jsonData;
        };
        this.updateStorage= function(jsonEncode){
            this.storage = "sessionStorage";
            this.jsonEncode = jsonEncode;
            this.SavObj();
            return this.jsonData;
        };
        this.updateStatsExamen = function(){
            this.callsoap(true);
        };
        this.setObjInVar = function(){
            this.callsoap();
            this.nameElement = JSON.stringify({});
            if(jsonSetLocalStorage){
                var setInLocalStorage = jsonSetLocalStorage.content.childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes;
                for(var idFormation in setInLocalStorage){
                    if(typeof(setInLocalStorage[idFormation])=="object"){
                        this.nameElement = setInLocalStorage[idFormation].childNodes[1].textContent;
                    }
                }
            }

            return this.nameElement;
        };
        this.getFormation = function(){

        };
        this.setEleve = function(){
            this.callsoap();
            var setInLocalStorage = jsonSetLocalStorage.content.childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes;

            var contentCompressConctat = "";

            for(var idFormation in setInLocalStorage){
                if(typeof(setInLocalStorage[idFormation])=="object") {
                    var setEleve = JSON.parse(setInLocalStorage[idFormation].childNodes[1].textContent);

                    for(var idSetEleve in setEleve){pw3.Page.obEleve[idSetEleve] = setEleve[idSetEleve];}

                    this.nameElement = setInLocalStorage[idFormation].childNodes[0].textContent;
                    var curseur = 0;
                    var i=1;
                    do{
                        var contentResult = JSON.stringify(pw3.Page.obEleve).substr(curseur, this.chunk);
                        eval(this.storage).setItem(this.storagenamePrefixe + this.nameElement + i, eval("LZString."+lzStringCompressionType)(contentResult));
                        curseur += this.chunk;
                        contentCompressConctat += contentResult;
                        i++;
                    }while(JSON.stringify(pw3.Page.obEleve).substr(curseur,this.chunk));
                }
            }
            return contentCompressConctat;
        };
        this.setObj = function(){
            this.callsoap();
            var setInLocalStorage = jsonSetLocalStorage.content.childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes;
            for(var idFormation in setInLocalStorage){
                if(typeof(setInLocalStorage[idFormation])=="object"){
                    this.nameElement = setInLocalStorage[idFormation].childNodes[0].textContent;
                    var contentCompressConctat = "";
                    var curseur = 0;
                    var i=1;
                    var content = setInLocalStorage[idFormation].childNodes[1].textContent;
                    var contentCompress = content.substr(curseur, this.chunk);
                    do{
                        eval(this.storage).setItem(this.storagenamePrefixe+this.nameElement+i, eval("LZString."+lzStringCompressionType)(contentCompress));
                        curseur += this.chunk;
                        //Spécifique à la formation
                        contentCompressConctat += contentCompress;
                        i++;
                        contentCompress = content.substr(curseur, this.chunk);
                    }while(contentCompress);
                }
            }
            return contentCompressConctat;
        };
        this.callsoap = function(async){
            jsonSetLocalStorage = null;
            if(!async){async = false;}
            $.soap({
                url: this.url,
                method: this.nameFonct,
                appendMethodToURL: false,
                data: this.jsonData,
                async:async,
                xhrFields: {
                    withCredentials: true
                },
                success: function(soapResponse) {
                    jsonSetLocalStorage = soapResponse;
                },
                error:function(e){catchError(e);}
            });
        };
        this.callElement = function(async){
            jsonSetLocalStorage = null;
            if(!async){async = false;}
            $.soap({
                url: this.url,
                method: this.nameFonct,
                appendMethodToURL: false,
                data: this.jsonData,
                async:async,
                success: function(soapResponse) {
                    jsonSetLocalStorage = soapResponse;
                    return jsonSetLocalStorage;
                }
            });
        };
        this.SavObj = function(){
            if(this.jsonData!=""){
                var curseur = 0;
                var i=1;
                var content = this.jsonData;
                var contentCompress = "";
                var contentChunk = "";
                if(this.jsonEncode === true){content = JSON.stringify(content);}
                contentChunk = content.substr(curseur, this.chunk);
                do{
                    contentCompress = eval("LZString."+lzStringCompressionType)(contentChunk);
                    eval(this.storage).setItem(this.storagenamePrefixe + this.nameElement + i, contentCompress);
                    curseur += this.chunk;
                    i++;
                    contentChunk = content.substr(curseur,this.chunk);

                }while(contentChunk);

                var contentConcat = content;

                return contentConcat;
            }
        }
    }
    catch(e){catchError(e);}
}

function debugPw(){
    try{
        if(sessionStorage.getItem("SS_modeDebug") === null){
            sessionStorage.setItem("SS_modeDebug", false);
        }
        pw3.Page.debug = sessionStorage.getItem("SS_modeDebug");
    }
    catch(e){catchError(e);}
}

function funcFiltreId(tblId, type, idForme, idColor){
    try{
        var arr = [];
        for(var idResult in tblId){
            if(idForme){
                if((idForme==tblId[idResult]["AssetMeta"]["signalisation_forme_id"])&&(!idColor)){
                    arr.push(tblId[idResult]["AssetMeta"][type]);
                }
                if(idColor){
                    if((idForme==tblId[idResult]["AssetMeta"]["signalisation_forme_id"])&&(idColor==tblId[idResult]["AssetMeta"]["signalisation_couleur_id"])){
                        arr.push(tblId[idResult]);
                    }
                }
            }else{
                arr.push(tblId[idResult]["AssetMeta"][type]);
            }
        }
        if(idColor){
            return arr;
        }
        return array_unique(arr);
    }
    catch(e){catchError(e);}
}

function arraySignalisation(assetType, themeId, dataAsset){
    try{
        var arrayResult = [];
        for(var idAsset in dataAsset.asset){
            if(dataAsset.asset[idAsset].asset_type_id===assetType){
                var tabTheme = dataAsset.asset[idAsset].tabTheme;
                for(var idTheme in tabTheme){
                    if(idTheme===themeId){
                        arrayResult.push(dataAsset.asset[idAsset]);
                    }
                }
            }
        }
        return arrayResult;
    }
    catch(e){catchError(e);}
}

function in_array(keySearch, arraySearch) {
    try{
        for(var idArray in arraySearch) {
            if(arraySearch[idArray] == keySearch) return true;
        }
        return false;
    }
    catch(e){catchError(e);}
}

function getTreeFormation(formation, idCours, idModule, idPartie, idSequence) {
    try{
        var result = formation["tabChild"][idCours];

        if (idSequence) {
            result = formation["tabChild"][idCours]["tabChild"][idModule]["tabChild"][idPartie]["tabChild"][idSequence];
        }else if(idPartie){
            result = formation["tabChild"][idCours]["tabChild"][idModule]["tabChild"][idPartie];
        }else if(idModule){
            result = formation["tabChild"][idCours]["tabChild"][idModule];
        }
        return result;
    }
    catch(e){catchError(e);}
}

function reinitListePanneauEtDetail(){
    try{
        $(".generalPanneaux .coteGauche").html("");
        $(".generalPanneaux .coteDroit .detailPanneaux").html("").attr("style", "");
    }
    catch(e){catchError(e);}
}

function cleanID(strId){
    try{
        var result;
        if(strId){result= strId.substring(1);}
        return result;
    }
    catch(e){catchError(e);}
}

function ObjectLength(object) {
    try{
        var length = 0;
        for( var key in object ) {
            if(object.hasOwnProperty(key)){++length;}
        }
        return length;
    }
    catch(e){catchError(e);}
}

function attrUrl(newParam){
    try{
        var newParamLenght = 0;
        var arraySplit;
        var locationHrefSplit = "";

        if(typeof(locationHref)==="object"){
            locationHrefSplit = locationHref;
        }
        else if(locationHref){
            locationHrefSplit = locationHref.split("-");
        }


        if((newParam) && (newParam!="")){
            newParamLenght = newParam.split("-").length;
            var newParamSplit = newParam.split("-");
        }

        //si newParam = plusieur paramètres comme pour le sommaire ou le pourquoi de la rêgle
        if(newParamLenght>=2){arraySplit = newParamSplit;}
        else{arraySplit = locationHrefSplit;}

        return arraySplit;
    }
    catch(e){catchError(e);}
}

function array_unique(inputArr) {
    try{
        var key = '',
            tmp_arr2 = {},
            val = '';

        var __array_search = function(needle, haystack) {
            var fkey = '';
            for (fkey in haystack) {
                if (haystack.hasOwnProperty(fkey)) {
                    if ((haystack[fkey] + '') === (needle + '')) {return fkey;}
                }
            }
            return false;
        };

        for (key in inputArr) {
            if (inputArr.hasOwnProperty(key)) {
                val = inputArr[key];
                if (false === __array_search(val, tmp_arr2)) {tmp_arr2[key] = val;}
            }
        }

        return tmp_arr2;
    }
    catch(e){catchError(e);}
}

function sha1(str) {
    try{
        var rotate_left = function(n, s) {
            var t4 = (n << s) | (n >>> (32 - s));
            return t4;
        };

        var cvt_hex = function(val) {
            var str = '';
            var i;
            var v;
            for (i = 7; i >= 0; i--) {
                v = (val >>> (i * 4)) & 0x0f;
                str += v.toString(16);
            }
            return str;
        };

        var blockstart;
        var i, j;
        var W = new Array(80);
        var H0 = 0x67452301;
        var H1 = 0xEFCDAB89;
        var H2 = 0x98BADCFE;
        var H3 = 0x10325476;
        var H4 = 0xC3D2E1F0;
        var A, B, C, D, E;
        var temp;

        str = this.utf8_encode(str);
        var str_len = str.length;

        var word_array = [];
        for (i = 0; i < str_len - 3; i += 4) {
            j = str.charCodeAt(i) << 24 | str.charCodeAt(i + 1) << 16 | str.charCodeAt(i + 2) << 8 | str.charCodeAt(i + 3);
            word_array.push(j);
        }

        switch (str_len % 4) {
            case 0:
                i = 0x080000000;
                break;
            case 1:
                i = str.charCodeAt(str_len - 1) << 24 | 0x0800000;
                break;
            case 2:
                i = str.charCodeAt(str_len - 2) << 24 | str.charCodeAt(str_len - 1) << 16 | 0x08000;
                break;
            case 3:
                i = str.charCodeAt(str_len - 3) << 24 | str.charCodeAt(str_len - 2) << 16 | str.charCodeAt(str_len - 1) <<
                    8 | 0x80;
                break;
        }
        word_array.push(i);
        while ((word_array.length % 16) != 14){word_array.push(0);}

        word_array.push(str_len >>> 29);
        word_array.push((str_len << 3) & 0x0ffffffff);

        for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
            for (i = 0; i < 16; i++) {
                W[i] = word_array[blockstart + i];
            }
            for (i = 16; i <= 79; i++) {
                W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
            }
            A = H0;
            B = H1;
            C = H2;
            D = H3;
            E = H4;
            for (i = 0; i <= 19; i++) {
                temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            for (i = 20; i <= 39; i++) {
                temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            for (i = 40; i <= 59; i++) {
                temp = (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            for (i = 60; i <= 79; i++) {
                temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
                E = D;
                D = C;
                C = rotate_left(B, 30);
                B = A;
                A = temp;
            }
            H0 = (H0 + A) & 0x0ffffffff;
            H1 = (H1 + B) & 0x0ffffffff;
            H2 = (H2 + C) & 0x0ffffffff;
            H3 = (H3 + D) & 0x0ffffffff;
            H4 = (H4 + E) & 0x0ffffffff;
        }
        temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
        return temp.toLowerCase();
    }
    catch(e){catchError(e);}
}

function uniqid(prefix, more_entropy) {
    try{
        if(typeof prefix === 'undefined'){prefix = '';}
        var retId;
        var formatSeed = function(seed, reqWidth) {
            seed = parseInt(seed, 10).toString(16);
            if (reqWidth < seed.length) {return seed.slice(seed.length - reqWidth);}
            if (reqWidth > seed.length) {return Array(1 + (reqWidth - seed.length)).join('0') + seed;}
            return seed;
        };
        if(!this.php_js) {this.php_js = {};}
        if(!this.php_js.uniqidSeed) {this.php_js.uniqidSeed = Math.floor(Math.random() * 0x75bcd15);}
        this.php_js.uniqidSeed++;
        retId = prefix;
        retId += formatSeed(parseInt(new Date().getTime() / 1000, 10), 8);
        retId += formatSeed(this.php_js.uniqidSeed, 5);
        if(more_entropy) {retId += (Math.random() * 10).toFixed(8).toString();}
        return retId;
    }
    catch(e){catchError(e);}
}

function rand(min, max) {
    try{
        var argc = arguments.length;
        if (argc === 0) {
            min = 0;
            max = 2147483647;
        } else if (argc === 1) {
            throw new Error('Warning: rand() expects exactly 2 parameters, 1 given');
        }
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    catch(e){catchError(e);}
}

function utf8_encode(argString) {
    try{
        if (argString === null || typeof argString === 'undefined') {return '';}
        var string = (argString + '');
        var utftext = '', start, end, stringl = 0;
        start = end = 0;
        stringl = string.length;
        for (var n = 0; n < stringl; n++) {
            var c1 = string.charCodeAt(n);
            var enc = null;

            if (c1 < 128) {
                end++;
            } else if (c1 > 127 && c1 < 2048) {
                enc = String.fromCharCode(
                    (c1 >> 6) | 192, (c1 & 63) | 128
                );
            } else if ((c1 & 0xF800) != 0xD800) {
                enc = String.fromCharCode(
                    (c1 >> 12) | 224, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128
                );
            } else { // surrogate pairs
                if ((c1 & 0xFC00) != 0xD800) {
                    throw new RangeError('Unmatched trail surrogate at ' + n);
                }
                var c2 = string.charCodeAt(++n);
                if ((c2 & 0xFC00) != 0xDC00) {
                    throw new RangeError('Unmatched lead surrogate at ' + (n - 1));
                }
                c1 = ((c1 & 0x3FF) << 10) + (c2 & 0x3FF) + 0x10000;
                enc = String.fromCharCode(
                    (c1 >> 18) | 240, ((c1 >> 12) & 63) | 128, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128
                );
            }
            if (enc !== null) {
                if (end > start){utftext += string.slice(start, end);}
                utftext += enc;
                start = end = n + 1;
            }
        }
        if (end > start){utftext += string.slice(start, stringl);}
        return utftext;
    }
    catch(e){catchError(e);}
}

function returnTimeStamp(){
    try{return new Date().getTime();}
    catch(e){catchError(e);}
}

function date(format, timestamp) {
    try{
        var that = this;
        var jsdate, f;
        var txt_words = [
            'Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur',
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        var formatChr = /\\?(.?)/gi;
        var formatChrCb = function(t, s) {return f[t] ? f[t]() : s;};
        var _pad = function(n, c) {
            n = String(n);
            while (n.length < c) {n = '0' + n;}
            return n;
        };
        f = {
            d: function() {return _pad(f.j(), 2);},
            D: function() {return f.l().slice(0, 3);},
            j: function() {return jsdate.getDate();},
            l: function() {return txt_words[f.w()] + 'day';},
            N: function() {return f.w() || 7;},
            S: function() {
                var j = f.j();
                var i = j % 10;
                if (i <= 3 && parseInt((j % 100) / 10, 10) == 1) {i = 0;}
                return ['st', 'nd', 'rd'][i - 1] || 'th';
            },
            w: function() {return jsdate.getDay();},
            z: function() { // Day of year; 0..365
                var a = new Date(f.Y(), f.n() - 1, f.j());
                var b = new Date(f.Y(), 0, 1);
                return Math.round((a - b) / 864e5);
            },
            W: function() {
                var a = new Date(f.Y(), f.n() - 1, f.j() - f.N() + 3);
                var b = new Date(a.getFullYear(), 0, 4);
                return _pad(1 + Math.round((a - b) / 864e5 / 7), 2);
            },
            F: function() {return txt_words[6 + f.n()];},
            m: function() {return _pad(f.n(), 2);},
            M: function() {return f.F().slice(0, 3);},
            n: function() {return jsdate.getMonth() + 1;},
            t: function() {return (new Date(f.Y(), f.n(), 0)).getDate();},
            L: function() {
                var j = f.Y();
                return j % 4 === 0 & j % 100 !== 0 | j % 400 === 0;
            },
            o: function() {
                var n = f.n();
                var W = f.W();
                var Y = f.Y();
                return Y + (n === 12 && W < 9 ? 1 : n === 1 && W > 9 ? -1 : 0);
            },
            Y: function() {return jsdate.getFullYear();},
            y: function() {return f.Y().toString().slice(-2);},
            a: function() {return jsdate.getHours() > 11 ? 'pm' : 'am';},
            A: function() {return f.a().toUpperCase();},
            B: function() {
                var H = jsdate.getUTCHours() * 36e2;
                var i = jsdate.getUTCMinutes() * 60;
                var s = jsdate.getUTCSeconds();
                return _pad(Math.floor((H + i + s + 36e2) / 86.4) % 1e3, 3);
            },
            g: function() {return f.G() % 12 || 12;},
            G: function() {return jsdate.getHours();},
            h: function() {return _pad(f.g(), 2); },
            H: function() {return _pad(f.G(), 2);},
            i: function() {return _pad(jsdate.getMinutes(), 2);},
            s: function() {return _pad(jsdate.getSeconds(), 2);},
            u: function() {return _pad(jsdate.getMilliseconds() * 1000, 6);},
            e: function() {throw 'Not supported (see source code of date() for timezone on how to add support)';},
            I: function() {
                var a = new Date(f.Y(), 0);
                var c = Date.UTC(f.Y(), 0);
                var b = new Date(f.Y(), 6);
                var d = Date.UTC(f.Y(), 6);
                return ((a - c) !== (b - d)) ? 1 : 0;
            },
            O: function(){
                var tzo = jsdate.getTimezoneOffset();
                var a = Math.abs(tzo);
                return (tzo > 0 ? '-' : '+') + _pad(Math.floor(a / 60) * 100 + a % 60, 4);
            },
            P: function(){
                var O = f.O();
                return (O.substr(0, 3) + ':' + O.substr(3, 2));
            },
            T: function() {return 'UTC';},
            Z: function() {return -jsdate.getTimezoneOffset() * 60;},
            c: function() {return 'Y-m-d\\TH:i:sP'.replace(formatChr, formatChrCb);},
            r: function() {return 'D, d M Y H:i:s O'.replace(formatChr, formatChrCb);},
            U: function() {return jsdate / 1000 | 0;}
        };
        this.date = function(format, timestamp) {
            that = this;
            jsdate = (timestamp === undefined ? new Date() : (timestamp instanceof Date) ? new Date(timestamp) :  new Date(timestamp * 1000));
            return format.replace(formatChr, formatChrCb);
        };
        return this.date(format, timestamp);
    }
    catch(e){catchError(e);}
}

function wordwrap(str, int_width, str_break, cut) {
    var m = ((arguments.length >= 2) ? arguments[1] : 75);
    var b = ((arguments.length >= 3) ? arguments[2] : '\n');
    var c = ((arguments.length >= 4) ? arguments[3] : false);
    var i, j, l, s, r;
    str += '';
    if (m < 1) {return str;}
    for (i = -1, l = (r = str.split(/\r\n|\n|\r/)).length; ++i < l; r[i] += s) {
        for (s = r[i], r[i] = ''; s.length > m; r[i] += s.slice(0, j) + ((s = s.slice(j)).length ? b : '')) {
            j = c == 2 || (j = s.slice(0, m + 1).match(/\S*(\s)?$/))[1] ? m : j.input.length - j[0].length || c == 1 && m || j.input.length + (j = s.slice(m).match(/^\S*/))[0].length;
        }
    }
    return r.join('\n');
}

function AfficheBoiteMessage(selecteur, dragable){
    var windows = $(selecteur);
    if(dragable!=""){windows.draggable({ handle: ".headBox", opacity: 0.45, containment: "body" });}
    if(!$(selecteur+" .contentAscenseur").hasClass("mCustomScrollbar")){$(".scrollerBox").mCustomScrollbar();}
    adjustLayout(windows);
    windows.removeClass("none");
    windows.fadeIn(250);
    $(".voileOverlay").addClass("active");
}

function closeBoiteMessage(idBox){

    //Pour arréter le son des vidéos lorsque l'on ferme la fenêtre
    if($("#playLecteur") && $("#playLecteur").hasClass("pauseLecteur")){
        $("#playLecteur").click();
    }

    $("#listeBox video").prop('muted', true);
    if((idBox)&&(idBox!="")){$("#listeBox #"+idBox).addClass("none");}
    else{$("#listeBox section[id^='box']").addClass("none");}
    $("#voileOverlay").removeClass("active");
    $(".full.overlayBox").removeClass("grayscale");
}

function adjustLayout(element){
    $(element).css({
        left: ($(window).width() - $(element).outerWidth())/2,
        top: ($(window).height() - $(element).outerHeight())/8
    });
}

function activeFlipBook(){
    var heightElementACC = $('.statistiques .contenuMonAAC').height();
    var widthElementACC = $('.statistiques .contenuMonAAC').width();
    if(document.addEventListener){
        window.addEventListener('resize', function() {
            heightElementACC = $('.statistiques .contenuMonAAC').height();
            widthElementACC = $('.statistiques .contenuMonAAC').width();
            $("#book").turn("size", widthElementACC, heightElementACC);
        });
    }
    $("#book").turn({
        width: widthElementACC,
        height: heightElementACC,
        gradients: true,
        acceleration: true
    });
}

function round(value, precision, mode) {
    var m, f, isHalf, sgn;
    precision |= 0;
    m = Math.pow(10, precision);
    value *= m;
    sgn = (value > 0) | -(value < 0);
    isHalf = value % 1 === 0.5 * sgn;
    f = Math.floor(value);
    if (isHalf) {
        switch (mode) {
            case 'PHP_ROUND_HALF_DOWN': value = f + (sgn < 0);
                break;
            case 'PHP_ROUND_HALF_EVEN': value = f + (f % 2 * sgn);
                break;
            case 'PHP_ROUND_HALF_ODD': value = f + !(f % 2);
                break;
            default: value = f + (sgn > 0);
        }
    }
    return (isHalf ? value : Math.round(value)) / m;
}

function hex2rgba(color, opacity) {
    color = color.replace('#','');
    r = parseInt(color.substring(0,2), 16);
    g = parseInt(color.substring(2,4), 16);
    b = parseInt(color.substring(4,6), 16);

    result = 'rgba('+r+','+g+','+b+','+opacity+')';
    return result;
}

function convertHeure(tempsConnexion){
    var heures = Math.abs(parseInt(tempsConnexion / 3600));
    var minutes = Math.abs(parseInt((tempsConnexion%3600) / 60));

    if(heures!==0){
        if(heures <10){heures='0'+heures;}
        heures = heures+'H';
    }
    else{heures='00H';}

    if(minutes <10){minutes='0'+minutes;}

    var tempsConnexion = heures+minutes;
    return tempsConnexion;
}

function courbeExamenBlanc(idElement, tblY, titreCourbeCodeDeLaRoute, titreCourbeExamenBlanc, titreCourbeSynthese, tblCodeDeLaRoute, tblExamenBlanc, tblSynthese, couleurBleu, couleurOrange, couleurRouge){
    var div = $(idElement);

    Highcharts.setOptions({
        colors: [couleurBleu, couleurOrange, couleurRouge],
        lang: {
            months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',  'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            shortMonths: [ "Jan" , "Fev" , "Mar" , "Avr" , "Mai" , "Jun" , "Juil" , "Aout" , "Sep" , "Oct" , "Nov" , "Dec"]
        }
    });

    var chartGraph =  div.highcharts({
        credits: {enabled: false},
        chart: {backgroundColor: 'transparent'},
        legend: {backgroundColor: '#ffffff',borderWidth: 1,borderRadius: 5},
        title: {text: null},
        xAxis: {gridLineWidth: 1,type: 'datetime',
            dateTimeLabelFormats: {month: '%b/%y'}
        },
        yAxis: {
            tickInterval: 5,
            min: 0,
            max: 40,
            title: {text: null},
            plotBands: {
                color: '#87D033', // Color value
                from: '35', // Start of the plot band
                to: '40' // End of the plot band
            }
        },
        series: [{
            type: 'spline',
            name: titreCourbeCodeDeLaRoute,
            data: eval(tblCodeDeLaRoute),
            marker: {enabled: false}
        }, {
            type: 'spline',
            name: titreCourbeExamenBlanc,
            data: eval(tblExamenBlanc),
            marker: {enabled: false}
        }, {
            type: 'spline',
            name: titreCourbeSynthese,
            data: eval(tblSynthese),
            marker: {enabled: false}
        }]
    });
}

function jaugeProgressionConduite(tableau){
    $('#canvasProgression').highcharts({
        credits:{
            enabled: false
        },
        chart:{
            type: 'column',
            backgroundColor: 'transparent'
        },
        title: {
            text: null
        },
        xAxis:{
            type: 'category',
            gridLineWidth: 1,
            labels:{
                style:{
                    fontSize: '11px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }
        },
        yAxis:{
            tickInterval: 50,
            min: 0,
            max: 100,
            title: {
                text: null
            },
            labels:{
                enabled: false
            }
        },
        legend:{
            enabled: false
        },
        series:[{
            name:'Avancement',
            color:'#FD9D05',
            data:eval(tableau),
            dataLabels:{
                enabled: false
            }
        }]
    });
}

function camambertCodeDeLaRoute(objectif, couleurBordOjectif, objectiResteAFaire, couleurBordCamanbert, fait, couleurFait, nonFait, idElement){
    var div = $(idElement);

    //Met la barre a 0 quand l'objectif est dépassé cela corrige un bug
    if(((objectif)&&(fait))&&(fait >= objectif)){
        objectif=0;
        objectiResteAFaire=0;
    }

    var chart =  div.highcharts({
        credits: {
            enabled: false
        },
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            backgroundColor: 'transparent'
        },
        title: {
            text: null
        },
        plotOptions: {
            pie: {
                allowPointSelect: false,
                borderWidth: 0,
                enableMouseTracking: false,
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.percentage:.1f} %</b>'
                }
            }
        },
        series: [{
            type: 'pie',
            name: 'Objectif à atteindre',
            data: [{
                y: parseInt(objectif),
                color: '#ffffff',
                borderWidth: 2,
                borderColor: couleurBordOjectif,
                dataLabels: {enabled: false}
            },{
                y:objectiResteAFaire,
                color: 'transparent',
                dataLabels: {enabled: false}
            }],
            innerSize: '0%'
        }, {
            type: 'pie',
            name: 'Pourcentage total',
            data: [{
                y: 100,
                borderWidth: 2,
                borderColor: couleurBordCamanbert,
                color: 'transparent',
                dataLabels: {enabled: false}
            }],
            innerSize: '0%'
        }, {
            type: 'pie',
            name: 'Pourcentage des cours lu',
            dataLabels: {distance: -50},
            data: [{
                y: fait,
                color: couleurFait,
                dataLabels: {
                    enabled: false
                }
            },{
                y:nonFait,
                color: 'transparent',
                dataLabels: {enabled: false}
            }],
            innerSize: '0%'
        }]
    });
}

function transphormeDateheure(time){
    var annee = time.substr(0, 4);
    var mois = time.substr(4, 2);
    var jour = time.substr(6, 2);
    var heure = time.substr(8, 2);
    var minute = time.substr(10, 2);

    var obDate = {};
    obDate.date = jour+"/"+mois+"/"+annee;
    obDate.heure = heure+"h"+minute;

    return obDate;
}

function jaugeProgressionConduite(tableau){
    $('#canvasProgression').highcharts({
        credits:{
            enabled: false
        },
        chart:{
            type: 'column',
            backgroundColor: 'transparent'
        },
        title: {
            text: null
        },
        xAxis:{
            type: 'category',
            gridLineWidth: 1,
            labels:{
                style:{
                    fontSize: '11px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }
        },
        yAxis:{
            tickInterval: 50,
            min: 0,
            max: 100,
            title: {
                text: null
            },
            labels:{
                enabled: false
            }
        },
        legend:{
            enabled: false
        },
        series:[{
            name:'Avancement',
            color:'#FD9D05',
            data:eval(tableau),
            dataLabels:{
                enabled: false
            }
        }]
    });
}

function onglet(id, origine, remplace, onglet){
    try{
        var elementCible = id.replace(origine, remplace);
        //onglet
        $("."+onglet).removeClass("active");
        $("#"+id).addClass("active");
        //contenu
        $("."+remplace).removeClass("active").addClass("none");
        $("#"+elementCible).addClass("active").removeClass("none");
        $(".scroller").mCustomScrollbar();
    }
    catch(e){catchError(e);}
}

function sortProperties(obj){
    try{
        // convert object into array
        var sortable=[];
        for(var key in obj){
            sortable.push([obj[key], (obj[key].AutoEcoleRang * 1)]); // each item is an array in format [key, value]
        }

        // sort items by value
        sortable.sort(function(a, b){
            return a[1]-b[1]; // compare numbers
        });
        return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
    }
    catch(e){catchError(e);}
}

function afficheIfram(thisId, fichier){
    try{
        var numEvaluation = thisId.split("numEvaluation");
        var numNomapad = numEvaluation[0];
        numEvaluation = numEvaluation[1];
        numNomapad = numNomapad.replace("numNomapad", "");

        var id = "iframeNomapad";
        var body = '';
        var picto = "B";
        var titre = (fichier === "nomapad_synthese_PW3.php" ? "Evaluation de synthèse" : "Evaluation finale");
        var idBox = "boxIframeNomapad";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getBoxMoto(id, titre, picto, body, idBox);
        $("#listeBox").html(html);
        AfficheBoiteMessage("#"+idBox, true);

        var iframe = " <iframe class=\"iframNomapad\" src=\""+protocoleMedia+"://www.packweb2.com/packweb/"+fichier+"?NumEvaluation="+numEvaluation+"&NumNomapad="+numNomapad+"\"></iframe>";
        $("#iframeNomapad .bodyBox").html(iframe);
        $(".full.overlayBox").addClass("grayscale");
        $(".voileOverlay").addClass("active");
    }
    catch(e){catchError(e);}
}

function funcfullScreen(){
    try{
        if($("#fullScreen").hasClass("active")){
            if (document.exitFullscreen) {document.exitFullscreen();}
            else if (document.mozCancelFullScreen) {document.mozCancelFullScreen();}
            else if (document.webkitCancelFullScreen) {document.webkitCancelFullScreen();}
        }else {
            var docElm = document.documentElement;
            if (docElm.requestFullscreen) {docElm.requestFullscreen();}
            else if (docElm.mozRequestFullScreen) {docElm.mozRequestFullScreen();}
            else if (docElm.webkitRequestFullScreen) {docElm.webkitRequestFullScreen();}
        }
    }
    catch(e){catchError(e);}
}

function nl2br(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

function razSommaire(){
    $("#boxSommaire .ligneNiv0.deplis").removeClass('deplis');
    $("#boxSommaire .ligneNiv0 .titreLigneSommaireNiv0.deplis").removeClass('deplis');
    $("#boxSommaire .ligneNiv0 .tblNiv1").addClass("none");
    $("#boxSommaire .ligneNiv0 .tblNiv2").addClass("none");
    $(".spacer").css("height", "0");
    $(".scrollerBox").mCustomScrollbar("update");
}

function RemoveAccents(strAccents) {
    var strAccents = strAccents.split('');
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
    for (var y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else
            strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join('');
    return strAccentsOut;
}

function lireFichierVideo(url){
    try{
        var id = "idBoxVideo";
        var body = '<video style="margin:-1em;" id="sequenceTVPneu" width="720" controls="true"><source src="'+RACINE_MEDIA+'/'+url+'"></source></video>';
        var picto = "H";
        var titre = "Video";
        var idBox = "boxVideo";
        var boxConstruct = new pw3.box(id, titre, picto, body, idBox);
        var html = boxConstruct.getHtml();
        $("#listeBox").html(html);
        AfficheBoiteMessage("#"+idBox, true);
        $(".voileOverlay").addClass("active");
        var elmVideo = $("#sequenceTVPneu");
        if(elmVideo.length){elmVideo.get(0).load();}
        elmVideo.get(0).play();
    }
    catch(e){catchError(e);}
}

function findPourquoiRegle(){
    try{
        var sequencePourquoiRegle;
        var idSequencePourquoiRegle;
        var idPartiePourquoiRegle;
        var splitUrl = pw3.Page.splitUrl;

        var retour = {};

        if(splitUrl[2]){
            var partiePourquoiRegle = pw3.Page.dataFormation.formation.tabChild[cleanID(splitUrl[1])].tabChild[cleanID(splitUrl[2])].tabChild;

            for (idPartiePourquoiRegle in partiePourquoiRegle) {
                if (partiePourquoiRegle[idPartiePourquoiRegle].Meta.ordre === "1") {
                    sequencePourquoiRegle = partiePourquoiRegle[idPartiePourquoiRegle].tabChild;
                    for (idSequencePourquoiRegle in sequencePourquoiRegle) {
                        retour.sequence = sequencePourquoiRegle[idSequencePourquoiRegle];
                    }
                    break;
                }
            }
            retour.urlComplete = "cours-" + splitUrl[1] + "-" + splitUrl[2] + "-p" + idPartiePourquoiRegle + "-s" + idSequencePourquoiRegle;
            retour.idPartie = idPartiePourquoiRegle;
            retour.idSequence = idSequencePourquoiRegle;
        }

        return retour;
    }
    catch(e){catchError(e);}
}

function findTabAssetRisqueEtSanction(themeId){
    try{
        var paramRisques = {};
        paramRisques.asset_type_id = [(ASSET_TYPE_RISQUE*1), (ASSET_TYPE_SANCTION*1)];
        paramRisques.themeId = themeId*1;

        return getTabAsset(paramRisques);
    }
    catch(e){catchError(e);}
}

function inspectPourquoiRegleOuvert(){
    try{
        var pourquoiRegle = findPourquoiRegle();
        var idSequence = pourquoiRegle.idSequence;
        return pw3.Stat.dataStat.stat[idSequence].ouvert;
    }
    catch(e){catchError(e);}
}

function inspectPourquoiRegleVu(){
    try{
        var pourquoiRegle = findPourquoiRegle();
        var idSequence = pourquoiRegle.idSequence;
        return pw3.Stat.dataStat.stat[idSequence].vu;
    }
    catch(e){catchError(e);}
}

function inspectPourquoiRegleFini(){
    try{
        var pourquoiRegle = findPourquoiRegle();
        var idSequence = pourquoiRegle.idSequence;
        return pw3.Stat.dataStat.stat[idSequence].is_fini;
    }
    catch(e){catchError(e);}
}

function inspectAssetFini(){
    try{
        var idModule = cleanID(pw3.Page.splitUrl[2]);
        var asset = {};
        asset.signalisation = pw3.Stat.dataStat.stat[idModule].signalisation;
        asset.risque = pw3.Stat.dataStat.stat[idModule].risque;
        return asset;
    }
    catch(e){catchError(e);}
}

function AffichePourquoiRegle(){
    try{
        var pourquoiRegle = findPourquoiRegle();
        pw3.Page.AffichePage("cours",pourquoiRegle.urlComplete);
    }
    catch(e){catchError(e);}
}

function testIfNull(data){
    if(data===null){data = "-";}
    return data;
}

function validateEmail(email) {
    var reg = new RegExp('^[a-z0-9]+([_|\.|-]+[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*[\.]{1}[a-z]{2,6}$', 'i');
    if(reg.test(email)){return(true); }
    else{return(false);}
}

function validateName(str) {
    var reg = new RegExp('^[A-Za-z áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ_\s\\-*]+$', 'i');
    if(reg.test(str)){return(true); }
    else{return(false);}
}

function stringToDate(maDate){
    return new Date(maDate.substring(0,4) + "-" + maDate.substring(4,6) + "-" + maDate.substring(6,8) + " " + maDate.substring(8,10) + ":" + maDate.substring(10,12) + ":00");
}

function updateNbMessage(){
    try{
        var boxMessage = new pw3.box();
        var obMessage = boxMessage.getListeMessage();
        var obRetourMessage = {};
        var nbMessageNonLu = "";

        if(obMessage.countUnRead>0){
            nbMessageNonLu = '<div class="link fontEdiser rondNbMessage">a<div class="rondRougeNbMessage fontArial">'+obMessage.countUnRead+'</div></div>';
        }

        $("#menuPrincipal #messagerie").html("e"+nbMessageNonLu);

        obRetourMessage.message = obMessage;
        obRetourMessage.nbMessageNonLu = nbMessageNonLu;

        return obRetourMessage;
    }
    catch(e){catchError(e);}
}

function clone(obj){
    try{
        var copy = "";
        if(obj){
            try{copy = JSON.parse(JSON.stringify(obj));}
            catch(e){catchError(e);}
        }
        return copy;
    }
    catch(e){catchError(e);}
}

function toArray(_Object){
    var _Array = new Array();
    for(var name in _Object){
        _Array[name] = _Object[name];
    }
    return _Array;
}

function constructionVignetteModule(dataMenuModule){
    try{
        var html;
        for(var element in dataMenuModule["tabChild"]){
            if(element){
                var getMetaSousModule = dataMenuModule["tabChild"][element]["Meta"];
                if (getMetaSousModule.pos_x) {
                    var assetType = "";
                    var assetMeta = "";
                    var getTabContenu = dataMenuModule["tabContenu"];
                    for(var tabContenu in getTabContenu){
                        var getTabAsset = getTabContenu[tabContenu]["tabAsset"];
                        for(var tabAsset in getTabAsset){
                            assetType = getTabAsset[tabAsset]["AssetType"].titre;
                            assetMeta = getTabAsset[tabAsset]["AssetMeta"].contenu;
                        }
                    }
                    var getMetaModule = dataMenuModule["Meta"];
                    var getLangSousModule = dataMenuModule["tabChild"][element]["tabObjectLang"][0];
                    var tblStatSousTheme = pw3.Stat.nbSousThemeNbSousThemeVu(pw3.Stat.dataStat.stat[element]);
                    var vignetteModule = new pw3.vignetteModule(getMetaSousModule.id, getLangSousModule.titre, tblStatSousTheme.nbSousthemeVu, tblStatSousTheme.nbSoustheme, getMetaModule.color, assetType, assetMeta, getMetaSousModule.pos_x, getMetaSousModule.pos_y, getMetaSousModule.width, getMetaSousModule.height);
                    html += vignetteModule.getHtml();
                }
            }
        }
        return html;
    }
    catch(e){catchError(e);}
}

function tailleEtPositionModule(dataModule, idChildDataModule){
    var objRetour = {};
    var elemContenu = $("#contenuAjax");
    objRetour.hauteurContenu = elemContenu.height();
    objRetour.largeurContenu = elemContenu.width();
    objRetour.pos_x = 0;
    objRetour.pos_y = 0;
    objRetour.hauteurDecalBox = 0;
    objRetour.largeurDecalBox = 0;

    if(dataModule[idChildDataModule].Meta.pos_y){
        objRetour.pos_y = dataModule[idChildDataModule].Meta.pos_y;
        objRetour.hauteurDecalBox = objRetour.hauteurContenu*objRetour.pos_y.replace("%", "")/100;
    }

    if(dataModule[idChildDataModule].Meta.pos_x){
        objRetour.pos_x = dataModule[idChildDataModule].Meta.pos_x;
        objRetour.largeurDecalBox = objRetour.largeurContenu*objRetour.pos_x.replace("%", "")/100;
    }

    objRetour.width = dataModule[idChildDataModule].Meta.width;
    objRetour.height = dataModule[idChildDataModule].Meta.height;

    return objRetour;
}

function refreschModule(dataModule){
    var tblValueData = [];
    for(var idDataModule in dataModule){
        var dataTailleEtPosition = tailleEtPositionModule(dataModule, idDataModule);
        $('#p'+idDataModule).css({
            "background-position": '-'+dataTailleEtPosition.largeurDecalBox+'px -'+dataTailleEtPosition.hauteurDecalBox+'px',
            "background-size": dataTailleEtPosition.largeurContenu+'px '+dataTailleEtPosition.hauteurContenu+'px',
            "top": dataTailleEtPosition.pos_y+"%",
            "left": dataTailleEtPosition.pos_x+"%",
            "width": dataTailleEtPosition.width+"%",
            "height": dataTailleEtPosition.height+"%"
        });
        tblValueData.push(dataTailleEtPosition);
    }
    return tblValueData;
}

function activeBaliseAudioVideo(){
    try{
        //Suppression des listeners de la balise audio
        var el = document.getElementById('audioQuestion'), elClone = el.cloneNode(true);
        el.parentNode.replaceChild(elClone, el);

        $("#audioQuestion source").attr('src', 'resources/media/blank.mp3');
        $("#videoQuestion source").attr('src', 'resources/media/blank.m4v');
        $("#audioQuestion, #videoQuestion").get(0).load();
        $("#videoQuestion").addClass("none");
    }
    catch(e){catchError(e);}

}


$.fn.serializeObject = function(){
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        }
        else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};


function completeVersion(){

    jQuery.ajax({
        url: protocoleMedia + "://" + location.host + "/package.json",
        dataType: "json",
        headers: {"Content-Type": "application/json", "Accept": "application/json"},
        type: 'GET',
        async: false,
        contentType: "application/json; charset=utf-8",
        success: function (data) {
            $("#version").text(data.version);

            if(appVersion && appVersion != ""){
                localStorage.setItem("pw_test_version", data.version);
            }
            checkPWVersion();
        }
    });
}

function clearTimerStep() {
    addContextToLog("Réinitilisation du timer");
    var deferClearTimerStep = Q.defer();
    timerStep = 0.01;
    timerPending = false;
    forceEndTimer = true;
    clearTimerSecuriteAudio();
    deferClearTimerStep.resolve(clearTimeout(setTimeExam));
    return deferClearTimerStep.promise;
}

function countQuestionType(type){
    var i=0;
    for(var idQuestion in pw3.Examens.oTestEnCours.Question){
        for(var idQuestionMedia in pw3.Examens.oTestEnCours.Question[idQuestion].media){
            var extentionFile = pw3.Examens.oTestEnCours.Question[idQuestion].media[idQuestionMedia].contenu.split(".");
            var ext = extentionFile[extentionFile.length-1];
            if(
                (ext == type) &&
                (pw3.Examens.oTestEnCours.Question[idQuestion].media[idQuestionMedia].tag == "packweb question")
            ){
                i++;
            }
        }
    }
    pw3.Examens.oTestEnCours["nbQuestion"+type] = i;
}

function initNewExam(){
    questionEnCours = 1;
    addContextToLog("Lancement d'un nouveau test");
    cleanImageQuestionInSS();
    $("#btnSuivant").removeClass("link").addClass("nolink");
    timerStep = 0.01;
    timerPending = false;
    forceEndTimer = true;
    nbTests++;
}

function cleanVideoQuestionInDom(){
    var allBaliseVideo = $("video[id^='videoQuestion_']");
    $.each(allBaliseVideo, function(key, value){
        $(value).remove();
    });
}

function stockVideo(){
    try {
        var deferPreloadVideo = Q.defer();
        var idQuestion = pw3.Examens.oTestEnCours.Compteur - 1;
        var allBaliseVideo = $("video[id^='videoQuestion_']");

        //nettoie la DOM des balises video qui ne sont pas nécéssaire
        if(allBaliseVideo.length >= nbVideoQuestionPreload){
            $.each(allBaliseVideo, function (key, value) {
                var idVideoQuestion = $(value).attr("id");
                var splitIdVideoQuestion = idVideoQuestion.split("_");
                if (splitIdVideoQuestion[1] < idQuestion) {
                    $(value).remove();
                }
                if (splitIdVideoQuestion[1] > idQuestion + nbAudioQuestionPreload) {
                    $(value).remove();
                }
            });
        }

        for(var i=idQuestion; i < pw3.Examens.oTestEnCours.Question.length; i++){
            var testIfVideo = false;
            if($("#videoQuestion_"+i).length >= 1){continue}
            var mediaQuestionEnCours = pw3.Examens.oTestEnCours.Question[i].media;
            for(var idQuestionMedia in mediaQuestionEnCours){
                var fileType = mediaQuestionEnCours[idQuestionMedia].type;

                if(
                    ((fileType == "Video") && (mediaQuestionEnCours[idQuestionMedia].tag == "packweb question"))
                    ||(fileType == "PWQuestionVideo")
                ){
                    testIfVideo = true;
                    var videoFileQuestion = RACINE_MEDIA_QUESTION_NEW + fileType.toLowerCase() +"/"+ mediaQuestionEnCours[idQuestionMedia].contenu;
                    (function(videoFileQuestion, i){
                        preLoadVideo(videoFileQuestion, i).then(function(data){
                            var baliseVideo = $('<video id="videoQuestion_'+i+'" preload="auto" class="none">' +
                                '<source id="mp4Source_'+i+'" codecs="mp4" type="video/mp4" src="'+videoFileQuestion+'"></source>' +
                                '</video>');
                            $('body').prepend(baliseVideo);
                            deferPreloadVideo.resolve(data);
                        });
                    })(videoFileQuestion, i);

                }
            }
            if(testIfVideo == false){deferPreloadVideo.resolve(true);}
        }
        return deferPreloadVideo.promise;
    }
    catch(e){console.log(e);}
}

function preLoadVideo(videoFileQuestion, indiceQuestion){
    try {
        var deferPreloadVideoFile = Q.defer();
        var objDataRetour = {"indiceQuestion": indiceQuestion, "videoFileQuestion": videoFileQuestion};

        $.ajax({
            url: videoFileQuestion,
            success: function () {
                deferPreloadVideoFile.resolve(objDataRetour);
            }
        });
        return deferPreloadVideoFile.promise;
    }
    catch(e){console.log(e)}
}

function cleanAudioQuestionInDom(){
    var allBaliseAudio = $("audio[id^='audioQuestion_']");
    $.each(allBaliseAudio, function(key, value){
        $(value).remove();
    });
}

function stockAudio(){
    var deferPreloadAudio = Q.defer();
    var idQuestion = pw3.Examens.oTestEnCours.Compteur-1;
    var audioFileQuestion;
    var allBaliseAudio = $("audio[id^='audioQuestion_']");

    //nettoie la DOM des balises audio qui ne sont pas nécéssaire
    $.each(allBaliseAudio, function(key, value){
        var idAudioQuestion = $(value).attr("id");
        var splitIdAudioQuestion  = idAudioQuestion.split("_");
        if(splitIdAudioQuestion[1] < idQuestion){
            $(value).remove();
        }
        if(splitIdAudioQuestion[1] > idQuestion + nbAudioQuestionPreload){
            $(value).remove();
        }
    });

    for(var i=idQuestion; i <= (idQuestion + (nbAudioQuestionPreload-1)); i++){
        var testIsSon = false;

        if($("#audioQuestion_"+i).length >= 1){continue}

        if(pw3.Examens.oTestEnCours.Question[i]) {
            var mediaQuestionEnCours = pw3.Examens.oTestEnCours.Question[i].media;

            for (var idQuestionMedia in mediaQuestionEnCours) {
                var fileType = mediaQuestionEnCours[idQuestionMedia].type;
                if (
                    (fileType == "Son")
                    && (mediaQuestionEnCours[idQuestionMedia].tag == "packweb question")
                ) {
                    testIsSon = true;
                    audioFileQuestion = mediaQuestionEnCours[idQuestionMedia].contenu;
                    preLoadAudio(audioFileQuestion, i).then(function (data) {
                        if ( ($( "#audioQuestion_"+data.indiceQuestion ).length == 0) && (data.indiceQuestion > 0)) {
                            var baliseAudio = $('<audio id="audioQuestion_' + data.indiceQuestion + '" preload="auto">' +
                                '<source id="mp3Source_' + data.indiceQuestion + '" codecs="mp3" type="audio/mpeg" src="' + RACINE_MEDIA_QUESTION + 'web-Q-flash/' + data.audioFileQuestion + '"></source>' +
                                '</audio>');
                            $('body').prepend(baliseAudio);
                            deferPreloadAudio.resolve(data);
                        }
                    })
                }
            }
        }
        if(testIsSon == false){deferPreloadAudio.resolve("");}
    }
    return deferPreloadAudio.promise;
}

function preLoadAudio(audioFileQuestion, indiceQuestion){
    var deferPreloadAudioFile = Q.defer();
    var objDataRetour = {"indiceQuestion" : indiceQuestion,  "audioFileQuestion" : audioFileQuestion};

    $.ajax({
        url: RACINE_MEDIA_QUESTION+'web-Q-flash/'+audioFileQuestion,
        success: function() {
            deferPreloadAudioFile.resolve(objDataRetour);
        }
    });
    return deferPreloadAudioFile.promise;
}

function stockImage(){
    try {
        //met dans un tableau l'url des images images qui sont a précharger
        var idQuestionCourtante = 1;
        var nbQuestionExamen = pw3.Examens.oTestEnCours.Question.length;
        var selectAudioQuestionPreload = nbAudioQuestionPreload;
        var deferPreloadImage = Q.defer();
        compteurQuestionImagePreload = 0;

        funcTypeCompression();

        if (pw3.Examens.oTestEnCours && pw3.Examens.oTestEnCours.Compteur) {
            idQuestionCourtante = pw3.Examens.oTestEnCours.Compteur;
            selectAudioQuestionPreload = idQuestionCourtante + nbAudioQuestionPreload;
        }

        //j'efface les image précédante et celle qui seraient en trop
        if (idQuestionCourtante > 1) {
            for (var ii = 1; ii <= idQuestionCourtante; ii++) {
                if (sessionStorage.getItem("SS_tamponImageExamen_" + ii)) {
                    sessionStorage.removeItem('SS_tamponImageExamen_' + (ii));
                }
            }

            for (var iii = nbQuestionExamen; (idQuestionCourtante + selectAudioQuestionPreload) <= iii; iii--) {
                if (sessionStorage.getItem("SS_tamponImageExamen_" + iii)) {
                    sessionStorage.removeItem('SS_tamponImageExamen_' + (iii));
                }
            }
        }

        for (var idQuestion in pw3.Examens.oTestEnCours.Question) {

            var idQuestionToPreload = parseInt(idQuestion) + 1;

            if (
                (idQuestionToPreload == 1 && idQuestionToPreload < idQuestionCourtante)
                || (idQuestionToPreload > 1 && idQuestionToPreload <= idQuestionCourtante)
                || (idQuestionToPreload > selectAudioQuestionPreload)

            ) {
                continue;
            }

            for (var idQuestionMedia in pw3.Examens.oTestEnCours.Question[idQuestion].media) {

                var testIsImage = false;
                var extentionFile = pw3.Examens.oTestEnCours.Question[idQuestion].media[idQuestionMedia].contenu.split(".");
                var ext = extentionFile[extentionFile.length-1];
                var media = pw3.Examens.oTestEnCours.Question[idQuestion].media;
                var version = pw3.Examens.oTestEnCours.Question[idQuestion].version;
                var file = null;

                if (
                    ((ext == "jpg") || (ext == "gif"))
                    && (sessionStorage.getItem("SS_tamponImageExamen_" + idQuestionToPreload) === null)
                ) {
                    testIsImage = true;

                    for (var idMedia in media) {
                        if ((findValueInArray(media[idMedia].tag, "question") === true) && ((media[idMedia].type_id == 1) || (media[idMedia].type_id == 2))) {
                            file = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-Q-image/" + media[idMedia].contenu, version);
                        }
                        if ((media[idMedia].type_id == 14) || (media[idMedia].type_id == 16)) {
                            file = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idMedia].type) + "/" + media[idMedia].contenu, version);
                        }
                    }

                    if(file!=null) {
                        load_binary_resource(file, idQuestionToPreload).then(
                            function (retour) {
                                compteurQuestionImagePreload++;
                                if (retour.rangQuestion) {
                                    var nameItem = "SS_tamponImageExamen_" + parseInt(retour.rangQuestion);
                                    sessionStorage.setItem(nameItem, eval("LZString." + lzStringCompressionType)(JSON.stringify(retour)));
                                    if (
                                        (compteurQuestionImagePreload > nbImagesQuestionPreload)
                                        || (compteurQuestionImagePreload == nbQuestionExamen)
                                    ) {
                                        deferPreloadImage.resolve(true);
                                    }
                                }
                                else {
                                    if (
                                        (compteurQuestionImagePreload > nbImagesQuestionPreload)
                                        || (compteurQuestionImagePreload == nbQuestionExamen)
                                    ) {
                                        deferPreloadImage.resolve(true);
                                    }
                                }
                            }
                        );
                        break;
                    }
                }
            }
        }
        return deferPreloadImage.promise;
    }
    catch(e){console.log(e);}
}

function erreurImageQuestion(raison, nombreEssaisMax, rangQuestion, e){
    try {
        var deferImageSecours = Q.defer();
        addContextToLog("L'image de la question n'a pas pu se charger pour la raison  (" + raison + "), le nombre tentatives est de " + nombreEssaisMax + " Détails : " + e);
        //contrôler la question de secours.
        //remplace la question dans la série.
        var idQuestionSecours = rangQuestion - 1;
        if (pw3.Examens && pw3.Examens.oTest &&
            pw3.Examens.oTest.TabQuestionSupp && pw3.Examens.oTest.TabQuestionSupp.length > 0) {

            var questionDeSecours = clone(pw3.Examens.oTest.TabQuestionSupp[0]);
            if ((rangQuestion) && (questionDeSecours)) {
                addContextToLog("Utilisation de la question de secours");
                addContextToLog("Question d'origine : " + pw3.Examens.TestCorrection.Question[idQuestionSecours].NumQuestion + ", Question de secours :" + questionDeSecours.reference);

                pw3.Examens.oTestEnCours.Question[idQuestionSecours] = questionDeSecours;
                pw3.Examens.oTest.TabQuestion[idQuestionSecours] = questionDeSecours;
                pw3.Examens.TestCorrection.Question[idQuestionSecours].NumQuestion = questionDeSecours.reference;

                var objTestCorrection = new CallObj("TestCorrection", "", pw3.Examens.TestCorrection, "update", "TestCorrection");
                objTestCorrection.getObj();

                var objTestEnCours = new CallObj("TestEnCours", "", pw3.Examens.oTestEnCours, "update", "TestEnCours");
                objTestEnCours.getObj();

                //efface la question qu'on utilise
                pw3.Examens.oTest.TabQuestionSupp.splice(0, 1);

                for (var idQuestionMedia in questionDeSecours.media) {

                    var media = questionDeSecours.media;
                    var version = pw3.Examens.oTestEnCours.Question[idQuestionMedia].version;
                    var obQVideo = {};
                    obQVideo.obj = media[idQuestionMedia];

                    if ((findValueInArray(media[idQuestionMedia].tag, "question") === true) && ((media[idQuestionMedia].type_id == 1) || (media[idQuestionMedia].type_id == 2))) {
                        obQVideo.url = getQuestionVersion(RACINE_MEDIA_QUESTION + "web-Q-image/" + media[idQuestionMedia].contenu, version);
                        load_binary_resource(obQVideo.url, rangQuestion, nombreEssaisMax, true).then(
                            function (data) {
                                deferImageSecours.resolve(data);
                            }
                        );
                        break;
                    } else if ((media[idQuestionMedia].type_id == 14) || (media[idQuestionMedia].type_id == 16)) {
                        obQVideo.url = getQuestionVersion(RACINE_MEDIA_QUESTION_NEW + formatRepertoirMedia(media[idQuestionMedia].type) + "/" + media[idQuestionMedia].contenu, version);
                        load_binary_resource(obQVideo.url, rangQuestion, nombreEssaisMax, true).then(
                            function (data) {
                                deferImageSecours.resolve(data);
                            }
                        );
                        break;
                    }
                }
            }else{deferImageSecours.resolve(false);}
        } else{deferImageSecours.resolve(false);}

        return deferImageSecours.promise;

        //Si même sur la question de secours il y a un problème
        //message éléve
        //redirction
        //nettoyage des tests dans le session storage
        //mail nous
        //utilisation de la question de secours.
        //Remplacement dans le session storage (test et stat)
        //chargement de l'image en base64
        //J'envois un mail pour nous avertir.
    }
    catch(e){catchError(e);}

}

function load_binary_resource(url, rangQuestion, nbEssais, test) {
    var deferImage = Q.defer();
    var nombreEssaisMax = 5;

    if(!nbEssais){nbEssais = 0;}

    nbEssais++;

    //TEST//
    //Test qui permet de savoir ce que je fais si il y a une erreur dans une image
    //if((rangQuestion == 6)&&(!test)){
    //Test qui permet de savoir ce que je dois faire si je n'ai plus du tous d'image de secour
    //url = "https://www.cdnediser.com/formation/question/PERMISB//web-Q-image/Q0041156146.jpg";


    try{
        requetteImage(url, nbEssais, rangQuestion, nombreEssaisMax).then(function(dataRequette){
            deferImage.resolve(dataRequette);
        });
    }
    catch(e){
        catchError(e);
        if(nbEssais < nombreEssaisMax){
            load_binary_resource(url, rangQuestion, nbEssais).then(function(data){
                deferImage.resolve(data);
            });
        }
        else{
            erreurImageQuestion("Erreur chargement image", nombreEssaisMax, rangQuestion, e).then(function(data){
                deferImage.resolve(data);
            });
        }
    }
    return deferImage.promise;
}

function cleanImageQuestionInSS(){
    try{
        $.each(sessionStorage, function(key, value){
            if(key.indexOf("SS_tamponImageExamen_")>-1){
                sessionStorage.removeItem(key);
            }
        });
    }
    catch(e){catchError(e);}
}

function requetteImage(url, nbEssais, rangQuestion, nombreEssaisMax){
    try{
        var deferRequette= Q.defer();
        var xmlHTTP = new XMLHttpRequest();

        xmlHTTP.open('GET',url,true);
        //xmlHTTP.timeout = 1; // time in milliseconds
        xmlHTTP.timeout = 10000; // time in milliseconds
        xmlHTTP.responseType = 'arraybuffer';
        xmlHTTP.onload = function(e){
            var arr = new Uint8Array(this.response);
            var strings = [], chunksize = 0xffff;
            var len = arr.length;

            for (var i = 0; i * chunksize < len; i++){
                strings.push(String.fromCharCode.apply(null, arr.subarray(i * chunksize, (i + 1) * chunksize)));
            }

            var b64=btoa(strings.join(""));
            var objRetour = {};
            objRetour.dataURL="data:image/jpeg;base64,"+b64;
            objRetour.rangQuestion = rangQuestion;
            deferRequette.resolve(objRetour);
        };

        xmlHTTP.ontimeout = function(e) {
            if(nbEssais < nombreEssaisMax){load_binary_resource(url, rangQuestion, nbEssais).then(function(data){
                deferRequette.resolve(data);
            })}
            else{erreurImageQuestion("TimeOut", nombreEssaisMax, rangQuestion, e).then( function(data){
                deferRequette.resolve(data);
            })}
        };

        xmlHTTP.onerror = function(e){
            if(nbEssais < nombreEssaisMax){load_binary_resource(url, rangQuestion, nbEssais).then(function(data){
                deferRequette.resolve(data);
            })}
            else{erreurImageQuestion("Image loading error", nombreEssaisMax, rangQuestion, e).then( function(data){
                deferRequette.resolve(data);
            })}
        };

        xmlHTTP.send();

        return deferRequette.promise;
    }
    catch(e){catchError(e);}
}

function addContextToLog(context){
    try{
        var message;

        if(pw3.Examens.oTestEnCours && pw3.Examens.oTestEnCours.Question){
            message = moment().format() + " : [" + questionEnCours + "/" + pw3.Examens.oTestEnCours.Question.length+ "] " + context;
        }else{
            message = moment().format() + " : " + context;
        }
        if(pw3.Page.debug && pw3.Page.debug == true){console.log(message);}
        tabExamLog.push(message);
    }
    catch(e){catchError(e);}
}

function clearLogContext(){
    tabExamLog = [];
}

function checkAudioSecurityNeeded(){
    if(audioSecurityNeeded || audioSecurityNotEnded){
        clearTimerSecuriteAudio();
        addContextToLog("SECURITE : On active le bouton Suivant car la balise audio n'a pas fonctionné correctement.");
        pw3.Examens.progressBarreQuestionExamen();
    }
}

function launchMp3(mode, mp3ToLaunch){
    try {
        $("#audioQuestion source").attr('src', mp3ToLaunch);
        $("#audioQuestion").get(0).preload = "auto";
        $("#audioQuestion").get(0).load();
        $("#audioQuestion").get(0).oncanplay = function () {

            var audioDuration = (($("#audioQuestion").get(0).duration) * 1000) + 1000;

            //Sécurité pour SAFARI
            if (!isFinite(audioDuration) || isNaN(audioDuration) || audioDuration == 0 || ((audioDuration - 1000) / 1000) == 0) {
                addContextToLog("BUG NAVIGATEUR - Durée du son non disponible : Initialisation de la durée à 10 secondes");
                audioDuration = 10000;
            }

            $("#audioQuestion").get(0).play();

            addContextToLog("Lancement de la lecture du son (" + mp3ToLaunch + ")");

            if (mode === "question") {
                clearTimerSecuriteAudio();
                addContextToLog("La sécurité sera déclenchée dans " + ((audioDuration - 1000) / 1000) + " secondes si la lecture du média n'est pas terminée");
                addTimerSecuriteAudio(audioSecurityNeeded, true, audioDuration);
            }
        };
        if (mode === "question") {
            $("#audioQuestion").get(0).onerror = function (e) {
                addContextToLog("Interception d'une erreur lors de la lecture du son");
                switch (e.target.error.code) {
                    case e.target.error.MEDIA_ERR_NETWORK:
                        addContextToLog("Une erreur réseau a empéché le téléchargement du média");
                        break;
                    case e.target.error.MEDIA_ERR_DECODE:
                        addContextToLog("La lecture du média a été annulée en raison d'un problème de corruption ou parce que le navigateur ne supporte pas ce type de media");
                        break;
                    case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                        addContextToLog("Le média n'a pu être chargé, soit à cause d'une coupure réseau soit parce que le format n'est pas supporté");
                        break;
                    default:
                        addContextToLog("Une erreur inconnue s'est produite");
                }

                clearTimeout(audioSecurityNeededTimeOut);
                addTimerSecuriteAudio(true, audioSecurityNotEnded, 10000);
            };
            //Active la rotation des images dans le sessionStorage
            stockImage();
            //Met en cache les fichiers MP3
            stockAudio();
            //Met en cache les fichiers MP4
            //stockVideo();
        }
    }
    catch(e){catchError(e);}
}

function checkVideoSecurityNeeded(mode, mp3ToLaunch){
    if(videoSecurityNeeded || videoSecurityNotEnded){
        clearTimerSecuriteVideo();
        addContextToLog("SECURITE : On lance le son automatiquement car la balise video n'a pas fonctionnée correctement.");
        launchMp3(mode, mp3ToLaunch)
    }
}

function addTimerSecuriteAudio(isAudioSecurityNeeded, isAudioSecurityNotEnded, timerLaunchSeeting){
    audioSecurityNeeded = isAudioSecurityNeeded;
    audioSecurityNotEnded = isAudioSecurityNotEnded;
    audioSecurityNeededTimeOut = setTimeout(function(){checkAudioSecurityNeeded()}, timerLaunchSeeting);
}

function addTimerSecuriteVideo(isVideoSecurityNeeded, isVideoSecurityNotEnded, timerLaunchSeeting, mode, mp3ToLaunch){
    videoSecurityNeeded = isVideoSecurityNeeded;
    videoSecurityNotEnded = isVideoSecurityNotEnded;
    videoSecurityNeededTimeOut = setTimeout(function(){checkVideoSecurityNeeded(mode, mp3ToLaunch)}, timerLaunchSeeting);
}

function clearTimerSecuriteAudio(){
    audioSecurityNeeded     = false;
    audioSecurityNotEnded   = false;
    clearTimeout(audioSecurityNeededTimeOut);
}

function clearTimerSecuriteVideo(){
    videoSecurityNeeded     = false;
    videoSecurityNotEnded   = false;
    clearTimeout(videoSecurityNeededTimeOut);
}

function reloadFormation(forceReload) {

    forceReload = (forceReload ? forceReload : false);

    if(forceReload
        || !pw3.Page.dataFormation
        || !pw3.Page.dataFormation.formation
        || !pw3.Page.dataFormation.formation.tabChild
        || (pw3.Page.dataFormation.formation.tabChild && pw3.Page.dataFormation.formation.tabChild.length == 0) ){
        pw3.Page.dataFormation = {};
        pw3.Page.dataCouleur = {};
        pw3.Page.dataForme = {};
        pw3.Page.dataFormation.formation = callData.formation.forceUpdateFormation();
        pw3.Page.dataCouleur.couleur = callData.formation.callFormationCouleur();
        pw3.Page.dataForme.forme = callData.formation.callFormationForme();
    }
}

function addslashes(str){
    //var test = str.replace(/'/g, "/'");
    var test = str.replace(/'/g, " ").replace(/"/g, " ").replace(/{/, ")").replace(/}/, ")");
    return test;
}

function sendLog(type, contexte, exception ) {

    var history = [];

    //On récupère l'ihstorique de navigation
    var tmpHistory = JSON.parse(sessionStorage.getItem("SS_historyNavPrecedent"));
    if(tmpHistory){
        history.push(tmpHistory[0]);
        if(tmpHistory.length > 1){
            history.push(tmpHistory[1]);
        }
    }

    var codeEleve = (pw3.Page.obEleve ? pw3.Page.obEleve.CodeEleve : "#n/a");
    var data = {"contexte" : contexte, "exception" : exception, "userAgent" : navigator.userAgent, "CodeEleve" : codeEleve, "history" : history};
    var dataApi = {"log": JSON.stringify(data)};
    apiCall("POST", "managements/debug/packweb3/partie/" + type, dataApi);
}

var Handler = (function(){
    try{
        var i = 1,
            listeners = {};

        return {
            addListener: function(element, event, handler, capture) {
                element.addEventListener(event, handler, capture);
                listeners[i] = {element: element,
                    event: event,
                    handler: handler,
                    capture: capture};
                return i++;
            },
            removeListener: function(id) {
                if(id in listeners) {
                    var h = listeners[id];
                    h.element.removeEventListener(h.event, h.handler, h.capture);
                }
            }
        };
    }
    catch(e){catchError(e);}
}());

function checkPWVersion(){
    apiCall("GET", "managements/products/169", {})
        .then(function(retour){

                for(var i = 0 ; i < retour.message["0"].meta.length; i++){
                    if(retour.message["0"].meta[i].ref === "version"){
                        var pw_test_version     = null;
                        var ls_pw_test_version  = localStorage.getItem("pw_test_version");

                        pw_test_version = retour.message["0"].meta[i].valeur;
                        //pw_test_version = pw_test_version.substring(0, pw_test_version.lastIndexOf("."));

                        if(ls_pw_test_version == null){
                            ls_pw_test_version = pw_test_version;
                            localStorage.setItem("pw_test_version", pw_test_version);
                        }

                        if(ls_pw_test_version != pw_test_version ){
                            location.reload(true);
                            localStorage.setItem("pw_test_version", pw_test_version);
                        }
                    }
                }
            }
        );
}

function personnalise(){
    try{
        laperso=null;

        // if(login_password === false){
            if(pw3.Page.agence) {
                if(pw3.Page.agence.perso) {
                    if(pw3.Page.agence.perso.message) {
                        laperso=JSON.parse(pw3.Page.agence.perso.message.valeur);
                    }
                }
            }
            if(pw3.Page.obEleve.Agence.perso) {
                if (pw3.Page.obEleve.Agence.perso.message) {
                    laperso = JSON.parse(pw3.Page.obEleve.Agence.perso.message.valeur);
                }
            }
         /* }
        else{
            if(!pw3.Page.obAcces){
                console.log(1);
                var ObjectEleve= new CallObj("", "", "", "sessionStorage", "eleve");
                var retourEleve = ObjectEleve.getObj();

                var ObjectAutoecole= new CallObj("", "", "", "sessionStorage", "autoecole");
                var retourAutoecole = ObjectAutoecole.getObj();

                console.log('retourAutoecole', retourAutoecole);

                if((pw3) && (pw3.Droit) && (pw3.Droit.LoginMdp) && (retourEleve) && (retourEleve.login_eleve)){
                    console.log(1111);
                    pw3.Droit.LoginMdp(retourEleve.login_eleve, retourEleve.code_eleve, false)
                        .then(function(){
                            if(pw3.Page.obAcces.option_acces.droit_client.perso){
                                if (pw3.Page.obAcces.option_acces.droit_client.perso.valeur) {
                                    laperso = JSON.parse(pw3.Page.obAcces.option_acces.droit_client.perso.valeur);
                                }
                            }
                        });
                }
            }
        }*/


        if (laperso) {
            document.body.style.backgroundImage = 'url("' + laperso.background + '")';
            document.body.style.backgroundPosition = "right top;";
            document.body.style.backgroundRepeat = "no-repeat;";
            document.body.style.backgroundSize = "cover;";
            document.body.style = "background-color: rgba(255, 255, 255, 0);background-image:url('" + laperso.background + "'); background-repeat: no-repeat; background-attachment:scroll; background-position-x:right; background-position-y:top; background-size:cover;";
            if (document.getElementById("logoPrincipal")) {
                document.getElementById("logoPrincipal").style.backgroundImage = "url('"+laperso.logo+"');";
                document.getElementById("logoPrincipal").style.backgroundPosition = "left bottom;";
                document.getElementById("logoPrincipal").style.backgroundRepeat = "no-repeat;";
                document.getElementById("logoPrincipal").style.backgroundAttachment = "scroll;";
                document.getElementById("logoPrincipal").style.backgroundSize = "cover;";
                document.getElementById("logoPrincipal").style.width = "65%;";
                document.getElementById("logoPrincipal").style = "background-image:url('"+laperso.logo+"'); background-repeat: no-repeat; background-position-x: left; background-position-y: bottom; background-size:auto;";
            }
            if (document.getElementsByClassName("imgLogo").length) {
                document.getElementsByClassName("imgLogo")[0].style.backgroundImage = "url('"+laperso.logo+"');";
                document.getElementsByClassName("imgLogo")[0].style.backgroundPosition = "left bottom;";
                document.getElementsByClassName("imgLogo")[0].style.backgroundRepeat = "no-repeat;";
                document.getElementsByClassName("imgLogo")[0].style.backgroundAttachment = "scroll;";
                document.getElementsByClassName("imgLogo")[0].style.backgroundSize = "cover;";
                document.getElementsByClassName("imgLogo")[0].style.width = "65%;";
                document.getElementsByClassName("imgLogo")[0].style = "background-image:url('"+laperso.logo+"'); background-repeat: no-repeat; background-position-x: leftt; background-position-y: bottom; background-size:auto;";
            }
        }
    }
    catch(e){catchError(e);}
}
