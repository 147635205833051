/**
 * Created by d.cian on 08/09/2015.
 */

$(document).ready(function() {

    pw3.Page = new pw3.Page();
    pw3.Stat = new pw3.Stat();
    pw3.Droit = new pw3.Droit();
    pw3.Examens = new pw3.Examens();
    
    $("body").addClass("accueil");

    window.onpopstate = function(){
        pw3.Page.AffichePage();
    };

    var page = new DataPage();
    pw3.Page.AffichePage();

    $(document).on("click", "#box", function () {
        pw3.Page.AfficheBox(page.getBoxInfo("test"));
    });
});
